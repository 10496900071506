import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Stack } from "@chakra-ui/layout";
import { ButtonIcon, Option, Picklist } from "react-rainbow-components";

import { useListAdminImpersonatorOrganizationsQuery } from "generated/graphql";
import { useCurrentUser } from "contexts/currentUser";
import CloseIcon from "settings/theme/ChakraTheme/icons/CloseIcon";

import getSelectOptions, { SelectOption } from "./getSelectOptions";
import useAdminImpersonationStore from "./store";

export const adminImpersonationSelectHeight = {
  css: "60px",
  value: 60,
};

/**
 * Renders the selector for the admin to select a organization to impersonate.
 * Updates the store with the select result.
 */
const AdminImpersonationSelect: React.FC = () => {
  const [t] = useTranslation();

  const [currentUserData] = useCurrentUser();
  const { data } = useListAdminImpersonatorOrganizationsQuery();

  const [options, setOptions] = useState<SelectOption[]>(
    getSelectOptions(
      currentUserData?.organization?.id,
      data?.listOrganizations,
    ),
  );

  const organizationId = useAdminImpersonationStore((store) => store.organizationId);

  const handleClearImpersonation = useCallback(() => {
    if (organizationId) {
      useAdminImpersonationStore.setState({
        organizationId: undefined,
        organization: undefined,
      });
    }
  }, [
    organizationId,
  ]);

  const onChange = useCallback((option) => {
    const organization = (data?.listOrganizations ?? []).find((item) => (
      item.id === option?.value
    ));

    useAdminImpersonationStore.setState({
      organizationId: option?.value,
      organization,
    });
  }, [
    data,
  ]);

  useEffect(() => {
    setOptions(getSelectOptions(
      currentUserData?.organization?.id,
      data?.listOrganizations,
    ));
  }, [
    currentUserData,
    data,
  ]);

  const value = options.find((option) => option.value === organizationId);

  return (
    <Flex
      alignItems="center"
    >
      <Stack
        isInline
        w="320px"
        minWidth="320px"
        padding={3}
        width="full"
        alignItems="center"
      >
        <Picklist
          className="admin-impersonation-picklist"
          onChange={onChange}
          value={value}
          placeholder={t("admin_impersonation.view_as_organization")}
          variant="bare"
        >
          {
            options.map(option => (
              <Option
                name={option.label}
                label={option.label}
                value={option.value}
                disabled={option.isDisabled}
                key={option.value}
              />
            ))
          }
        </Picklist>

        { organizationId && (
          <ButtonIcon
            tooltip={t("actions.clear_impersonation")}
            icon={<CloseIcon />}
            onClick={handleClearImpersonation}
          />
        )}
      </Stack>
    </Flex>
  );
};

export default AdminImpersonationSelect;
