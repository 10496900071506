import React from "react";
import { Flex, SimpleGrid, VStack } from "@chakra-ui/layout";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  DatePicker,
  RadioGroup,
  Picklist,
  Option,
} from "react-rainbow-components";

import MaskedInput from "components/FormComponents/MaskedInput";
import getErrorMessage from "utils/getErrorMessage";
import { buildCurrencyMask } from "constants/masks";
import { submitOptions } from "constants/submitOptions";
import MoneyBagIcon from "settings/theme/ChakraTheme/icons/MoneyBagIcon";
import { ApplicationStatusEnum } from "generated/graphql";
import { urgenciesSelectOptions } from "constants/urgenciesOptions";
import i18n from "translations/i18n";

import { EditGrantFormFieldsProps } from "./types";

export const newStatusOptions = [
  { value: ApplicationStatusEnum.Awarded, label: i18n.t("statuses.awarded") },
  { value: ApplicationStatusEnum.Declined, label: i18n.t("statuses.declined") },
  { value: ApplicationStatusEnum.Pending, label: i18n.t("statuses.pending") },
];

const EditGrantFormFields: React.FC<EditGrantFormFieldsProps> = ({
  control,
  errors,
  register,
  isSubmitted,
  isAwarded,
  shouldShowDeadlineField = false,
  ...rest
}) => {
  const [t] = useTranslation();

  return (
    <VStack
      spacing={6}
      {...rest}
    >
      <Flex width="full">
        <Controller
          as={RadioGroup}
          control={control}
          name="isSubmitted"
          label={t("modals.update_grant.application_submitted")}
          error={getErrorMessage("isSubmitted", errors)}
          orientation="horizontal"
          options={submitOptions}
          labelAlignment="left"
        />
      </Flex>

      {
        shouldShowDeadlineField && (
          <Controller
            as={DatePicker}
            control={control}
            name="deadline"
            label={t("modals.update_grant.application_deadline")}
            error={getErrorMessage("deadline", errors)}
            required
            labelAlignment="left"
          />
        )
      }

      {
        isSubmitted && (
          <>
            <Controller
              as={DatePicker}
              control={control}
              name="dateSubmitted"
              label={t("modals.update_grant.date_submitted")}
              error={getErrorMessage("dateSubmitted", errors)}
              required
              labelAlignment="left"
            />

            <MaskedInput
              ref={register}
              title={t("modals.update_grant.amount_requested")}
              name="amountRequested"
              mask={buildCurrencyMask()}
              errors={errors}
              isRequired
              rightElement={<MoneyBagIcon />}
            />

            <VStack
              spacing={6}
              width="full"
            >
              <Flex width="full">
                <Controller
                  as={RadioGroup}
                  control={control}
                  label={t("modals.update_grant.current_status")}
                  orientation="horizontal"
                  name="status"
                  options={newStatusOptions}
                  labelAlignment="left"
                />
              </Flex>

              {
                isAwarded && (
                  <>
                    <Controller
                      as={DatePicker}
                      control={control}
                      name="receivementDate"
                      label={t("modals.update_grant.receivement_date")}
                      error={getErrorMessage("receivementDate", errors)}
                      labelAlignment="left"
                    />

                    <MaskedInput
                      ref={register}
                      title={t("modals.update_grant.amount_awarded")}
                      name="amountAwarded"
                      mask={buildCurrencyMask()}
                      errors={errors}
                      rightElement={<MoneyBagIcon />}
                    />

                    <SimpleGrid
                      columns={2}
                      gap={6}
                      alignItems="baseline"
                    >
                      <Controller
                        as={Picklist}
                        control={control}
                        name="reportType"
                        error={getErrorMessage("reportType", errors)}
                        label={t("create_grant.steps.deadlines.report_type")}
                        placeholder={t("create_grant.steps.deadlines.select_a_type")}
                        labelAlignment="left"
                      >
                        {
                          urgenciesSelectOptions.map((type) => (
                            <Option
                              name={type.value}
                              value={type.value}
                              label={type.label}
                              key={type.label}
                            />
                          ))
                        }
                      </Controller>

                      <Controller
                        as={DatePicker}
                        control={control}
                        name="reportDeadline"
                        error={getErrorMessage("reportDeadline", errors)}
                        label={t("create_grant.steps.deadlines.report_deadline")}
                      />
                    </SimpleGrid>
                  </>
                )
              }
            </VStack>
          </>
        )
      }
    </VStack>
  );
};

export default EditGrantFormFields;
