import { FunderTypeEnum } from "generated/graphql";
import yup from "settings/yup";

const createFunderSchema = yup.object()
  .shape({
    name: yup
      .string()
      .required(),
    type: yup
      .object()
      .required(),
  });

export interface CreateFunderSchema {
  name: string;
  type: FunderTypeOption;
}

export interface FunderTypeOption {
  value: FunderTypeEnum;
  name?: string;
  icon?: unknown;
  label: string;
}

export default createFunderSchema;
