import React from "react";

import DefaultCell from "./DefaultCell";
import { CapitalizedCellProps } from "./types";

const CapitalizedCell: React.FC<CapitalizedCellProps> = ({
  value,
}) => <DefaultCell textTransform="capitalize" value={value} />;

export default CapitalizedCell;
