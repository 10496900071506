import { months } from "constants/months";

export interface MonthOptionProps {
  value: string;
  label: string;
  year: string;
}

/**
 * Defines possible select options for months with a defined year.
 * @param year The year e.g.: 2020
 */
export const formattedMonthOptions = (year: string): MonthOptionProps[] => (
  Object.keys(months).map((key) => ({
    value: key,
    label: months[key].label as string,
    year,
  }))
);
