import { Text, Box } from "@chakra-ui/layout";
import React from "react";

import { NumberCircleProps } from "./types";

const NumberCircle: React.FC<NumberCircleProps> = ({
  bgColor = "primary.500",
  boxSize = "40px",
  value,
}) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    borderRadius={100}
    minHeight={boxSize}
    minWidth={boxSize}
    boxSize={boxSize}
    bgColor={bgColor}
  >
    <Text
      fontSize="sm"
      color="white"
    >
      {value}
    </Text>
  </Box>
);

export default NumberCircle;
