import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { Input, Button } from "react-rainbow-components";
import { Link } from "react-router-dom";

import { useRequestResetPasswordMutation } from "generated/graphql";
import getErrorMessage from "utils/getErrorMessage";
import CustomCard from "components/CustomCard";
import { LOGIN_PAGE_PATH } from "routes";
import { useToast } from "contexts/toast";
import forgotPasswordSchema, { ForgotPasswordSchema } from "settings/yup/schemas/forgotPasswordSchema";
import useNavigate from "hooks/useNavigate";
import EmojiWrapper from "components/EmojiWrapper";
import MailIcon from "settings/theme/ChakraTheme/icons/MailIcon";

const ForgotPassword: React.FC = () => {
  const [t] = useTranslation();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const {
    errors,
    formState,
    handleSubmit,
    control,
  } = useForm<ForgotPasswordSchema>({
    resolver: yupResolver(forgotPasswordSchema),
    mode: "onBlur",
    defaultValues: {
      email: "",
    },
  });

  const [requestResetPassword, { loading }] = useRequestResetPasswordMutation();

  const onSubmit = useCallback((values) => {
    requestResetPassword({
      variables: {
        email: values.email,
      },
    })
      .then(() => {
        addToast({
          title: t("actions.reset_password_link"),
          type: "success",
        });

        navigate(LOGIN_PAGE_PATH)();
      })
      .catch((error) => {
        addToast({
          title: error?.message,
          type: "error",
        });
      });
  }, [
    t,
    addToast,
    navigate,
    requestResetPassword,
  ]);

  return (
    <form className="forgot-password-wrapper">
      <CustomCard
        title={t("forgot_password.title")}
        shouldAnimate
        shouldShowLogo
      >
        <h6 className="forgot-password-info">
          {t("forgot_password.a_security_code")}

          {" "}

          <EmojiWrapper
            ariaLabel="wink face"
            emoji="😉"
          />
        </h6>

        <Controller
          as={Input}
          control={control}
          name="email"
          type="email"
          required
          icon={<MailIcon />}
          label={t("forgot_password.email_address")}
          placeholder={t("forgot_password.enter_your_email_address")}
          className="forgot-password-input-field"
          error={getErrorMessage("email", errors)}
          iconPosition="right"
        />

        <div className="forgot-password-actions-container">
          <Button
            label={t("forgot_password.send_email")}
            variant="brand"
            type="submit"
            onClick={handleSubmit(onSubmit)}
            isLoading={loading}
            disabled={!formState.isValid}
          />

          <Link to={LOGIN_PAGE_PATH}>
            {t("buttons.go_back")}
          </Link>
        </div>
      </CustomCard>
    </form>
  );
};

export default ForgotPassword;
