import { Box } from "@chakra-ui/layout";
import React from "react";
import { Card } from "react-rainbow-components";

import { CardContainerProps } from "./types";

const CardContainer: React.FC<CardContainerProps> = ({
  children,
  ...props
}) => (
  <Box
    {...props}
    as={Card}
    width="full"
    boxShadow="none"
    borderRadius="4px"
    background="white"
    padding={5}
  >
    {children}
  </Box>
);

export default CardContainer;
