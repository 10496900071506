import { endOfDay } from "date-fns";
import { ObjectSchema } from "yup";

import { IsSubmittedEnum } from "components/EditGrantForm/types";
import { ApplicationStatusEnum } from "generated/graphql";
import yup from "settings/yup";
import i18n from "translations/i18n";

import buildCurrencyTest from "../tests/currencyFieldTest";
import { PicklistFunderProps } from "./createGrantSchema";

const grantUpdateSchema = yup.object().shape({
  isSubmitted: yup
    .string(),
  dateSubmitted: yup
    .date()
    .when("isSubmitted", {
      is: (value) => value === IsSubmittedEnum.Yes,
      then: yup
        .date()
        .max(
          endOfDay(new Date()).toDateString(),
          i18n.t("yup.string_max"),
        )
        .required(),
      otherwise: yup
        .date(),
    }),
  amountRequested: yup
    .string()
    .when("isSubmitted", {
      is: (value) => value === IsSubmittedEnum.Yes,
      then: yup
        .string()
        .test(buildCurrencyTest())
        .required(),
      otherwise: yup
        .string(),
    }),
  deadline: yup
    .date()
    .required(),
  status: yup
    .string(),
  receivementDate: yup
    .date()
    .when("status", {
      is: (value) => value === ApplicationStatusEnum.Awarded,
      then: yup
        .date()
        .max(
          endOfDay(new Date()).toDateString(),
          i18n.t("yup.string_max"),
        )
        .required(),
      otherwise: yup
        .date(),
    }),
  amountAwarded: yup
    .string()
    .when("status", {
      is: (value) => value === ApplicationStatusEnum.Awarded,
      then: yup
        .string()
        .test(buildCurrencyTest())
        .required(),
      otherwise: yup
        .string(),
    }),
  reportType: yup
    .object()
    .when("reportDeadline", (reportDeadline: string, schema: ObjectSchema) => (
      reportDeadline
        ? schema.required()
        : schema.notRequired()
    )),
  reportDeadline: yup
    .date()
    .when("reportType", (reportType: string, schema: ObjectSchema) => (
      reportType
        ? schema.required()
        : schema.notRequired()
    )),
}, [["reportType", "reportDeadline"]]);

export interface GrantUpdateSchema {
  isSubmitted?: IsSubmittedEnum;
  dateSubmitted: Date;
  amountRequested: string;
  deadline: Date;
  status: string;
  receivementDate?: Date;
  amountAwarded: string;
  reportType: PicklistFunderProps;
  reportDeadline: Date;
}

export default grantUpdateSchema;
