import React from "react";

import { TextareaDisplayProps } from "./types";

const TextareaDisplay: React.FC<TextareaDisplayProps> = ({
  text,
}) => (
  <div className="textarea-display">
    {text}
  </div>
);

export default TextareaDisplay;
