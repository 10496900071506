import React from "react";
import { useFormContext } from "react-hook-form";

import { ApplicationStatusEnum } from "generated/graphql";
import EditGrantFormFields from "components/EditGrantForm/EditGrantFormFields";
import { CreateGrantSchema } from "settings/yup/schemas/createGrantSchema";
import { IsSubmittedEnum } from "components/EditReportForm/types";

const Status: React.FC = () => {
  const {
    errors,
    control,
    register,
    watch,
  } = useFormContext<CreateGrantSchema>();

  const isSubmitted = watch("isSubmitted") === IsSubmittedEnum.Yes;

  const isAwarded = watch("status") === ApplicationStatusEnum.Awarded;

  return (
    <EditGrantFormFields
      errors={errors}
      control={control}
      register={register}
      isSubmitted={isSubmitted}
      isAwarded={isAwarded}
      marginBottom={6}
    />
  );
};

export default Status;
