import parseDecimal from "parse-decimal-number";

const customSeparators = { thousands: ",", decimal: "." };

/**
 * Receives a currency string and returns
 * the decimal value.
 */
export const getFloat = (value: string): number | undefined => {
  if (!value) {
    return undefined;
  }

  return parseDecimal(
    value.replace("$", ""),
    customSeparators,
  );
};
