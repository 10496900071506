import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import ArrowLeftIcon from "settings/theme/ChakraTheme/icons/ArrowLeftIcon";
import Button from "components/Button";

const BackButton: React.FC = () => {
  const history = useHistory();

  const handleNavBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Button
      padding={0}
      color="white"
      onClick={handleNavBack}
    >
      <ArrowLeftIcon boxSize={6} color="primary.200" />
    </Button>
  );
};

export default BackButton;
