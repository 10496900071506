import { nanoid } from "nanoid";

import { GetLargestFundersQuery } from "generated/graphql";

import formatFunderName from "./formatFunderName";

export interface FormattedLargestFundersTableProps {
  formattedHeaders?: LargestFunderTableHeader[];
  largestFunders?: Record<string, string>[];
}

export interface LargestFunderTableHeader {
  id: string;
  name: string;
}

const formatLargestFundersTable = (
  value?: GetLargestFundersQuery["currentUser"]["organization"] | null,
): FormattedLargestFundersTableProps | undefined => {
  const programFundersLists = value?.largestFunders?.programFunders;

  if (!programFundersLists) {
    return undefined;
  }

  const sortedProgramFunders = [...programFundersLists]?.sort(
    (a, b) => (b?.funders?.length - a?.funders?.length),
  );

  const formattedHeaders = sortedProgramFunders.map(funder => ({
    id: funder?.program?.id.toString(),
    name: funder?.program?.name,
  }));

  const formattedPrograms = sortedProgramFunders.reduce(((acc, cur) => ({
    ...acc,
    [cur.program.id]: cur.funders,
  })), {});

  const largestFunder = !!sortedProgramFunders.length && sortedProgramFunders[0];

  if (!largestFunder) {
    return {
      formattedHeaders,
      largestFunders: undefined,
    };
  }

  const rows = largestFunder.funders.map((funder, index) => (
    Object.keys(formattedPrograms).reduce((prev, programKey) => ({
      ...prev,
      [programKey]: formatFunderName(formattedPrograms[programKey][index]),
    }), {
      id: nanoid(),
    })
  ));

  return {
    formattedHeaders,
    largestFunders: rows,
  };
};

export default formatLargestFundersTable;
