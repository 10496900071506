import { Skeleton } from "@chakra-ui/skeleton";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Dataset, Chart } from "react-rainbow-components";
import { Flex, Text } from "@chakra-ui/layout";

import CardContainer from "components/Cards/CardContainer";
import {
  useGetFundersByTypeQuery,
  useGetImpersonatedOrganizationFundersByTypeQuery,
} from "generated/graphql";
import ChartLegend from "components/ChartLegend";
import CardHeader from "components/Cards/CardHeader";
import YearFilter from "components/Filters/YearFilter";
import { filters } from "hooks/useFilter";
import useYearPeriodFilter from "hooks/useFilter/useYearPeriodFilter";
import { DashboardItemProps } from "views/Dashboard/types";
import useGetOrganization from "hooks/useGetOrganization";

import colors from "./colors";
import labels from "./labels";
import options from "./options";

interface ComponentProps {
  funderTypesCount: any;
}

const ChartComponent: React.FC<ComponentProps> = memo(({ funderTypesCount }) => {
  const [t] = useTranslation();

  const data = labels.map((label) => funderTypesCount?.[label.toLowerCase()]);

  const isNotEmpty = data.some((value) => value !== 0);

  return isNotEmpty ? (
    <Flex marginY="auto" alignItems="center" justifyContent="space-between">
      <ChartLegend values={data} labels={labels} colors={colors} />

      <Chart
        type="doughnut"
        labels={labels}
        maintainAspectRatio
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        options={options}
      >
        <Dataset values={data} backgroundColor={colors} />
      </Chart>
    </Flex>
  ) : (
    <Flex height="full" alignItems="center" justifyContent="center">
      <Text
        color="primary.200"
        fontFamily="heading"
        fontSize={{ base: "xs", md: "lg", lg: "lg" }}
      >
        {t("dashboard.funding_by_type.nothing_here_yet")}
      </Text>
    </Flex>
  );
});

const FundingsByType: React.FC<DashboardItemProps> = ({
  oldestAwardReceivementDate,
  oldestAwardLoading,
}) => {
  const [t] = useTranslation();

  const { startAt, endAt } = useYearPeriodFilter(filters.fundingByTypeYear);

  const [organization, {
    loading,
  }] = useGetOrganization(
    useGetFundersByTypeQuery,
    useGetImpersonatedOrganizationFundersByTypeQuery,
    {
      variables: {
        startAt,
        endAt,
      },
    },
  );

  const funderTypesCount = organization?.funderTypesCount;

  return (
    <Skeleton
      height="full"
      display="flex"
      as={CardContainer}
      flexDirection="column"
      isLoaded={!loading}
    >
      <CardHeader title={t("dashboard.funding_by_type.funding_by_type")}>
        {
          !oldestAwardLoading && (
            <YearFilter
              startDate={oldestAwardReceivementDate}
              filter={filters.fundingByTypeYear}
            />
          )
        }
      </CardHeader>

      <ChartComponent funderTypesCount={funderTypesCount} />
    </Skeleton>
  );
};

export default memo(FundingsByType);
