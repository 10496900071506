import { endOfDay } from "date-fns";

import { IsSubmittedEnum } from "components/EditGrantForm/types";
import { Status } from "constants/statuses";
import yup from "settings/yup";
import i18n from "translations/i18n";

const reportUpdateSchema = yup.object()
  .shape({
    isSubmitted: yup
      .string(),
    submissionDate: yup
      .date()
      .when("isSubmitted", {
        is: (value) => value === IsSubmittedEnum.Yes,
        then: yup
          .date()
          .max(
            endOfDay(new Date()).toDateString(),
            i18n.t("yup.string_max"),
          )
          .required(),
        otherwise: yup
          .date(),
      }),
    deadline: yup
      .date()
      .required(),
    urgency: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.string(),
      }),
  });

export interface ReportUpdateSchema {
  isSubmitted?: IsSubmittedEnum;
  submissionDate: Date;
  deadline: Date;
  urgency: Status;
}

export default reportUpdateSchema;
