import React from "react";
import { SimpleGrid } from "@chakra-ui/layout";
import { Skeleton } from "@chakra-ui/skeleton";
import { useTranslation } from "react-i18next";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { startOfYear } from "date-fns";

import Card from "views/Dashboard/SummaryCard/Card";
import {
  useListApplicationsQuery,
  ListApplicationsQuery,
  useGetGrantsSummaryQuery,
  useGetImpersonatedOrganizationGrantsSummaryQuery,
  FilterApplicationStatusEnum,
} from "generated/graphql";
import ArrowRightUpIcon from "settings/theme/ChakraTheme/icons/ArrowRightUpIcon";
import ClockIcon from "settings/theme/ChakraTheme/icons/ClockIcon";
import elementIds from "views/Dashboard/elementIds";
import formatCurrency from "utils/formatCurrency";
import useGetOrganization from "hooks/useGetOrganization";
import { formatDate, yearMonthDay } from "utils/dateFormats";

import { awardedFilterUrl, pendingFilterUrl, submittedFilterUrl } from "./filterUrls";
import {
  breakAfterContainerCss,
  containerCss,
} from "../styles";
import PrintableDetails from "./PrintableDetail";
import { DetailItemProps } from "./types";

export const formatApplications = (
  applicationsList?: ListApplicationsQuery["listApplications"],
): DetailItemProps[] => {
  if (!applicationsList) {
    return [];
  }

  const formattedApplications = applicationsList?.map((application) => ({
    id: application?.id,
    program: application?.grant?.program?.name,
    funder: application?.grant?.funder?.name,
  }));

  return formattedApplications;
};

const SummaryCard: React.FC = () => {
  const [t] = useTranslation();

  const [organization, {
    loading,
  }] = useGetOrganization(
    useGetGrantsSummaryQuery,
    useGetImpersonatedOrganizationGrantsSummaryQuery,
  );

  const grantsSummary = organization?.grantsSummary;

  const totalApplicationAmount = formatCurrency(grantsSummary?.totalApplicationAmount);
  const totalApplicationCount = grantsSummary?.totalApplicationCount;

  const totalAwardedAmount = formatCurrency(grantsSummary?.totalAwardedAmount);
  const totalAwardedGrants = grantsSummary?.totalAwardedGrants;

  const totalPendingAmount = formatCurrency(grantsSummary?.totalPendingAmount);
  const totalPendingGrants = grantsSummary?.totalPendingGrants;

  const { data: submittedApplicationsData } = useListApplicationsQuery({
    variables: {
      filters: {
        organizationId: Number(organization?.id),
        startAt: formatDate(startOfYear(new Date()), yearMonthDay),
        status: FilterApplicationStatusEnum.Submitted,
      },
    },
    skip: !organization?.id,
  });

  const { data: awardedApplicationsData } = useListApplicationsQuery({
    variables: {
      filters: {
        organizationId: Number(organization?.id),
        startAt: formatDate(startOfYear(new Date()), yearMonthDay),
        status: FilterApplicationStatusEnum.Awarded,
      },
    },
    skip: !organization?.id,
  });

  const { data: pendingApplicationsData } = useListApplicationsQuery({
    variables: {
      filters: {
        organizationId: Number(organization?.id),
        status: FilterApplicationStatusEnum.Pending,
      },
    },
    skip: !organization?.id,
  });

  const formattedSubmittedApplications = formatApplications(
    submittedApplicationsData?.listApplications,
  );

  const formattedAwardedApplications = formatApplications(
    awardedApplicationsData?.listApplications,
  );

  const formattedPendingApplications = formatApplications(
    pendingApplicationsData?.listApplications,
  );

  return (
    <SimpleGrid
      columns={{ base: 1, md: 1, lg: 3 }}
      gap={6}
      width="full"
      id={elementIds.summary}
      css={containerCss}
    >
      <Skeleton
        isLoaded={!loading}
        css={breakAfterContainerCss}
      >
        <Link to={submittedFilterUrl}>
          <Card
            value={totalApplicationAmount}
            subtitle={
              t(
                "dashboard.summary.applications_sent",
                {
                  context: `${totalApplicationCount}`,
                  count: totalApplicationCount,
                },
              )
            }
            icon={(
              <ArrowRightUpIcon
                boxSize={10}
                color="primary.100"
              />
            )}
          />
        </Link>

        <PrintableDetails
          title={t("dashboard.summary.applications_list")}
          detailsList={formattedSubmittedApplications}
        />
      </Skeleton>

      <Skeleton
        isLoaded={!loading}
        css={breakAfterContainerCss}
      >
        <Link to={awardedFilterUrl}>
          <Card
            value={totalAwardedAmount}
            subtitle={
              t(
                "dashboard.summary.grants_awarded",
                {
                  context: `${totalAwardedGrants}`,
                  count: totalAwardedGrants,
                },
              )
            }
            icon={(
              <CheckCircleIcon
                boxSize={10}
                color="primary.100"
              />
            )}
          />
        </Link>

        <PrintableDetails
          title={t("dashboard.summary.grants_list")}
          detailsList={formattedAwardedApplications}
        />
      </Skeleton>

      <Skeleton
        isLoaded={!loading}
        css={breakAfterContainerCss}
      >
        <Link to={pendingFilterUrl}>
          <Card
            value={totalPendingAmount}
            subtitle={
              t(
                "dashboard.summary.grants_pending",
                {
                  context: `${totalPendingGrants}`,
                  count: totalPendingGrants,
                },
              )
            }
            icon={(
              <ClockIcon
                boxSize={10}
                color="primary.100"
              />
            )}
          />
        </Link>

        <PrintableDetails
          title={t("dashboard.summary.grants_list")}
          detailsList={formattedPendingApplications}
        />
      </Skeleton>
    </SimpleGrid>
  );
};

export default SummaryCard;
