import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { Input, Button } from "react-rainbow-components";

import getErrorMessage from "utils/getErrorMessage";
import CustomCard from "components/CustomCard";
import {
  FORGOT_PASSWORD_PAGE_PATH,
  SIGN_UP_PAGE_PATH,
  DASHBOARD_PAGE_PATH,
} from "routes";
import useSession from "hooks/useSession";
import loginSchema, { LoginSchema } from "settings/yup/schemas/loginSchema";
import { useToast } from "contexts/toast";
import MailIcon from "settings/theme/ChakraTheme/icons/MailIcon";
import LockIcon from "settings/theme/ChakraTheme/icons/LockIcon";
import { useLoginUserMutation } from "generated/graphql";

const Login: React.FC = () => {
  const [createSession] = useSession();
  const [t] = useTranslation();
  const { addToast } = useToast();

  const {
    errors,
    handleSubmit,
    control,
  } = useForm<LoginSchema>({
    resolver: yupResolver(loginSchema),
    mode: "onBlur",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const [loginUser, { loading }] = useLoginUserMutation();

  const onSubmit = useCallback((values) => {
    loginUser({
      variables: {
        password: values.password,
        email: values.email,
      },
    })
      .then((response) => {
        const token = response?.data?.loginUser;

        if (token) {
          createSession(token, DASHBOARD_PAGE_PATH);
        }
      })
      .catch((error) => {
        addToast({
          title: error?.message,
          type: "error",
        });
      });
  }, [
    addToast,
    loginUser,
    createSession,
  ]);

  return (
    <form className="login-wrapper">
      <CustomCard
        title={t("login.title")}
        shouldAnimate
        shouldShowLogo
        subtitle={(
          <span>
            {t("login.dont_have_an_account")}

            {" "}

            <Link
              to={SIGN_UP_PAGE_PATH}
            >
              {t("login.create_account_here")}
            </Link>
          </span>
        )}
      >
        <Controller
          as={Input}
          control={control}
          name="email"
          type="email"
          required
          icon={<MailIcon />}
          label={t("login.email_address")}
          placeholder={t("login.enter_your_email_address")}
          className="login-input-field"
          error={getErrorMessage("email", errors)}
          iconPosition="right"
        />

        <Controller
          as={Input}
          control={control}
          name="password"
          type="password"
          required
          icon={<LockIcon />}
          label={t("login.password")}
          placeholder={t("login.enter_your_password")}
          className="login-input-field"
          error={getErrorMessage("password", errors)}
          iconPosition="right"
        />

        <div className="login-actions-container">
          <Button
            label={t("login.login")}
            variant="brand"
            type="submit"
            onClick={handleSubmit(onSubmit)}
            isLoading={loading}
          />

          <Link to={FORGOT_PASSWORD_PAGE_PATH}>
            {t("login.forgot_your_password")}
          </Link>
        </div>
      </CustomCard>
    </form>
  );
};

export default Login;
