import React from "react";
import { FormLabel } from "@chakra-ui/form-control";
import { Text } from "@chakra-ui/layout";

import { FieldTitleProps } from "./types";

const FieldTitle: React.FC<FieldTitleProps> = ({
  children,
  htmlFor,
  isRequired = false,
  ...rest
}) => (
  <FormLabel
    htmlFor={htmlFor}
    fontFamily="mono"
    fontSize="14px"
    color="gray.500"
    opacity="0.8"
    marginRight={0}
    marginBottom="2px"
    textAlign="left"
    marginLeft="16px"
    {...rest}
  >
    {
      isRequired && (
        <Text
          as="span"
          color="status.error"
          marginRight={1}
        >
          *
        </Text>
      )
    }

    {children}
  </FormLabel>
);

export default FieldTitle;
