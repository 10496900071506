import { getYear } from "date-fns";

import { PeriodsEnum } from "constants/periods";
import { FilterApplicationStatusEnum } from "generated/graphql";
import { GRANT_FLOW_PAGE_LOCATION } from "routes/locations";

export const defaultFilters = {
  submissionMonth: PeriodsEnum.Year,
  submissionYear: String(getYear(new Date())),
};

export const submittedFilterUrl = GRANT_FLOW_PAGE_LOCATION.toUrl({
  ...defaultFilters,
  grantStatus: FilterApplicationStatusEnum.Submitted,
});

export const awardedFilterUrl = GRANT_FLOW_PAGE_LOCATION.toUrl({
  ...defaultFilters,
  grantStatus: FilterApplicationStatusEnum.Awarded,
});

export const pendingFilterUrl = GRANT_FLOW_PAGE_LOCATION.toUrl({
  submissionMonth: PeriodsEnum.All,
  submissionYear: PeriodsEnum.All,
  grantStatus: FilterApplicationStatusEnum.Pending,
});
