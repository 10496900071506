import React from "react";
import { Input } from "react-rainbow-components";
import { InputProps } from "react-rainbow-components/components/Input";

import FieldTitle from "components/FormComponents/MaskedInput/FieldTitle";
import ExternalLink from "components/ExternalLink";

const ExternalLinkInput: React.FC<InputProps> = ({
  readOnly,
  value,
  name,
  label,
  ...props
}) => (
  <>
    {
      readOnly
        ? (
          <>
            <FieldTitle htmlFor={3}>
              {label}
            </FieldTitle>

            <ExternalLink
              url={value as string}
              color="secondary.200"
            />
          </>
        )
        : (
          <Input
            readOnly={readOnly}
            value={value}
            name={name}
            label={label}
            {...props}
          />
        )
    }
  </>
);

export default ExternalLinkInput;
