import {
  useRef, useState, useEffect, useCallback, useMemo,
} from "react";

import { UseHoverType } from "./types";

/**
 * Exposes the useHover hook.
 * Adds an event listener on an element and returns
 * true or false when this element is hovered.
 */
const useHover = <T extends HTMLElement>(): UseHoverType<T> => {
  const [isHovered, setIsHovered] = useState(false);

  const ref = useRef<T>(null);

  const handleMouseOver = useCallback(() => () => {
    if (isHovered) {
      return;
    }

    setIsHovered(true);
  }, [isHovered]);

  const handleMouseOut = useCallback(() => () => {
    if (!isHovered) {
      return;
    }

    setIsHovered(false);
  }, [isHovered]);

  useEffect(() => {
    const node = ref?.current;

    node?.addEventListener("mouseover", handleMouseOver());
    node?.addEventListener("mouseout", handleMouseOut());

    return () => {
      node?.removeEventListener("mouseover", handleMouseOver());
      node?.removeEventListener("mouseout", handleMouseOut());
    };
  },
  [
    handleMouseOver,
    handleMouseOut,
  ]);

  const payload = useMemo<UseHoverType<T>>(() => [
    ref,
    isHovered,
  ], [
    ref,
    isHovered,
  ]);

  return payload;
};

export default useHover;
