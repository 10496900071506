import create from "zustand";

import { AdminImpersonationStoreState } from "./types";

const useAdminImpersonationStore = create<AdminImpersonationStoreState>((_set, get) => ({
  organizationId: undefined,
  organization: undefined,
  isVisible: false,
  getOrganizationId: (fallback) => {
    const { organizationId } = get();

    return organizationId ?? fallback;
  },
}));

export default useAdminImpersonationStore;
