import React from "react";
import { Flex } from "@chakra-ui/layout";

import { TableCellContainerProps } from "./types";

const TableCellContainer: React.FC<TableCellContainerProps> = ({
  children,
  ...props
}) => (
  <Flex
    alignItems="center"
    minHeight={14}
    position="relative"
    justifyContent="center"
    marginTop={1}
    marginBottom={1}
    {...props}
  >
    {children}
  </Flex>
);

export default TableCellContainer;
