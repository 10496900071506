import { FunderTypeEnum } from "generated/graphql";
import yup from "settings/yup";

import { makePhoneNumberTest } from "../tests/phoneNumberTest";

export const defaultUpdateFunderValues = {
  name: "",
  email: "",
  phone: "",
  type: {
    value: undefined,
    name: undefined,
    label: undefined,
  },
  website: "",
  keyContacts: "",
};

const updateFunderSchema = yup.object()
  .shape({
    name: yup
      .string()
      .default(defaultUpdateFunderValues.name)
      .required(),
    email: yup
      .string()
      .default(defaultUpdateFunderValues.email)
      .email(),
    phone: yup
      .string()
      .default(defaultUpdateFunderValues.phone)
      .test(makePhoneNumberTest(false)),
    keyContacts: yup
      .string()
      .default(defaultUpdateFunderValues.keyContacts),
    type: yup
      .object()
      .default(defaultUpdateFunderValues.type)
      .required(),
    website: yup
      .string()
      .default(defaultUpdateFunderValues.website),
  });
export interface UpdateFunderSchema {
  name: string;
  email: string;
  phone: string;
  keyContacts: string;
  type: FunderTypeOption;
  website: string;
}

export interface FunderTypeOption {
  value: FunderTypeEnum;
  name?: string;
  icon?: unknown;
  label: string;
}

export default updateFunderSchema;
