import { WizardStep } from "components/Wizard/types";
import i18n from "translations/i18n";

import Program from "./Steps/Program";

export enum CreateProgramStep {
  ProgramStep,
}

const createProgramSteps: WizardStep[] = [
  {
    id: CreateProgramStep.ProgramStep,
    component: Program,
    title: i18n.t("create_program.steps.program.title"),
  },
];

export default createProgramSteps;
