import {
  Flex,
  UnorderedList,
  SimpleGrid,
  Text,
  ListItem,
} from "@chakra-ui/layout";
import React from "react";

import { PrintableDetailsProps } from "./types";

const PrintableDetails: React.FC<PrintableDetailsProps> = ({
  detailsList,
  title,
}) => (
  <Flex className="show-on-print">
    <Text
      mt={4}
      mb={2}
      w="full"
      fontSize="md"
      textAlign="center"
    >
      {title}
    </Text>

    <UnorderedList>
      <SimpleGrid
        mt={4}
        templateColumns="repeat(2, 1fr)"
      >
        {
          detailsList.map((item) => (
            <ListItem mt={2} key={item?.id}>
              {`${item?.program}, ${item?.funder}`}
            </ListItem>
          ))
        }
      </SimpleGrid>
    </UnorderedList>
  </Flex>
);

export default PrintableDetails;
