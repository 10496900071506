import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

import updateFunderSchema, { defaultUpdateFunderValues, UpdateFunderSchema } from "settings/yup/schemas/updateFunderSchema";
import { funderTypes } from "components/CreateFunderForm/Steps/Personal/options";
import { useUpdateFunderMutation, UpdateFunderParams } from "generated/graphql";
import { useToast } from "contexts/toast";
import formatUSPhoneNumber from "utils/formatUSPhoneNumber";
import { UpdateFunderRefetchQueries } from "graphql/mutations/updateFunderMutation";
import capitalizeStringFirstLetter from "utils/capitalizeStringFirstLetter";

import CardContainer from "../CardContainer";
import { ContactInformationCardProps } from "./types";
import ContactInformationFields from "./ContactInformationFields";
import CardHeaderWithEdit from "../CardHeaderWithEdit";

const ContactInformationCard: React.FC<ContactInformationCardProps> = ({
  data,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const [t] = useTranslation();

  const { addToast } = useToast();

  const [updateFunder, {
    loading: isLoading,
  }] = useUpdateFunderMutation({
    refetchQueries: UpdateFunderRefetchQueries,
  });

  const funderData = data?.getFunder;

  const keyContactsCount = funderData?.keyContacts?.split(";").length;

  const {
    control,
    reset,
    handleSubmit,
    errors,
    formState,
  } = useForm<UpdateFunderSchema>({
    resolver: yupResolver(updateFunderSchema),
    mode: "all",
    defaultValues: updateFunderSchema.cast(),
  });

  useEffect(() => {
    if (funderData) {
      reset({
        name: funderData?.name ?? defaultUpdateFunderValues?.name,
        email: funderData?.email ?? defaultUpdateFunderValues?.email,
        phone: formatUSPhoneNumber(funderData?.phone) ?? defaultUpdateFunderValues?.phone,
        type: {
          value: funderData?.type ?? defaultUpdateFunderValues?.type?.value,
          name: funderData?.type ?? defaultUpdateFunderValues?.type?.name,
          label: funderTypes[funderData?.type].label ?? defaultUpdateFunderValues?.type?.label,
        },
        website: funderData?.website ?? defaultUpdateFunderValues?.website,
        keyContacts: funderData?.keyContacts ?? defaultUpdateFunderValues?.keyContacts,
      });
    }
  }, [
    reset,
    funderData,
  ]);

  const onSubmit = useCallback((values: UpdateFunderSchema) => {
    if (!funderData?.id || !formState.isDirty) {
      return;
    }

    const params: UpdateFunderParams = {
      name: values?.name,
      email: values?.email,
      phone: values?.phone,
      type: values?.type?.value,
      website: values?.website,
      keyContacts: values?.keyContacts,
    };

    updateFunder({
      variables: {
        id: funderData.id,
        params,
      },
    })
      .then(() => {
        addToast({
          title: t("contact_information_card.funder_contact_information_have_been_updated"),
          type: "success",
        });
      })
      .catch((error) => {
        addToast({
          title: capitalizeStringFirstLetter(error.message),
          type: "error",
        });
      });
  }, [
    t,
    addToast,
    funderData,
    updateFunder,
    formState.isDirty,
  ]);

  const isDisabled = !formState.isValid && isEditing && formState.isDirty;

  return (
    <CardContainer>
      <CardHeaderWithEdit
        isEditing={isEditing}
        handleSubmit={handleSubmit(onSubmit)}
        setIsEditing={setIsEditing}
        isDisabled={isDisabled}
        isLoading={isLoading}
        title={`${t("contact_information_card.contact_information")}:`}
      />

      <ContactInformationFields
        control={control}
        errors={errors}
        isEditing={isEditing}
        keyContactsCount={keyContactsCount}
        isLoaded={!!funderData}
      />
    </CardContainer>
  );
};

export default ContactInformationCard;
