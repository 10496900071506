import React from "react";
import { VStack } from "@chakra-ui/layout";

import { useGetReportQuery } from "generated/graphql";
import Loading from "components/Loading";
import EditReportForm from "components/EditReportForm";

import { UpdateReportModalProps } from "./types";

const UpdateReportModal: React.FC<UpdateReportModalProps> = ({
  hideModal,
  componentProps: {
    id,
    buttonLabel,
  },
}) => {
  const {
    data: reportData,
    loading,
  } = useGetReportQuery({
    variables: {
      id,
    },
  });

  return (
    <VStack spacing={6}>
      {
        loading && (
          <Loading height="xxs" />
        )
      }

      {
        !loading && (
          <EditReportForm
            reportData={reportData}
            buttonLabel={buttonLabel}
            hideModal={hideModal}
          />
        )
      }
    </VStack>
  );
};

export default UpdateReportModal;
