import React from "react";
import { isAfter, addYears, set } from "date-fns";

import useGetOrganization from "hooks/useGetOrganization";
import { useGetImpersonatedOrganizationFiscalYearQuery, useGetOrganizationFiscalYearQuery } from "generated/graphql";
import { formatDate, yearMonthDay } from "utils/dateFormats";

import { UseFiscalYearResult } from "./types";

const MINUTE_IN_MILLISECONDS = -60000;

// Returns the current fiscal year start and end dates
// and the current organization ID.
export const useFiscalYear = (): UseFiscalYearResult => {
  const [organization] = useGetOrganization(
    useGetOrganizationFiscalYearQuery,
    useGetImpersonatedOrganizationFiscalYearQuery,
  );

  const fiscalYearStartDateObj = organization?.fiscalYearStart
    ? new Date(organization?.fiscalYearStart)
    : new Date();

  const timezoneOffset = fiscalYearStartDateObj.getTimezoneOffset() * MINUTE_IN_MILLISECONDS;

  const fiscalYearStartDateObjWithoutTimezone = new Date(
    fiscalYearStartDateObj.getTime() - timezoneOffset,
  );

  const updatedFiscalYearStart = organization?.fiscalYearStart
    ? formatDate(
      set(
        fiscalYearStartDateObjWithoutTimezone,
        {
          year: new Date().getFullYear(),
        },
      ),
    )
    : undefined;

  const currentFiscalYearStart = React.useMemo((): string | undefined => {
    if (!updatedFiscalYearStart) {
      return undefined;
    }

    return isAfter(new Date(updatedFiscalYearStart), new Date())
      ? formatDate(addYears(new Date(updatedFiscalYearStart), -1), yearMonthDay)
      : updatedFiscalYearStart;
  }, [
    updatedFiscalYearStart,
  ]);

  const currentFiscalYearEnd = React.useMemo((): string | undefined => {
    if (!currentFiscalYearStart) {
      return undefined;
    }

    return formatDate(addYears(new Date(currentFiscalYearStart), 1), yearMonthDay);
  }, [
    currentFiscalYearStart,
  ]);

  const organizationId = organization?.id;

  return {
    currentFiscalYearStart,
    currentFiscalYearEnd,
    organizationId,
  };
};
