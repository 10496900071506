import React from "react";
import { Flex } from "@chakra-ui/layout";

const TableContainer: React.FC = ({
  children,
  ...props
}) => (
  <Flex
    margin={8}
    flexDir="column"
    {...props}
  >
    {children}
  </Flex>
);

export default TableContainer;
