import { FundersTableTitlesEnum } from "constants/fundersTableTitles";
import { ListFundersQuery } from "generated/graphql";

import formatCurrency from "./formatCurrency";

export interface FunderProps {
  id: string;

  [FundersTableTitlesEnum.Name]?: string;

  [FundersTableTitlesEnum.Email]?: string;

  [FundersTableTitlesEnum.Type]?: string;

  [FundersTableTitlesEnum.Website]?: string;

  [FundersTableTitlesEnum.TotalAwardAmount]?: string;
}

/**
 * Receives fundersData from the listFunders query and
 * returns it formatted
 * @param tableData rawTableData
 */
const formatFundersTableData = (
  tableData?: ListFundersQuery["currentUser"]["organization"],
): FunderProps[] => {
  const fundersList = tableData?.funders;

  if (!tableData || !fundersList) {
    return [];
  }

  const formattedTableData = fundersList.map(funder => (
    {
      id: funder?.id?.toString(),

      funder: funder?.name,

      funderId: funder?.id,

      [FundersTableTitlesEnum.Name]: funder?.name,

      [FundersTableTitlesEnum.TotalAwardAmount]: formatCurrency(funder?.totalAwardAmount),

      [FundersTableTitlesEnum.Email]: funder?.email,

      [FundersTableTitlesEnum.Type]: funder?.type.toLowerCase(),

      [FundersTableTitlesEnum.Website]: funder?.website,
    }
  ));

  return formattedTableData;
};

export default formatFundersTableData;
