import { FilterApplicationStatusEnum } from "generated/graphql";
import i18n from "translations/i18n";

export enum AllApplicationsEnum {
  AllApplications = "ALL_STATUSES",
}

export enum AllRecordsEnum {
  AllRecords = "ALL_RECORDS",
}

export interface Status {
  label: string;
  value?: string;
}

/**
 * The statuses of an grant
 */
export const grantFlowStatuses: Record<
  FilterApplicationStatusEnum | AllApplicationsEnum,
  Status
> = {
  [AllApplicationsEnum.AllApplications]: {
    label: i18n.t("statuses.all_applications"),
    value: AllApplicationsEnum.AllApplications,
  },
  [FilterApplicationStatusEnum.Awarded]: {
    label: i18n.t("statuses.awarded"),
    value: FilterApplicationStatusEnum.Awarded,
  },
  [FilterApplicationStatusEnum.Declined]: {
    label: i18n.t("statuses.declined"),
    value: FilterApplicationStatusEnum.Declined,
  },
  [FilterApplicationStatusEnum.NotApply]: {
    label: i18n.t("statuses.not_apply"),
    value: FilterApplicationStatusEnum.NotApply,
  },
  [FilterApplicationStatusEnum.Pending]: {
    label: i18n.t("statuses.pending"),
    value: FilterApplicationStatusEnum.Pending,
  },
  [FilterApplicationStatusEnum.Submitted]: {
    label: i18n.t("statuses.submitted"),
    value: FilterApplicationStatusEnum.Submitted,
  },
};

/**
 * Defines possible select options for grant status.
 */
export const grantFlowStatusSelectOptions = Object.keys(grantFlowStatuses).map((key): Status => ({
  label: grantFlowStatuses[key].label as string,
  value: key === AllApplicationsEnum.AllApplications ? undefined : key,
}));

export enum ReportStatusEnum {
  Submitted = "SUBMITTED",

  NotSubmitted = "NOT_SUBMITTED"
}

export const reportCenterStatuses: Record<ReportStatusEnum | AllRecordsEnum, Status> = {
  [AllRecordsEnum.AllRecords]: {
    label: i18n.t("statuses.all_records"),
    value: AllRecordsEnum.AllRecords,
  },
  [ReportStatusEnum.Submitted]: {
    label: i18n.t("statuses.submitted"),
    value: ReportStatusEnum.Submitted,
  },
  [ReportStatusEnum.NotSubmitted]: {
    label: i18n.t("statuses.not_submitted"),
    value: ReportStatusEnum.NotSubmitted,
  },
};

/**
 * Defines possible select options for report center.
 */
export const reportCenterStatusSelectOptions = Object.keys(reportCenterStatuses).map(
  (key): Status => ({
    label: reportCenterStatuses[key].label as string,
    value: key === AllRecordsEnum.AllRecords ? undefined : key,
  }),
);

export const validStatuses = Object.keys(FilterApplicationStatusEnum)
  .map(key => FilterApplicationStatusEnum[key]);
