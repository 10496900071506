import React from "react";
import { Stack } from "@chakra-ui/layout";
import { useTranslation } from "react-i18next";
import {
  Input, Option, Picklist,
} from "react-rainbow-components";
import { Controller, useFormContext } from "react-hook-form";

import { CreateFunderSchema } from "settings/yup/schemas/createFunderSchema";
import getErrorMessage from "utils/getErrorMessage";

import { funderTypesOptions } from "./options";

const Personal: React.FC = () => {
  const [t] = useTranslation();

  const {
    errors,
    control,
  } = useFormContext<CreateFunderSchema>();

  return (
    <Stack
      as="form"
      spacing={6}
      marginBottom={8}
      width="full"
    >
      <Controller
        as={Input}
        control={control}
        name="name"
        type="text"
        error={getErrorMessage("name", errors)}
        label={t("create_funder.steps.personal.name")}
        placeholder={t("create_funder.steps.personal.enter_funder_name")}
        labelAlignment="left"
        required
      />

      <Controller
        as={Picklist}
        control={control}
        name="type"
        error={getErrorMessage("type", errors)}
        label={t("create_funder.steps.personal.type")}
        placeholder={t("create_funder.steps.personal.select_a_funding_type")}
        labelAlignment="left"
        required
      >
        {
          funderTypesOptions.map((type) => (
            <Option
              name={type.value}
              value={type.value}
              label={type.label}
              key={type.label}
            />
          ))
        }
      </Controller>
    </Stack>
  );
};

export default Personal;
