import { gql } from "@apollo/client";

export const createPreviousAwardRefetchQueries = [
  "GetFunder",
];

export default gql`
  mutation CreatePreviousAward (
    $amountAwarded: Decimal!
    $awardedDate: Date
    $awardedYear: Int
    $funderId: Int!
    $programId: Int!
    $submissionDate: Date
  ) {
    createPreviousAward(
      amountAwarded: $amountAwarded
      awardedDate: $awardedDate
      awardedYear: $awardedYear
      funderId: $funderId
      programId: $programId
      submissionDate: $submissionDate
    ) {
      id
    }
  }
`;
