/* eslint-disable @typescript-eslint/no-explicit-any */

const baseContainerCss: any = {
  breakInside: "avoid",
  "*": {
    boxShadow: "none !important",
  },
};

export const containerCss: any = {
  "@media print": {
    display: "block !important",
    "*": {
      boxShadow: "none !important",
    },
  },
};

/**
 * Avoids page breaks inside a given section element container when printing.
 */
export const sectionContainerCss: any = {
  "@media print": {
    ...baseContainerCss,
  },
};

/**
 * Avoids page breaks inside a given chart element container when printing.
 */
export const chartContainerCss: any = {
  "@media print": {
    ...baseContainerCss,
    maxHeight: "500px",
  },
};

export const hideOnPrintCss: any = {
  "@media print": {
    display: "none",
  },
};

/**
 * Force page breaks after a given element container when printing.
 */

export const breakAfterContainerCss: any = {
  ...containerCss,
  pageBreakAfter: "always",
};
