import { ReportStatusEnum } from "constants/statuses";
import { ApplicationStatusEnum } from "generated/graphql";
import ArrowUpIcon from "settings/theme/ChakraTheme/icons/ArrowUpIcon";
import PencilIcon from "settings/theme/ChakraTheme/icons/PencilIcon";
import i18n from "translations/i18n";

export interface GetUpdateButtonContentProps {
  color: string;
  Icon: React.FC;
  label: string;
}

const getUpdateButtonContent = (
  status?: ApplicationStatusEnum | ReportStatusEnum,
): GetUpdateButtonContentProps => {
  if (
    status === ApplicationStatusEnum.Awarded
    || status === ApplicationStatusEnum.Declined
    || status === ReportStatusEnum.Submitted
  ) {
    return {
      color: "status.success",
      label: i18n.t("actions.edit"),
      Icon: PencilIcon,
    };
  }

  return {
    color: "secondary.200",
    label: i18n.t("actions.update"),
    Icon: ArrowUpIcon,
  };
};

export default getUpdateButtonContent;
