export const backendUrl = (
  process.env.NODE_ENV === "production"
    ? `${window.location.protocol}//${window.location.host}`
    : `${window.location.protocol}//localhost:4000`
);

export const ROOT_PAGE_PATH = "/";

export const ADMIN_PAGE_PATH = "/admin";

export const DASHBOARD_PAGE_PATH = "/dashboard";

export const GRANT_FLOW_PAGE_PATH = "/grant-flow";

export const REPORT_CENTER_PAGE_PATH = "/report-center";

export const FUNDERS_LIST_PAGE_PATH = "/funders";

export const LARGEST_FUNDERS_PAGE_PATH = "/largest-funders";

export const CALENDAR_PAGE_PATH = "/calendar";

export const LOGIN_PAGE_PATH = "/login";

export const SIGN_UP_PAGE_PATH = "/sign-up";

export const FORGOT_PASSWORD_PAGE_PATH = "/forgot-password";

export const RESET_PASSWORD_PAGE_PATH = "/reset-password";

export const FUNDER_PROFILE_PAGE_PATH = "/funder/:id";
