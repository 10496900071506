import React from "react";
import { Stack } from "@chakra-ui/layout";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Input, Option, Picklist } from "react-rainbow-components";
import { Skeleton } from "@chakra-ui/skeleton";

import { funderTypesOptions } from "components/CreateGrantForm/Steps/Deadlines/options";
import getErrorMessage from "utils/getErrorMessage";
import MaskedInput from "components/FormComponents/MaskedInput";
import { PHONE_MASK } from "constants/masks";
import ExternalLinkInput from "components/CustomInputs/ExternalLinkInput";

import { ContactInformationFieldsProps } from "./types";

const ContactInformationFields: React.FC<ContactInformationFieldsProps> = ({
  control,
  isEditing,
  errors,
  keyContactsCount,
  isLoaded = true,
}) => {
  const [t] = useTranslation();

  return (
    <form className="contact-information-form">
      <Stack
        spacing={6}
        flexDirection="column"
      >
        <Skeleton isLoaded={isLoaded}>
          <Controller
            as={Input}
            control={control}
            name="name"
            readOnly={!isEditing}
            label={t("contact_information_card.name")}
            error={getErrorMessage("name", errors)}
            labelAlignment="left"
          />
        </Skeleton>

        <Skeleton isLoaded={isLoaded}>
          <Controller
            as={Input}
            control={control}
            name="keyContacts"
            readOnly={!isEditing}
            label={t("contact_information_card.key_contact", {
              count: keyContactsCount,
            })}
            error={getErrorMessage("keyContacts", errors)}
            labelAlignment="left"
          />
        </Skeleton>

        <Skeleton isLoaded={isLoaded}>
          <Controller
            as={Input}
            control={control}
            name="email"
            readOnly={!isEditing}
            label={t("contact_information_card.email_address")}
            error={getErrorMessage("email", errors)}
            labelAlignment="left"
          />
        </Skeleton>

        <Skeleton isLoaded={isLoaded}>
          <Controller
            as={MaskedInput}
            name="phone"
            type="text"
            control={control}
            mask={PHONE_MASK}
            readOnly={!isEditing}
            title={t("contact_information_card.phone_number")}
            errors={errors}
          />
        </Skeleton>

        <Skeleton isLoaded={isLoaded}>
          <Controller
            as={Picklist}
            control={control}
            name="type"
            readOnly={!isEditing}
            label={t("contact_information_card.type")}
            error={getErrorMessage("type", errors)}
            labelAlignment="left"
          >
            {
              funderTypesOptions.map((type) => (
                <Option
                  name={type.value}
                  value={type.value}
                  label={type.label}
                  key={type.label}
                />
              ))
            }
          </Controller>
        </Skeleton>

        <Skeleton isLoaded={isLoaded}>
          <Controller
            as={ExternalLinkInput}
            control={control}
            name="website"
            readOnly={!isEditing}
            label={t("contact_information_card.website")}
            error={getErrorMessage("website", errors)}
            labelAlignment="left"
          />
        </Skeleton>
      </Stack>
    </form>
  );
};

export default ContactInformationFields;
