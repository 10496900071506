import { maskArray } from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const defaultCurrencyMaskOptions = {
  prefix: "$",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export interface MaskOptions {
  prefix?: string;
  suffix?: string;
  includeThousandsSeparator?: boolean;
  thousandsSeparatorSymbol?: string;
  allowDecimal?: boolean;
  decimalSymbol?: string;
  decimalLimit?: string;
  requireDecimal?: boolean;
  allowNegative?: boolean;
  allowLeadingZeroes?: boolean;
  integerLimit?: number;
}

/**
 * Defines the currency mask characteristics
 * @param maskOptions currency mask parameters
 * e.g.: {
 * prefix: "$",
 * suffix: "",
 * includeThousandsSeparator: true,
 * }
 */
export const buildCurrencyMask = (maskOptions?: MaskOptions): maskArray => createNumberMask({
  ...defaultCurrencyMaskOptions,
  ...maskOptions,
});

export const PHONE_MASK = ["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];

export const ZIP_CODE_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/];

export const YEAR_MASK = [/\d/, /\d/, /\d/, /\d/];

export const ZIP_CODE_LENGTH = 5;

export const STATE_MASK = [/[a-zA-Z]/, /[a-zA-Z]/];

export const STATE_LENGTH = 2;
