import React, { useCallback } from "react";
import { ModalBody, ModalFooter } from "@chakra-ui/modal";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import grantUpdateSchema, { GrantUpdateSchema } from "settings/yup/schemas/grantUpdateSchema";
import {
  ApplicationStatusEnum, ReportUrgencyEnum, UpdateApplicationParams, useUpdateApplicationMutation,
} from "generated/graphql";
import { formatDate, displayMonthDayYear, yearMonthDay } from "utils/dateFormats";
import { useToast } from "contexts/toast";
import { impersonatedUpdateApplicationRefetchQueries, updateApplicationRefetchQueries } from "graphql/mutations/updateApplicationMutation";
import { getFloat } from "utils/getFloat";
import { IsSubmittedEnum } from "components/EditReportForm/types";
import capitalizeStringFirstLetter from "utils/capitalizeStringFirstLetter";
import useAdminImpersonationStore from "components/AdminImpersonation/store";

import {
  EditGrantFormProps,
} from "./types";
import EditGrantFormFields from "./EditGrantFormFields";

const EditGrantForm: React.FC<EditGrantFormProps> = ({
  hideModal,
  buttonLabel,
  grantData,
}) => {
  const grantId = grantData?.getApplication?.id;

  const initialFormValues = grantData?.getApplication;

  const grantReport = grantData?.getApplication?.reports?.[0];

  const organizationId = useAdminImpersonationStore(store => store?.organizationId);

  const { addToast } = useToast();

  const [t] = useTranslation();

  const {
    handleSubmit,
    errors,
    control,
    register,
    watch,
  } = useForm<GrantUpdateSchema>({
    resolver: yupResolver(grantUpdateSchema),
    mode: "onChange",
    defaultValues: {
      isSubmitted: (
        initialFormValues?.submission?.submissionDate
          ? IsSubmittedEnum.Yes
          : IsSubmittedEnum.No
      ),
      dateSubmitted: initialFormValues?.submission?.submissionDate && formatDate(
        initialFormValues?.submission?.submissionDate,
        displayMonthDayYear,
      ),
      amountRequested: initialFormValues?.submission?.amountRequested,
      receivementDate: initialFormValues?.award?.receivementDate && formatDate(
        initialFormValues?.award?.receivementDate,
        displayMonthDayYear,
      ),
      deadline: initialFormValues?.deadline && formatDate(
        initialFormValues?.deadline,
        displayMonthDayYear,
      ),
      status: initialFormValues?.status,
      amountAwarded: initialFormValues?.award?.amountAwarded,
      reportType: {
        label: grantReport?.urgency
          && capitalizeStringFirstLetter(grantReport.urgency.toLowerCase()),
        value: grantReport?.urgency,
        name: grantReport?.urgency,
      },
      reportDeadline: grantReport?.deadline,
    },
  });

  const [
    updateApplication,
    {
      loading: updateApplicationLoading,
    },
  ] = useUpdateApplicationMutation({
    refetchQueries: organizationId
      ? impersonatedUpdateApplicationRefetchQueries
      : updateApplicationRefetchQueries,
    awaitRefetchQueries: true,
  });

  const onSubmit = useCallback((values: GrantUpdateSchema) => {
    if (!grantId || !values) {
      return;
    }

    const params: UpdateApplicationParams = {
      submission: {
        amountRequested: getFloat(values?.amountRequested) || undefined,
        submissionDate: formatDate(values?.dateSubmitted, yearMonthDay),
      },
      declined: values?.status === ApplicationStatusEnum.Declined,
      deadline: formatDate(values?.deadline, yearMonthDay),
      reports: (
        (values?.reportType && values?.reportDeadline)
          ? [{
            urgency: values?.reportType?.value as ReportUrgencyEnum,
            deadline: formatDate(values?.reportDeadline, yearMonthDay),
          }]
          : undefined
      ),
    };

    if (values?.isSubmitted === IsSubmittedEnum.No) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      params.submission = null as any;
      params.declined = undefined;
      params.deadline = formatDate(values?.deadline, yearMonthDay);
    }

    if (values?.amountAwarded) {
      params.award = {
        amountAwarded: getFloat(values?.amountAwarded) || undefined,
        receivementDate: formatDate(
          values?.receivementDate,
          yearMonthDay,
        ) || undefined,
      };
    }

    if (values?.status === ApplicationStatusEnum.Pending) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      params.award = null as any;
    }

    updateApplication({
      variables: {
        id: grantId,
        params,
      },
    })
      .then(() => {
        addToast({
          title: t("actions.application_has_been_updated"),
          type: "success",
        });

        hideModal();
      })
      .catch(error => (
        addToast({
          title: capitalizeStringFirstLetter(error.message),
          type: "error",
        })
      ));
  }, [
    t,
    grantId,
    addToast,
    hideModal,
    updateApplication,
  ]);

  const isSubmitted = watch("isSubmitted") === IsSubmittedEnum.Yes;

  const isAwarded = watch("status") === ApplicationStatusEnum.Awarded;

  return (
    <>
      <ModalBody
        as="form"
        width="full"
      >
        <EditGrantFormFields
          control={control}
          register={register}
          errors={errors}
          isSubmitted={isSubmitted}
          isAwarded={isAwarded}
          shouldShowDeadlineField
        />
      </ModalBody>

      <ModalFooter width="full">
        <Button
          onClick={handleSubmit(onSubmit)}
          width="full"
          backgroundColor="secondary.200"
          isLoading={updateApplicationLoading}
        >
          {buttonLabel}
        </Button>
      </ModalFooter>
    </>
  );
};

export default EditGrantForm;
