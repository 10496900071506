import React from "react";
import MaskedInputComponent from "react-text-mask";
import mergeRefs from "react-merge-refs";
import { FormControl } from "@chakra-ui/form-control";
import { Input as ChakraInput, InputGroup, InputRightElement } from "@chakra-ui/input";

import FieldErrorMessage from "components/FormComponents/FieldErrorMessage";
import getErrorMessage from "utils/getErrorMessage";

import { maskPlaceholderChar } from "./maskPlaceholderChar";
import { MaskedInputProps } from "./types";
import FieldTitle from "./FieldTitle";

const MaskedInput = React.forwardRef<HTMLElement, MaskedInputProps>((
  {
    errorBorderColor = "status.error",
    variant = "filled",
    validationMessage,
    fontSize = "xs",
    showMask = false,
    mask,
    titleProps,
    onChange,
    onBlur,
    errors,
    title,
    name,
    isRequired,
    rightElement,
    readOnly = false,
    ...rest
  },
  ref,
) => {
  const error = getErrorMessage(name, errors);

  const visibleBorderThickness = error ? "2px solid" : "1px solid";

  const focusStyle = readOnly
    ? ""
    : {
      background: "background",
      borderWidth: "2px",
      borderColor: "primary.100",
      boxShadow: "0 0 2px",
    };

  const borderThickness = readOnly ? "2px transparent" : visibleBorderThickness;

  const borderColor = readOnly ? "" : "gray.300";

  const paddingStyle = readOnly ? "0px" : "16px";

  const backgroundColor = rest.isDisabled ? "gray.300" : "background";

  return (
    <FormControl>
      {
        title && (
          <FieldTitle
            htmlFor={name}
            isRequired={isRequired}
            {...(titleProps ?? {})}
          >
            {title}
          </FieldTitle>
        )
      }

      <MaskedInputComponent
        placeholderChar={maskPlaceholderChar}
        showMask={showMask}
        onChange={onChange}
        onBlur={onBlur}
        mask={mask}
        readOnly={readOnly}
        render={(maskRef, props) => (
          <InputGroup>
            <ChakraInput
              {...props}
              {...rest}
              borderRadius={50}
              hover="none"
              _focus={focusStyle}
              focusBorderColor="primary.100"
              paddingLeft={paddingStyle}
              outline="none"
              background={backgroundColor}
              border={borderThickness}
              borderColor={borderColor}
              errorBorderColor={errorBorderColor}
              ref={mergeRefs([maskRef, ref])}
              fontSize={fontSize}
              isInvalid={!!error}
              variant={variant}
              name={name}
              _placeholder={{
                fontFamily: "mono",
                fontSize: "xs",
                color: "gray",
                opacity: 0.6,
              }}
            />

            {
              rightElement && (
                <InputRightElement>
                  { rightElement }
                </InputRightElement>
              )
            }
          </InputGroup>
        )}
      />

      <FieldErrorMessage
        validationMessage={validationMessage}
        textAlign="center"
        error={error}
      />
    </FormControl>
  );
});

export default MaskedInput;
