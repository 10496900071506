import React from "react";
import { VStack } from "@chakra-ui/layout";

import EditGrantForm from "components/EditGrantForm";
import { useGetApplicationQuery } from "generated/graphql";
import Loading from "components/Loading";

import { UpdateGrantModalProps } from "./types";

const UpdateGrantModal: React.FC<UpdateGrantModalProps> = ({
  hideModal,
  componentProps: {
    id,
    buttonLabel,
  },
}) => {
  const {
    data: grantData,
    loading,
  } = useGetApplicationQuery({
    variables: {
      id,
    },
  });

  return (
    <VStack spacing={6}>
      {
        loading && (
          <Loading height="xxs" />
        )
      }

      {
        !loading && (
          <EditGrantForm
            grantData={grantData}
            buttonLabel={buttonLabel}
            hideModal={hideModal}
          />
        )
      }
    </VStack>
  );
};

export default UpdateGrantModal;
