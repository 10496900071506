import React, { useMemo } from "react";
import { Flex } from "@chakra-ui/layout";
import { Option, Picklist } from "react-rainbow-components";

import getYearsInInterval from "utils/getYearsInInterval";
import useFilter, { filters } from "hooks/useFilter";

interface YearFilterProps {
  startDate: Date;
}

const YearFilter: React.FC<YearFilterProps> = ({ startDate }) => {
  const [awardsComparisonYear, setAwardsComparisonYear] = useFilter(
    filters.awardsComparisonYear,
  );
  const [fundingByTypeYear, setFundingByTypeYear] = useFilter(
    filters.fundingByTypeYear,
  );

  const options = useMemo(
    () => getYearsInInterval({
      start: startDate,
      end: new Date(),
    }), [startDate],
  );

  const handleChange = (option: any): void => {
    setAwardsComparisonYear(option?.value);
    setFundingByTypeYear(option?.value);
  };

  const value = awardsComparisonYear === fundingByTypeYear ? awardsComparisonYear : undefined;

  return (
    <Flex maxWidth="140px">
      <Picklist
        value={value ? { value, label: `FY ${value}` } : undefined}
        onChange={handleChange}
        placeholder="Choose FY"
      >
        {options?.map((year) => (
          <Option value={year} label={`FY ${year}`} name={year} key={year} />
        ))}
      </Picklist>
    </Flex>
  );
};
export default YearFilter;
