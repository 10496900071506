import React, { useCallback, useMemo } from "react";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { Fade } from "@chakra-ui/transition";
import { useTranslation } from "react-i18next";

import ArrowUpIcon from "settings/theme/ChakraTheme/icons/ArrowUpIcon";
import { useModal } from "contexts/modal";
import useHover from "hooks/useHover";
import UpdateGrantModal from "components/Modal/UpdateGrantModal";
import UpdateReportModal from "components/Modal/UpdateReportModal";
import { TableTypeEnum } from "constants/types";
import i18n from "translations/i18n";

import { UpdateButtonProps, UpdateModalProps } from "./types";

const UpdateButton: React.FC<UpdateButtonProps> = ({
  id,
  type,
  label = i18n.t("actions.update"),
  color = "primary.100",
  Icon = ArrowUpIcon,
}) => {
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();

  const [t] = useTranslation();

  const [showModal] = useModal();

  const updateModal = useMemo(() => {
    const currentModal: UpdateModalProps = {
      title: t("modals.update_grant.update_grant"),
      component: UpdateGrantModal,
      componentProps: {
        buttonLabel: t("actions.update_information"),
        id,
      },
    };

    if (type === TableTypeEnum.Report) {
      currentModal.component = UpdateReportModal;
      currentModal.title = t("modals.update_report.update_report");
    }

    return currentModal;
  }, [
    t,
    id,
    type,
  ]);

  const handleClick = useCallback(() => {
    showModal(updateModal);
  }, [
    showModal,
    updateModal,
  ]);

  return (
    <Flex
      position="absolute"
      right={2}
      ref={hoverRef}
      pointer="cursor"
    >
      <Flex position="relative">
        <Fade
          in={isHovered}
          unmountOnExit
        >
          <Text
            position="absolute"
            top="-10px"
            fontSize="xxs"
            color={color}
            left="15px"
            transform="translateX(-50%)"
            lineHeight="16px"
          >
            {label}
          </Text>
        </Fade>

        <Box
          as="button"
          outline="none"
          width="32px"
          onClick={handleClick}
          _active={
            {
              transform: "scale(0.8)",
              transition: "transform 0.1s",
            }
          }
        >
          <Icon
            color={color}
            boxSize={4}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default UpdateButton;
