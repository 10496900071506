import { gql } from "@apollo/client";

export default gql`
  query GetImpersonatedOrganizationCurrentYearGoal ($organizationId: Int!)  {
    getOrganization (id: $organizationId) {
      id
      name
      currentYearGoal {
        overall {
          achieved
          achievedRate
          goal
          missing
        }
        programs {
          id
          name
          achievedAmount
          missingAmount
          yearlyGoal
        }
      }
    }
  }
`;
