import React from "react";
import { useTranslation } from "react-i18next";

import UpdateButton from "components/Table/TableCell/UpdateButton";
import { ApplicationStatusEnum } from "generated/graphql";
import { ReportStatusEnum } from "constants/statuses";
import getUpdateButtonContent from "utils/getUpdateButtonContent";

import { UpdateButtonCellProps } from "./types";
import DefaultCell from "./DefaultCell";

export const statusWithActions: Array<ApplicationStatusEnum | ReportStatusEnum> = [
  ApplicationStatusEnum.NotApply,
  ApplicationStatusEnum.Pending,
  ApplicationStatusEnum.Awarded,
  ApplicationStatusEnum.Declined,
  ReportStatusEnum.NotSubmitted,
  ReportStatusEnum.Submitted,
];

const UpdateButtonCell: React.FC<UpdateButtonCellProps> = ({
  value,
  row,
}) => {
  const hasButton = value ? statusWithActions.includes(value) : false;
  const buttonContent = getUpdateButtonContent(value);

  const [t] = useTranslation();

  return (
    <DefaultCell value={t(`statuses.${value?.toLowerCase()}`)}>
      {
        hasButton && (
          <UpdateButton
            id={Number(row?.id)}
            type={row?.type}
            {...buttonContent}
          />
        )
      }
    </DefaultCell>
  );
};

export default UpdateButtonCell;
