import React from "react";
import { Flex, ListIcon, Text } from "@chakra-ui/layout";

import MedalIcon from "settings/theme/ChakraTheme/icons/MedalIcon";
import formatFunderName from "utils/formatFunderName";

import { OverallRankingItemProps } from "../types";

const OverallRankingItem: React.FC<OverallRankingItemProps> = ({
  funder,
  ...props
}) => (
  <Flex
    display="flex"
    marginBottom={1}
    alignItems="center"
    minWidth="100px"
    {...props}
  >
    <ListIcon
      as={MedalIcon}
      color="status.success"
    />

    <Text>
      {formatFunderName(funder)}
    </Text>
  </Flex>
);

export default OverallRankingItem;
