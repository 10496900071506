import { useCallback, useMemo } from "react";

import {
  GetBaseImpersonatedOrganizationQueryVariables,
  GetBaseCurrentOrganizationQueryVariables,
  GetBaseImpersonatedOrganizationQuery,
  GetBaseCurrentOrganizationQuery,
} from "generated/graphql";
import useAdminImpersonationStore from "components/AdminImpersonation/store";

import {
  QueryVariablesResolver,
  UseGetOrganizationResult,
  QueryResolver,
  QueryOptions,
} from "./types";

/**
 * Generic hook that returns the organization,
 * obtained with the `useGetCurrentOrganizationQuery` param.
 *
 * If the current user is an admin and has a selected organization to impersonate, it'll query
 * for that given organization instead, using the `useGetImpersonatedOrganizationQuery`.
 */
function useGetOrganization<
  TCurrentOrganizationData
    extends GetBaseCurrentOrganizationQuery,

  TCurrentOrganizationVariables
    extends QueryVariablesResolver<GetBaseCurrentOrganizationQueryVariables>,

  TImpersonatedOrganizationData
    extends GetBaseImpersonatedOrganizationQuery,

  TImpersonatedOrganizationVariables
    extends QueryVariablesResolver<GetBaseImpersonatedOrganizationQueryVariables>,
> (
  useGetCurrentOrganizationQuery: QueryResolver<
    TCurrentOrganizationData,
    TCurrentOrganizationVariables
  >,
  useGetImpersonatedOrganizationQuery: QueryResolver<
    TImpersonatedOrganizationData,
    TImpersonatedOrganizationVariables
  >,
  queryOptions?: QueryOptions,
): UseGetOrganizationResult<TCurrentOrganizationData, TImpersonatedOrganizationData> {
  const impersonatedOrganizationId = useAdminImpersonationStore((store) => store.organizationId);

  const {
    loading: getCurrentOrganizationLoading,
    refetch: getCurrentOrganizationRefetch,
    error: getCurrentOrganizationError,
    data: getCurrentOrganizationData,
  } = useGetCurrentOrganizationQuery({
    ...(queryOptions ?? {}),
    skip: !!(
      !!impersonatedOrganizationId
      || queryOptions?.skip
    ),
  });

  const {
    loading: getImpersonatedOrganizationLoading,
    refetch: getImpersonatedOrganizationRefetch,
    error: getImpersonatedOrganizationError,
    data: getImpersonatedOrganizationData,
  } = useGetImpersonatedOrganizationQuery({
    ...(queryOptions ?? {}),
    skip: !!(
      !impersonatedOrganizationId
      || queryOptions?.skip
    ),
    variables: {
      ...(queryOptions?.variables ?? {}),
      organizationId: impersonatedOrganizationId as number,
    } as TImpersonatedOrganizationVariables,
  });

  const refetch = useCallback(() => {
    if (impersonatedOrganizationId) {
      const payload = {
        ...(queryOptions?.variables ?? {}),
        organizationId: impersonatedOrganizationId as number,
      } as TImpersonatedOrganizationVariables;

      getImpersonatedOrganizationRefetch?.(payload);

      return;
    }

    getCurrentOrganizationRefetch?.();
  }, [
    getImpersonatedOrganizationRefetch,
    getCurrentOrganizationRefetch,
    queryOptions,
    impersonatedOrganizationId,
  ]);

  const organization = useMemo(() => (
    impersonatedOrganizationId
      ? getImpersonatedOrganizationData?.getOrganization
      : getCurrentOrganizationData?.currentUser?.organization
  ), [
    getCurrentOrganizationData,
    getImpersonatedOrganizationData,
    impersonatedOrganizationId,
  ]);

  const loading = (
    getCurrentOrganizationLoading
    || getImpersonatedOrganizationLoading
  );

  const error = (
    getCurrentOrganizationError
    || getImpersonatedOrganizationError
  );

  const payload = useMemo<UseGetOrganizationResult<
  TCurrentOrganizationData,
  TImpersonatedOrganizationData
  >>(
    () => [
      organization,
      {
        impersonatedOrganizationData: getImpersonatedOrganizationData,
        currentOrganizationData: getCurrentOrganizationData,
        refetch,
        loading,
        error,
      },
    ],
    [
      getImpersonatedOrganizationData,
      getCurrentOrganizationData,
      refetch,
      organization,
      loading,
      error,
    ],
  );

  return payload;
}

export default useGetOrganization;
