import { ReportCenterTableTitlesEnum } from "constants/reportCenterTableTitles";
import { reportCenterStatuses } from "constants/statuses";
import { TableTypeEnum } from "constants/types";
import { ListReportsQuery } from "generated/graphql";

import { displayMonthDayYear, formatDate } from "./dateFormats";
import formatCurrency from "./formatCurrency";
import formatStatus from "./formatStatus";

export interface ReportProps {
  id: string;

  type: TableTypeEnum;

  funderId: string;

  [ReportCenterTableTitlesEnum.ReportDeadline]?: string;

  [ReportCenterTableTitlesEnum.Funder]?: string;

  [ReportCenterTableTitlesEnum.AmountAwarded]?: string;

  [ReportCenterTableTitlesEnum.Program]?: string;

  [ReportCenterTableTitlesEnum.Urgency]?: string;

  [ReportCenterTableTitlesEnum.DateSubmitted]?: string;

  [ReportCenterTableTitlesEnum.Status]?: string;
}

/**
 * Receives reportsTableData from the listReports query and
 * returns it formatted
 * @param tableData rawTableData
 */
const formatReportsTableData = (
  tableData: ListReportsQuery,
): ReportProps[] => {
  const reportsList = tableData.listReports;

  if (!tableData || !reportsList) {
    return [];
  }

  const formattedTableData = reportsList.map(report => (
    {
      id: report?.id?.toString() ?? "-",

      type: TableTypeEnum.Report,

      funderId: report?.grantApplication?.grant?.funder?.id.toString() ?? "-",

      [ReportCenterTableTitlesEnum.ReportDeadline]: formatDate(
        report?.deadline,
        displayMonthDayYear,
      ),

      [ReportCenterTableTitlesEnum.Funder]: report?.grantApplication?.grant?.funder?.name ?? "-",

      [ReportCenterTableTitlesEnum.AmountAwarded]: formatCurrency(
        report?.grantApplication?.award?.amountAwarded,
      ) ?? "-",

      [ReportCenterTableTitlesEnum.Program]: report?.grantApplication?.grant?.program?.name ?? "-",

      [ReportCenterTableTitlesEnum.Urgency]: formatStatus(report?.urgency) ?? "-",

      [ReportCenterTableTitlesEnum.DateSubmitted]: formatDate(
        report?.submissionDate,
        displayMonthDayYear,
      ),

      [ReportCenterTableTitlesEnum.Status]: report?.submitted
        ? reportCenterStatuses.SUBMITTED.value
        : reportCenterStatuses.NOT_SUBMITTED.value,
    }
  ));

  return formattedTableData;
};

export default formatReportsTableData;
