import React from "react";
import { Text } from "@chakra-ui/layout";

import TableCellContainer from "components/Table/TableCell";
import ExternalLink from "components/ExternalLink";

import { ExternalLinkCellProps } from "./types";

const ExternalLinkCell: React.FC<ExternalLinkCellProps> = ({
  value,
}) => (
  <TableCellContainer>
    {
      value ? (
        <ExternalLink
          url={value}
          color="primary.100"
          _hover={{
            color: "primary.100",
          }}
        >
          {value}
        </ExternalLink>
      ) : (
        <Text>
          -
        </Text>
      )
    }
  </TableCellContainer>
);

export default ExternalLinkCell;
