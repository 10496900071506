import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from "@chakra-ui/modal";
import { Heading, Divider, Box } from "@chakra-ui/layout";
import { useDisclosure } from "@chakra-ui/hooks";
import React, { useCallback, useMemo, useState } from "react";

import {
  ModalContextPayload,
  ModalContainerProps,
  OnChakraModalClose,
  ShowModalOptions,
  ModalState,
} from "./types";
import { ModalProvider } from ".";

function ModalContainer<T = Record<string, unknown>>({
  children,
}: ModalContainerProps<T>): React.ReactElement {
  const defaultState = useMemo<ModalState<T>>(() => ({
    title: "",
    size: "lg",
    showDivider: true,
    onClose: undefined,
    component: undefined,
    isCloseable: true,
    closeOnOverlayClick: true,
    colorScheme: "primary",
    componentProps: {},
    modalHeaderProps: {},
    modalContentProps: {},
    modalCloseButtonProps: {},
  }), []);

  const [modalState, setModalState] = useState<ModalState<T>>(defaultState);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const showModal = useCallback((options: ShowModalOptions<T> | unknown) => {
    const modalOptions = options as ShowModalOptions<T>;

    const newState = {
      ...defaultState,
      ...modalOptions,
      isCloseable: modalOptions?.isCloseable ?? defaultState.isCloseable,
      closeOnOverlayClick: modalOptions?.closeOnOverlayClick ?? defaultState.isCloseable,
    };

    setModalState(newState);
    onOpen();
  }, [
    defaultState,
    onOpen,
  ]);

  const hideModal = useCallback((payload) => {
    modalState.onClose?.(payload ?? {});

    setModalState(defaultState);
    onClose();
  }, [
    defaultState,
    modalState,
    onClose,
  ]);

  const payload = useMemo<ModalContextPayload<T>>(() => [
    showModal,
    hideModal,
    {
      isOpen,
    },
  ], [
    showModal,
    hideModal,
    isOpen,
  ]);

  const Component = modalState?.component;
  const componentProps = (modalState?.componentProps || {}) as T;

  return (
    <ModalProvider value={payload}>
      {children}

      <Modal
        closeOnOverlayClick={modalState?.closeOnOverlayClick}
        onClose={hideModal as OnChakraModalClose}
        closeOnEsc={modalState?.isCloseable}
        size={modalState?.size?.toString()}
        isOpen={isOpen}
        isCentered
      >
        <ModalOverlay
          zIndex={1000}
        >
          <ModalContent
            {...(modalState?.modalContentProps ?? {})}
          >
            <ModalHeader
              {...(modalState?.modalHeaderProps ?? {})}
            >
              <Heading
                color={`${defaultState?.colorScheme}.200`}
                textAlign="center"
                fontSize="md"
              >
                {modalState?.title}
              </Heading>
            </ModalHeader>

            {
              modalState?.showDivider && (
                <Divider borderColor="gray.100" margin={0} />
              )
            }

            {
              modalState?.isCloseable && (
                <ModalCloseButton
                  {...(modalState?.modalCloseButtonProps ?? {})}
                />
              )
            }

            <Box
              maxHeight="80vh"
              overflowX="auto"
              overflowY="auto"
            >
              {
                Component && (
                  <Component
                    componentProps={componentProps}
                    hideModal={hideModal}
                  />
                )
              }
            </Box>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </ModalProvider>
  );
}

export default ModalContainer;
