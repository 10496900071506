import { Stack } from "@chakra-ui/layout";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import { useModal } from "contexts/modal";
import CreateGrantForm from "components/CreateGrantForm";
import CreateFunderForm from "components/CreateFunderForm";
import CreateProgramForm from "components/CreateProgramForm";
import { FUNDER_PROFILE_PAGE_LOCATION } from "routes/locations";
import useNavigate from "hooks/useNavigate";
import { CreateFunderMutation } from "generated/graphql";

const GrantFlowActions: React.FC = () => {
  const [t] = useTranslation();
  const [showModal] = useModal();
  const navigate = useNavigate();

  const buttonsFontSize = { base: "xxs", md: "xxs", lg: "xs" };

  const openCreateNewGrantModal = useCallback(() => {
    showModal({
      title: t("create_grant.title"),
      component: CreateGrantForm,
      closeOnOverlayClick: false,
    });
  }, [
    t,
    showModal,
  ]);

  const successCallback = useCallback((funder?: CreateFunderMutation["createFunder"]) => {
    const funderPageUrl = FUNDER_PROFILE_PAGE_LOCATION.toUrl({ id: funder?.id });

    navigate(funderPageUrl)();
  }, [
    navigate,
  ]);

  const openCreateNewFunderModal = useCallback(() => {
    showModal({
      title: t("create_funder.title"),
      component: CreateFunderForm,
      componentProps: {
        successCallback,
      },
      closeOnOverlayClick: false,
    });
  }, [
    t,
    showModal,
    successCallback,
  ]);

  const openCreateNewProgramModal = useCallback(() => {
    showModal({
      title: t("create_program.title"),
      component: CreateProgramForm,
      closeOnOverlayClick: false,
    });
  }, [
    t,
    showModal,
  ]);

  return (
    <Stack
      spacing={6}
      direction={{ base: "column", md: "row" }}
      mt={{ md: "0 !important" }}
    >
      <Button
        background="status.warning"
        fontSize={buttonsFontSize}
        onClick={openCreateNewFunderModal}
      >
        {t("buttons.add_new_funder")}
      </Button>

      <Button
        background="secondary.200"
        fontSize={buttonsFontSize}
        onClick={openCreateNewProgramModal}
      >
        {t("buttons.add_new_program")}
      </Button>

      <Button
        background="status.success"
        fontSize={buttonsFontSize}
        onClick={openCreateNewGrantModal}
      >
        {t("buttons.add_new_grant")}
      </Button>
    </Stack>
  );
};

export default GrantFlowActions;
