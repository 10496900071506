import { Flex, Text } from "@chakra-ui/layout";
import React from "react";

import { TableFooterProps } from "./types";

const TableFooter: React.FC<TableFooterProps> = ({
  title,
  value,
  ...props
}) => (
  <Flex {...props}>
    <Text
      fontFamily="heading"
      fontSize="xs"
      color="primary.200"
      marginTop={8}
    >
      {`${title}:`}

      {" "}

      <Text
        as="span"
        fontFamily="mono"
      >
        {value}
      </Text>
    </Text>
  </Flex>
);

export default TableFooter;
