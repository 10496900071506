import libPhoneNumber, { PhoneNumberFormat } from "google-libphonenumber";

const phoneUtil = libPhoneNumber.PhoneNumberUtil.getInstance();

/**
 * Format phone number with country code
 * @param number e.g.: +19801542335
 */
const formatUSPhoneNumber = (number: string | undefined): string | undefined => {
  if (!number) {
    return undefined;
  }

  const phoneNumber = phoneUtil.parseAndKeepRawInput(number, "US");

  const result = phoneUtil.format(phoneNumber, PhoneNumberFormat.NATIONAL);

  return result;
};

export default formatUSPhoneNumber;
