import { gql } from "@apollo/client";

export default gql`
  query GetFunder ($id: Int!) {
    getFunder(id: $id) {
      id
      name
      keyContacts
      email
      phone
      type
      website
      howToApply
      notes
      isPaused
      location {
        id
        city
        complement
        state
        streetAddress
        zipcode
      }
      awards {
        id
        isPrecisionByYear
        receivementDate
        amountAwarded
        program {
          id
          name
        }
        grantApplication {
          id
          submission {
            id
            submissionDate
          }
        }
        responseDays
      }
    }
  }
`;
