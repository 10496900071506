import { gql } from "@apollo/client";

export const updateApplicationRefetchQueries = [
  "ListApplications",
  "GetGrantsSummary",
];

export const impersonatedUpdateApplicationRefetchQueries = [
  "ListApplications",
  "GetImpersonatedOrganizationGrantsSummary",
];
export default gql`
  mutation UpdateApplication (
    $id: Int!,
    $params: UpdateApplicationParams!,
  ) {
    updateApplication(
      id: $id,
      params: $params,
    ) {
      id
    }
  }
`;
