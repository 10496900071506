import CapitalizedCell from "components/Table/TableCell/CellValue/CapitalizedCell";
import ExternalLinkCell from "components/Table/TableCell/CellValue/ExternalLinkCell";
import FunderCell from "components/Table/TableCell/CellValue/FunderCell";
import i18n from "translations/i18n";

import { ColumnContent } from "./grantFlowTableTitles";

export enum FundersTableTitlesEnum {
  Name = "name",

  Email = "email",

  Type = "type",

  Website = "website",

  TotalAwardAmount = "totalAwardAmount",
}

export const fundersTableTitles: Record<FundersTableTitlesEnum, ColumnContent> = {
  [FundersTableTitlesEnum.Name]: {
    label: i18n.t("tables.columns_titles.name"),
    field: FundersTableTitlesEnum.Name,
    component: FunderCell,
  },
  [FundersTableTitlesEnum.Email]: {
    label: i18n.t("tables.columns_titles.email"),
    field: FundersTableTitlesEnum.Email,
  },
  [FundersTableTitlesEnum.Type]: {
    label: i18n.t("tables.columns_titles.type"),
    field: FundersTableTitlesEnum.Type,
    component: CapitalizedCell,
  },
  [FundersTableTitlesEnum.Website]: {
    label: i18n.t("tables.columns_titles.website"),
    field: FundersTableTitlesEnum.Website,
    component: ExternalLinkCell,
  },
  [FundersTableTitlesEnum.TotalAwardAmount]: {
    label: i18n.t("tables.columns_titles.total_award_amount"),
    field: FundersTableTitlesEnum.TotalAwardAmount,
  },
};

/**
 * Defines funders table titles.
 */
export const fundersTableFormattedTitles = Object.keys(fundersTableTitles).map(
  (key) => ({
    label: fundersTableTitles[key].label as string,
    field: fundersTableTitles[key].field as string,
    component: fundersTableTitles[key]?.component,
  }),
);
