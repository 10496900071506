import { Flex, Text } from "@chakra-ui/layout";
import React from "react";
import { useTranslation } from "react-i18next";

import OverallRankingItem from "./OverallRankingItem";
import { FunderNameAward, LargestFundersFooterProps } from "../types";
import { largestFundersFooterStyles } from "./styles";

const LargestFundersFooter: React.FC<LargestFundersFooterProps> = ({
  overall,
}) => {
  const [t] = useTranslation();

  const overallRanking = overall?.slice(0, 5);

  return (
    <Flex
      marginTop={4}
      flexDirection="column"
    >
      <Text
        marginBottom={2}
        fontFamily="heading"
        fontSize={{ base: "xxs", md: "xs", lg: "xs" }}
      >
        {t("largest_funders.overall_ranking")}
      </Text>

      <Flex
        flexDir={{ base: "column", sm: "row" }}
        justify="space-between"
        flexWrap={{ base: "wrap", sm: "nowrap" }}
        css={largestFundersFooterStyles}
      >
        {
          overallRanking && overallRanking.map((funder: FunderNameAward) => (
            <OverallRankingItem
              funder={funder}
              key={funder.id}
              mr={4}
            />
          ))
        }
      </Flex>
    </Flex>
  );
};

export default LargestFundersFooter;
