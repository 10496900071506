/* eslint-disable @typescript-eslint/no-explicit-any */

export const printableHeaderStyles: any = {
  "@media print": {
    display: "block !important",
    top: "1rem",
    left: "2rem",
    maxHeight: "60px",
    position: "absolute",
  },
};
