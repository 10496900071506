import { gql } from "@apollo/client";

export const UpdateFunderRefetchQueries = [
  "ListFunders",
];

export default gql`
  mutation UpdateFunder (
    $id: Int!,
    $params: UpdateFunderParams!,
  ) {
    updateFunder(
      id: $id,
      params: $params,
    ) {
      id
      isPaused
    }
  }
`;
