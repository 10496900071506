import * as React from "react";
import { IconProps } from "@chakra-ui/icons";

import ArrowRightUpIcon from "./ArrowRightUpIcon";

const ArrowUpIcon: React.FC<IconProps> = (props) => (
  <ArrowRightUpIcon
    {...props}
    transform="rotate(-45deg)"
  />
);

export default ArrowUpIcon;
