import { DeadlineTypeEnum, FunderTypeEnum } from "generated/graphql";
import { Status } from "constants/statuses";
import i18n from "translations/i18n";

export const funderTypes: Record<FunderTypeEnum, Status> = {
  [FunderTypeEnum.Corporate]: {
    label: i18n.t("Corporate"),
    value: FunderTypeEnum.Corporate,
  },
  [FunderTypeEnum.Foundation]: {
    label: i18n.t("Foundation"),
    value: FunderTypeEnum.Foundation,
  },
  [FunderTypeEnum.Government]: {
    label: i18n.t("Government"),
    value: FunderTypeEnum.Government,
  },
  [FunderTypeEnum.Other]: {
    label: i18n.t("Other"),
    value: FunderTypeEnum.Other,
  },
};

export const funderTypesOptions = Object.keys(funderTypes).map((key): Status => ({
  label: funderTypes[key].label as string,
  value: key,
}));

export const deadlineTypes: Record<DeadlineTypeEnum, Status> = {
  [DeadlineTypeEnum.Required]: {
    label: i18n.t("deadlines.hard"),
    value: DeadlineTypeEnum.Required,
  },
  [DeadlineTypeEnum.Rolling]: {
    label: i18n.t("deadlines.rolling"),
    value: DeadlineTypeEnum.Rolling,
  },
};

export const deadlineOptions = Object.keys(deadlineTypes).map((key): Status => ({
  label: deadlineTypes[key].label as string,
  value: key,
}));
