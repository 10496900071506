import { gql } from "@apollo/client";

export const CreateProgramRefetchQueries = [
  "GetCurrentYearGoal",
];

export default gql`
  mutation CreateProgram (
    $params: CreateProgramParams!
  ) {
    createProgram(params: $params) {
      id
    }
  }
`;
