import React, { useEffect, useMemo } from "react";
import { Flex } from "@chakra-ui/layout";
import { useTranslation } from "react-i18next";

import {
  ListReportsQuery,
  useListReportsLazyQuery,
} from "generated/graphql";
import { reportCenterFormattedColumns } from "constants/reportCenterTableTitles";
import Table from "components/Table";
import SearchFilter from "components/Filters/SearchFilter";
import StatusFilter from "components/Filters/StatusFilter";
import useFilter, { filters } from "hooks/useFilter";
import { getStartAt, getEndAt } from "utils/dateFormats";
import formatReportsTableData from "utils/formatReportsTableData";
import { AllRecordsEnum, reportCenterStatuses, reportCenterStatusSelectOptions } from "constants/statuses";
import { getReportStatus } from "utils/getStatus";
import DateFilter from "components/Filters/DateFilter";
import TableHeader from "components/Table/TableHeader";
import { useFiscalYear } from "hooks/useFiscalYear";

const ReportCenter: React.FC = () => {
  const tableColumns = reportCenterFormattedColumns;

  const [t] = useTranslation();

  const [search] = useFilter(filters.search);
  const [reportStatus, setReportStatus] = useFilter(filters.reportStatus);
  const [submissionMonth] = useFilter(filters.submissionMonth);
  const [submissionYear] = useFilter(filters.submissionYear);

  const [listReports,
    {
      data: reportsData,
      loading: reportsLoading,
    },
  ] = useListReportsLazyQuery();

  const {
    currentFiscalYearStart,
    currentFiscalYearEnd,
    organizationId,
  } = useFiscalYear();

  const startAt = useMemo(() => getStartAt(
    submissionMonth as string,
    submissionYear as string,
    currentFiscalYearStart,
  ), [
    currentFiscalYearStart,
    submissionMonth,
    submissionYear,
  ]);

  const endAt = useMemo(() => getEndAt(
    submissionMonth as string,
    submissionYear as string,
    currentFiscalYearEnd,
  ), [
    currentFiscalYearEnd,
    submissionMonth,
    submissionYear,
  ]);

  useEffect(() => {
    if (!organizationId) {
      return;
    }

    listReports({
      variables: {
        filters: {
          organizationId,
          search: search as string,
          submitted: getReportStatus(reportStatus as AllRecordsEnum),
          startAt,
          endAt,
        },
      },
    });
  },
  [
    listReports,
    search,
    reportStatus,
    startAt,
    endAt,
    organizationId,
  ]);

  const rawTableData = (reportsData ?? {}) as ListReportsQuery;

  const formattedTableData = formatReportsTableData(rawTableData);

  return (
    <Table
      table={formattedTableData}
      tableColumns={tableColumns}
      isLoading={reportsLoading}
    >
      <TableHeader title={t("tables.titles.report_center")} />

      <Flex
        flexDir="row"
        marginBottom={8}
      >
        <SearchFilter
          width="full"
        />

        <StatusFilter
          marginLeft={4}
          currentStatus={reportStatus as string}
          setStatus={setReportStatus}
          statusOptions={reportCenterStatusSelectOptions}
          tableStatuses={reportCenterStatuses}
        />
      </Flex>

      <DateFilter />
    </Table>
  );
};

export default ReportCenter;
