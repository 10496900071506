import { gql } from "@apollo/client";

export const createFunderRefetchQueries = [
  "ListApplications",
  "GetCurrentUser",
  "ListReports",
];

export default gql`
  mutation CreateFunder (
    $params: CreateFunderParams!
  ) {
    createFunder(params: $params) {
      id
      name
    }
  }
`;
