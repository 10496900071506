import * as R from "remeda";

import { Organizations } from "./types";

export interface SelectOption {
  label: string;
  value: string | number;
  isDisabled?: boolean;
}

/**
 * Parses organizations into select options.
 * @param userOrganizationId The user's organization id.
 * @param organizations The organizations fetched from the query.
 */
const getSelectOptions = (
  userOrganizationId: number | undefined,
  organizations: Organizations | undefined,
): SelectOption[] => R.pipe(
  organizations ?? [],
  R.filter((organization) => organization.id !== userOrganizationId),
  R.sortBy((organization) => organization.id),
  R.map((organization): SelectOption => ({
    label: `${organization.id} - ${organization.name}`,
    value: organization.id,
  })),
);

export default getSelectOptions;
