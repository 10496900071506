import useAdminImpersonationStore from "components/AdminImpersonation/store";

import { BuildRefetchQueriesOptions, RefetchQueries } from "./types";

/**
 * Build the `refetchQueries` object to be used in an `useGetOrganization` call, re-fetching
 * the correct queries according to the admin impersonation store state.
 *
 * @param param0 Options.
 */
const buildRefetchQueries = ({
  refetchImpersonatedOrganizationQueries,
  refetchCurrentOrganizationQueries,
}: BuildRefetchQueriesOptions): RefetchQueries | undefined => {
  const impersonatedOrganizationId = useAdminImpersonationStore.getState()?.organizationId;

  if (impersonatedOrganizationId) {
    return refetchImpersonatedOrganizationQueries;
  }

  return refetchCurrentOrganizationQueries;
};

export default buildRefetchQueries;
