import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { Flex } from "@chakra-ui/layout";

import { useRouteProps } from "contexts/route";
import Header from "components/Header";
import Footer from "components/Footer";
import i18n from "translations/i18n";
import PrintableHeader from "components/PrintableHeader";
import Unauthorized from "views/Unauthorized";
import Loading from "components/Loading";
import { useGetUserAuthorizationQuery } from "generated/graphql";

import { printStyles } from "./styles";

/**
 * App wrapper. Can be used to render Header, Footer, etc.
 * @param param0 options
 */
const AppWrapper: React.FC = ({
  children,
}) => {
  const {
    showHeader,
    showFooter,
    requireAuthentication,
  } = useRouteProps();

  const {
    data,
    loading,
  } = useGetUserAuthorizationQuery({
    fetchPolicy: "cache-and-network",
  });

  const htmlAttributes = {
    lang: i18n.language,
  };

  const isAuthorized = !!(
    !requireAuthentication
    || (
      requireAuthentication
      && data?.currentUser?.authorized
    )
  );

  const content = useMemo(() => {
    if (loading && requireAuthentication) {
      return <Loading />;
    }

    return (
      isAuthorized
        ? children
        : <Unauthorized />
    );
  }, [
    loading,
    children,
    isAuthorized,
    requireAuthentication,
  ]);

  return (
    <Flex
      flexDirection="column"
      minHeight="100vh"
      backgroundColor="background"
      className="app-wrapper"
      css={printStyles}
    >
      <PrintableHeader />

      <Helmet htmlAttributes={htmlAttributes} />

      {
        showHeader && (
          <Header
            showNavItems={isAuthorized}
            userRole={data?.currentUser?.role}
          />
        )
      }

      {content}

      {
        showFooter && (
          <Footer />
        )
      }
    </Flex>
  );
};

export default AppWrapper;
