import { TableTitlesEnum } from "constants/grantFlowTableTitles";
import { TableTypeEnum } from "constants/types";
import { ListApplicationsQuery } from "generated/graphql";
import i18n from "translations/i18n";

import { displayMonthDayYear, formatDate } from "./dateFormats";
import formatCurrency from "./formatCurrency";

export interface ApplicationProps {
  id: string;

  type: TableTypeEnum;

  funderId: string;

  [TableTitlesEnum.ApplicationDeadline]?: string;

  [TableTitlesEnum.Funder]?: string;

  [TableTitlesEnum.LastAward]?: string;

  [TableTitlesEnum.DateSubmitted]?: string;

  [TableTitlesEnum.AmountRequested]?: string;

  [TableTitlesEnum.Program]?: string;

  [TableTitlesEnum.DateReceived]?: string;

  [TableTitlesEnum.AmountAwarded]?: string;

  [TableTitlesEnum.ResponseAverage]?: string;

  [TableTitlesEnum.Status]?: string;
}

/**
 * Receives tableData from the ListApplications query and
 * returns it formatted
 * @param tableData rawTableData
 */
const formatGrantFlowTableData = (
  tableData: ListApplicationsQuery,
): ApplicationProps[] => {
  const applicationsList = tableData.listApplications;

  if (!tableData || !applicationsList) {
    return [];
  }

  const formattedTableData = applicationsList.map(application => (
    {
      id: application?.id?.toString() ?? "-",

      type: TableTypeEnum.Grant,

      funderId: application?.grant?.funder?.id.toString() ?? "-",

      [TableTitlesEnum.ApplicationDeadline]: formatDate(application?.deadline, displayMonthDayYear),

      [TableTitlesEnum.Funder]: application?.grant?.funder?.name ?? "-",

      [TableTitlesEnum.LastAward]: formatDate(
        application?.grant?.funder?.lastAward?.receivementDate,
        displayMonthDayYear,
      ) ?? i18n.t("tables.new"),

      [TableTitlesEnum.DateSubmitted]: formatDate(
        application?.submission?.submissionDate,
        displayMonthDayYear,
      ),

      [TableTitlesEnum.AmountRequested]: formatCurrency(application?.submission?.amountRequested) ?? "-",

      [TableTitlesEnum.Program]: application?.grant?.program?.name ?? "-",

      [TableTitlesEnum.DateReceived]: formatDate(
        application?.award?.receivementDate,
        displayMonthDayYear,
      ),

      [TableTitlesEnum.AmountAwarded]: formatCurrency(application?.award?.amountAwarded) ?? "-",

      [TableTitlesEnum.ResponseAverage]: i18n.t(
        "tables.day",
        {
          count: Math.ceil(application?.grant?.responseAverage),
        },
      ) ?? "-",

      [TableTitlesEnum.Status]: application?.status,
    }
  ));

  return formattedTableData;
};

export default formatGrantFlowTableData;
