import React from "react";
import { Flex } from "@chakra-ui/layout";
import { MenuItem as RainbowMenuItem } from "react-rainbow-components";

import { MenuItemProps } from "../types";

const MenuItem: React.FC<MenuItemProps> = ({
  menuItem,
  handleSelect,
  ...props
}) => (
  <Flex
    key={menuItem.value}
    {...props}
  >
    <RainbowMenuItem
      onClick={handleSelect(menuItem)}
      label={menuItem.label}
    />
  </Flex>
);

export default MenuItem;
