import React from "react";
import { ModalBody, ModalFooter } from "@chakra-ui/modal";
import { Text } from "@chakra-ui/layout";
import { useTranslation, Trans } from "react-i18next";

import Button from "components/Button";

import { PauseNegotiationModalProps } from "./types";

const PauseNegotiationModal: React.FC<PauseNegotiationModalProps> = ({
  componentProps: {
    funderData,
    onPause,
  },
}) => {
  const [t] = useTranslation();

  return (
    <>
      <ModalBody>
        <Text fontSize="sm" textAlign="center">
          <Trans
            i18nKey="modals.pause_negotiation.are_you_sure_that_you_want"
            values={{ funderName: funderData?.getFunder?.name }}
            components={{
              HighLightedText: (
                <Text
                  as="span"
                  fontFamily="heading"
                  color="secondary.100"
                  textTransform="uppercase"
                />
              ),
            }}
          />
        </Text>
      </ModalBody>

      <ModalFooter>
        <Button
          width="full"
          backgroundColor="secondary.200"
          onClick={onPause}
        >
          {t("actions.pause")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default PauseNegotiationModal;
