import { Flex, FlexProps } from "@chakra-ui/layout";
import React from "react";

import LogoIcon from "settings/theme/ChakraTheme/icons/LogoIcon";

import { printableHeaderStyles } from "./styles";

const PrintableHeader: React.FC<FlexProps> = ({
  ...props
}) => (
  <Flex
    display="none"
    css={printableHeaderStyles}
    {...props}
  >
    <LogoIcon boxSize="60px" color="black" />
  </Flex>
);

export default PrintableHeader;
