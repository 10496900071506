import React, { useEffect, useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import { Chart, Dataset } from "react-rainbow-components";
import datalabels from "chartjs-plugin-datalabels";
import { Skeleton } from "@chakra-ui/skeleton";
import { Text } from "@chakra-ui/layout";
import { getYear } from "date-fns";

import useYearPeriodFilter from "hooks/useFilter/useYearPeriodFilter";
import {
  useGetAwardsByYearQuery,
  useGetImpersonatedOrganizationAwardsByYearQuery,
} from "generated/graphql";
import CardContainer from "components/Cards/CardContainer";
import { DashboardItemProps } from "views/Dashboard/types";
import YearFilter from "components/Filters/YearFilter";
import CardHeader from "components/Cards/CardHeader";
import getAwardsByYear, { GetAwardsByYearType } from "utils/getAwardsByYear";
import useFilter, { filters } from "hooks/useFilter";
import useGetOrganization from "hooks/useGetOrganization";

import { datalabelsConfigs } from "./datalabels";

interface ComponentProps {
  awards: GetAwardsByYearType;
}

const ChartComponent: React.FC<ComponentProps> = memo(({ awards }) => {
  const [t] = useTranslation();
  return (
    <Chart
      type="bar"
      plugins={[datalabels]}
      labels={awards.labels}
      style={{ minWidth: 200, maxWidth: 800 }}
      datalabels={datalabelsConfigs}
      disableXAxisGridLines
      disableYAxisGridLines
      maintainAspectRatio={false}
    >
      <Dataset
        values={awards.values}
        backgroundColor="blue"
        title={t("dashboard.get_awards_by_year.awarded")}
      />
    </Chart>
  );
});

const ComparisonByYear: React.FC<DashboardItemProps> = ({
  oldestAwardReceivementDate,
  oldestAwardLoading,
}) => {
  const [t] = useTranslation();
  const { startAt } = useYearPeriodFilter(filters.awardsComparisonYear);
  const [, setFilter] = useFilter(filters.awardsComparisonYear);

  const [organization, {
    loading,
  }] = useGetOrganization(
    useGetAwardsByYearQuery,
    useGetImpersonatedOrganizationAwardsByYearQuery,
    {
      variables: {
        startAt,
      },
    },
  );

  const awards = useMemo(() => getAwardsByYear(organization?.awardsByYear), [organization]);

  useEffect(() => {
    if (!oldestAwardReceivementDate) {
      return;
    }

    setFilter(getYear(oldestAwardReceivementDate));
  }, [
    setFilter,
    oldestAwardReceivementDate,
  ]);

  return (
    <Skeleton
      as={CardContainer}
      isLoaded={!loading}
      height="full"
      display="flex"
      flexDirection="column"
    >
      <CardHeader title={t("dashboard.get_awards_by_year.awards_comparison_by_year")}>
        <Text
          fontFamily="semibold"
          fontSize="xxs"
        >
          {`${t("dashboard.get_awards_by_year.start_year")}:`}
        </Text>

        {
          !oldestAwardLoading && (
            <YearFilter
              startDate={oldestAwardReceivementDate}
              filter={filters.awardsComparisonYear}
            />
          )
        }
      </CardHeader>

      <ChartComponent awards={awards} />
    </Skeleton>
  );
};

export default memo(ComparisonByYear);
