import { PicklistProps } from "react-rainbow-components/components/Picklist";

import { GetOrganizationProgramsQuery } from "generated/graphql";

/**
 * Receives tableData from the ListFunders query and
 * returns it formatted
 * @param tableData rawTableData
 */
const formatFunderList = (
  tableData?: GetOrganizationProgramsQuery["currentUser"]["organization"]["funders"],
): PicklistProps["value"][] => {
  if (!tableData) {
    return [];
  }

  const formattedListData = tableData.map(funder => (
    {
      value: funder?.id ?? undefined,

      label: funder?.name ?? "-",
    }
  ));

  return formattedListData;
};

export default formatFunderList;
