import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/icon";

const ArrowRightUpIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} viewBox="0 0 800 800">
    <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M400 0C179.086 0 0 179.086 0 400C0 620.914 179.086 800 400 800C620.914 800 800 620.914 800 400C800 179.086 620.914 0 400 0ZM242.387 258.583V289.979C242.316 303.485 253.417 314.303 266.923 313.95L433.306 309.919L230.083 513.142C220.679 522.546 220.679 537.678 230.083 547.083L252.71 569.71C262.115 579.115 277.247 579.115 286.651 569.71L489.874 366.488L485.844 532.87C485.561 546.305 496.379 557.407 509.814 557.407H541.21C554.433 557.478 565.181 546.729 565.181 533.436V258.513C565.252 245.29 554.504 234.542 541.21 234.542L266.357 234.613C253.135 234.542 242.387 245.29 242.387 258.583Z" />
  </Icon>
);

export default ArrowRightUpIcon;
