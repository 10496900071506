import FundersList from "views/FundersList";
import ResetPassword from "views/ResetPassword";
import Home from "views/Home";
import Dashboard from "views/Dashboard";
import GrantFlow from "views/GrantFlow";
import ReportCenter from "views/ReportCenter";
import Calendar from "views/Calendar";
import SignUp from "views/SignUp";
import Login from "views/Login";
import ForgotPassword from "views/ForgotPassword";
import { RouteProps } from "contexts/route/types";
import {
  ROOT_PAGE_PATH,
  DASHBOARD_PAGE_PATH,
  GRANT_FLOW_PAGE_PATH,
  REPORT_CENTER_PAGE_PATH,
  CALENDAR_PAGE_PATH,
  LOGIN_PAGE_PATH,
  SIGN_UP_PAGE_PATH,
  FORGOT_PASSWORD_PAGE_PATH,
  RESET_PASSWORD_PAGE_PATH,
  FUNDER_PROFILE_PAGE_PATH,
  FUNDERS_LIST_PAGE_PATH,
  LARGEST_FUNDERS_PAGE_PATH,
} from "routes";
import FunderProfile from "views/FunderProfile";
import LargestFunders from "views/LargestFunders";

const defaultRouteProps = {
  exact: true,
  strict: false,
  requireAuthentication: true,
  showHeader: true,
  showFooter: false,
  componentProps: {},
};

const appRoutes: RouteProps[] = [
  {
    ...defaultRouteProps,
    showHeader: false,
    showFooter: false,
    requireAuthentication: false,
    path: ROOT_PAGE_PATH,
    component: Home,
  },
  {
    ...defaultRouteProps,
    showFooter: false,
    requireAuthentication: true,
    path: DASHBOARD_PAGE_PATH,
    component: Dashboard,
  },
  {
    ...defaultRouteProps,
    requireAuthentication: true,
    path: GRANT_FLOW_PAGE_PATH,
    component: GrantFlow,
    showFooter: false,
  },
  {
    ...defaultRouteProps,
    requireAuthentication: true,
    path: REPORT_CENTER_PAGE_PATH,
    component: ReportCenter,
    showFooter: false,
  },
  {
    ...defaultRouteProps,
    requireAuthentication: true,
    path: FUNDERS_LIST_PAGE_PATH,
    component: FundersList,
    showFooter: false,
  },
  {
    ...defaultRouteProps,
    requireAuthentication: true,
    path: LARGEST_FUNDERS_PAGE_PATH,
    component: LargestFunders,
    showFooter: false,
  },
  {
    ...defaultRouteProps,
    requireAuthentication: true,
    path: CALENDAR_PAGE_PATH,
    component: Calendar,
  },
  {
    ...defaultRouteProps,
    requireAuthentication: true,
    path: FUNDER_PROFILE_PAGE_PATH,
    component: FunderProfile,
    showHeader: true,
    showFooter: false,
  },
  {
    ...defaultRouteProps,
    showHeader: false,
    showFooter: false,
    requireAuthentication: false,
    path: LOGIN_PAGE_PATH,
    component: Login,
  },
  {
    ...defaultRouteProps,
    requireAuthentication: false,
    path: SIGN_UP_PAGE_PATH,
    component: SignUp,
    showHeader: false,
    showFooter: false,
  },
  {
    ...defaultRouteProps,
    requireAuthentication: false,
    path: FORGOT_PASSWORD_PAGE_PATH,
    component: ForgotPassword,
    showHeader: false,
    showFooter: false,
  },
  {
    ...defaultRouteProps,
    requireAuthentication: false,
    path: RESET_PASSWORD_PAGE_PATH,
    component: ResetPassword,
    showHeader: false,
    showFooter: false,
  },
];

/**
 * Public paths that can be accessed without a session.
 */
export const publicPaths = appRoutes
  .filter((route) => !route?.requireAuthentication)
  .map((route) => route.path);

export default appRoutes;
