import React from "react";
import { Stack } from "@chakra-ui/layout";
import { useTranslation } from "react-i18next";
import { Input } from "react-rainbow-components";
import { Controller, useFormContext } from "react-hook-form";

import getErrorMessage from "utils/getErrorMessage";
import MaskedInput from "components/FormComponents/MaskedInput";
import { buildCurrencyMask } from "constants/masks";
import MoneyBagIcon from "settings/theme/ChakraTheme/icons/MoneyBagIcon";
import { CreateProgramSchema } from "settings/yup/schemas/createProgramSchema";

const Program: React.FC = () => {
  const [t] = useTranslation();

  const {
    errors,
    control,
    register,
  } = useFormContext<CreateProgramSchema>();

  return (
    <Stack
      as="form"
      spacing={6}
      width="full"
      marginBottom={8}
    >
      <Controller
        required
        as={Input}
        name="name"
        type="text"
        control={control}
        labelAlignment="left"
        error={getErrorMessage("name", errors)}
        label={t("create_program.steps.program.program_name")}
        placeholder={t("create_program.steps.program.enter_program_name")}
      />

      <MaskedInput
        ref={register}
        errors={errors}
        name="yearlyGoal"
        isRequired
        mask={buildCurrencyMask()}
        rightElement={<MoneyBagIcon />}
        title={t("create_program.steps.program.yearly_goal")}
      />
    </Stack>
  );
};

export default Program;
