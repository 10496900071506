import { useMemo } from "react";
import { startOfYear, endOfYear } from "date-fns";
import * as R from "remeda";

import { formatDate, yearMonthDay } from "utils/dateFormats";

import { UseYearPeriodFilterResult, Filter } from "./types";
import useFilter from ".";

/**
 * Parses and returns the result of a given year period filter to be used in a query.
 * @param filter The filter.
 */
const useYearPeriodFilter = (filter: Filter): UseYearPeriodFilterResult => {
  const [selectedYear] = useFilter(filter);

  const startAt = useMemo(() => R.pipe(
    selectedYear,
    () => new Date(selectedYear as number, 1, 1),
    startOfYear,
    (parsedYear) => formatDate(parsedYear, yearMonthDay),
  ), [
    selectedYear,
  ]);

  const endAt = useMemo(() => R.pipe(
    selectedYear,
    () => new Date(selectedYear as number, 1, 1),
    endOfYear,
    (parsedYear) => formatDate(parsedYear, yearMonthDay),
  ), [
    selectedYear,
  ]);

  const result = useMemo<UseYearPeriodFilterResult>(() => ({
    startAt,
    endAt,
  }), [
    startAt,
    endAt,
  ]);

  return result;
};

export default useYearPeriodFilter;
