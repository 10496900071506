import React, { useMemo, useRef } from "react";
import {
  Flex, Grid, GridItem, VStack,
} from "@chakra-ui/layout";
import { parseISO } from "date-fns";
import { jsPDF } from "jspdf";
import { toCanvas } from "html-to-image";

import ComparisonByYear from "views/Dashboard/Charts/ComparisonByYear";
import {
  useGetOldestAwardDateQuery,
  useGetImpersonatedOrganizationOldestAwardDateQuery,
} from "generated/graphql";
import { formatDate, yearMonthDay } from "utils/dateFormats";
import SummaryCard from "views/Dashboard/SummaryCard";
import elementIds from "views/Dashboard/elementIds";
import useGetOrganization from "hooks/useGetOrganization";

import DashboardHeader from "./Header";
import { containerCss, chartContainerCss } from "./styles";
import FundingsByType from "./Charts/FundingsByType";

const Dashboard: React.FC = () => {
  const [organization, {
    loading: oldestAwardLoading,
  }] = useGetOrganization(
    useGetOldestAwardDateQuery,
    useGetImpersonatedOrganizationOldestAwardDateQuery,
  );

  const refContent = useRef<HTMLElement | undefined>();

  const oldestAward = organization?.oldestAward;

  const oldestAwardReceivementDate = useMemo(() => parseISO(
    oldestAward?.receivementDate ?? formatDate(new Date(), yearMonthDay),
  ), [oldestAward?.receivementDate]);

  const downloadPDF = ():void => {
    if (!refContent.current) return;
    toCanvas(refContent.current)
      .then((canvas: HTMLCanvasElement) => {
        const imageData = canvas.getContext("2d")?.getImageData(0, 0, canvas.width, canvas.height);
        if (!imageData) return;

        // eslint-disable-next-line new-cap
        const doc = new jsPDF({
          orientation: "landscape",
          unit: "px",
          format: [canvas.width, canvas.height],
        });
        doc.addImage(
          imageData,
          0,
          0,
          canvas.width,
          canvas.height,
        );
        doc.save("grant.pdf");
      });
  };

  return (
    <Flex flexDirection="column">
      <DashboardHeader
        oldestAwardReceivementDate={oldestAwardReceivementDate}
        oldestAwardLoading={oldestAwardLoading}
        downloadPDF={downloadPDF}
      />

      <VStack
        css={containerCss}
        spacing={6}
        ref={refContent}
        padding={8}
        bg="#f8f8f8"
      >
        <SummaryCard />

        <Grid
          gap={6}
          width="full"
          templateColumns={{ base: "repeat(12, 1fr)" }}
          css={containerCss}
        >
          <GridItem
            id={elementIds.comparisonByYear}
            colSpan={{ base: 12, lg: 6 }}
            css={chartContainerCss}
          >
            <ComparisonByYear
              oldestAwardReceivementDate={oldestAwardReceivementDate}
              oldestAwardLoading={oldestAwardLoading}
            />
          </GridItem>

          <GridItem
            id={elementIds.fundingByType}
            colSpan={{ base: 12, lg: 6 }}
            css={chartContainerCss}
          >
            <FundingsByType
              oldestAwardReceivementDate={oldestAwardReceivementDate}
              oldestAwardLoading={oldestAwardLoading}
            />
          </GridItem>
        </Grid>
      </VStack>
    </Flex>
  );
};

export default Dashboard;
