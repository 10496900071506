export enum ToastDurationEnum {
  SHORT = 4000,
  LONG = 10000
}

export interface ToastProps {
  id: string;
  type: "success" | "warning" | "info" | "error";
  title: string;
  description?: string;
  hideCloseButton?: boolean;
  toastDuration?: ToastDurationEnum.SHORT | ToastDurationEnum.LONG;
}

export interface ToastContextData {
  addToast(toastProps: Omit<ToastProps, "id">): void;
  removeToast(id: string): void;
}

export interface UseToastDefaultOptions {
  duration: "SHORT";
  hideCloseButton: boolean;
}
