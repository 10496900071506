import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { Input, Button, DatePicker } from "react-rainbow-components";

import getErrorMessage from "utils/getErrorMessage";
import CustomCard from "components/CustomCard";
import { DASHBOARD_PAGE_PATH, LOGIN_PAGE_PATH } from "routes";
import useSession from "hooks/useSession";
import { useToast } from "contexts/toast";
import signUpSchema, { SignUpSchema } from "settings/yup/schemas/signUpSchema";
import { useCreateUnverifiedUserMutation } from "generated/graphql";
import UserNameIcon from "settings/theme/ChakraTheme/icons/UserNameIcon";
import MailIcon from "settings/theme/ChakraTheme/icons/MailIcon";
import LockIcon from "settings/theme/ChakraTheme/icons/LockIcon";
import { formatDate, yearMonthDay } from "utils/dateFormats";

const SignUp: React.FC = () => {
  const [createSession] = useSession();
  const [t] = useTranslation();
  const { addToast } = useToast();

  const {
    errors,
    handleSubmit,
    control,
  } = useForm<SignUpSchema>({
    resolver: yupResolver(signUpSchema),
    mode: "onBlur",
    defaultValues: {
      fiscalYearStart: undefined,
      organizationName: "",
      email: "",
      password: "",
      passwordConfirmation: "",
    },
  });

  const [createUnverifiedUser, { loading }] = useCreateUnverifiedUserMutation();

  const onSubmit = useCallback((values) => {
    createUnverifiedUser({
      variables: {
        password: values.password,
        email: values.email,
        organization: {
          name: values.organizationName,
          fiscalYearStart: formatDate(values.fiscalYearStart, yearMonthDay),
        },
      },
    })
      .then((response) => {
        const token = response?.data?.createUnverifiedUser;

        if (token) {
          addToast({
            title: t("actions.account_created_successfully"),
            type: "success",
          });

          createSession(token, DASHBOARD_PAGE_PATH);
        }
      })
      .catch((error) => {
        addToast({
          title: error?.message,
          type: "error",
        });
      });
  }, [
    t,
    addToast,
    createSession,
    createUnverifiedUser,
  ]);

  return (
    <form className="sign-up-wrapper">
      <CustomCard
        title={t("sign_up.title")}
        shouldAnimate
        shouldShowLogo
        subtitle={
          (
            <span>
              {t("sign_up.already_have_an_account")}

              {" "}

              <Link to={LOGIN_PAGE_PATH}>{t("sign_up.login_here")}</Link>
            </span>
          )
        }
      >
        <Controller
          as={Input}
          control={control}
          name="organizationName"
          type="text"
          required
          icon={<UserNameIcon />}
          label={t("sign_up.organization_name")}
          placeholder={t("sign_up.enter_organization_name")}
          className="sign-up-input-field"
          error={getErrorMessage("organizationName", errors)}
          iconPosition="right"
        />

        <Controller
          as={DatePicker}
          control={control}
          name="fiscalYearStart"
          required
          label={t("sign_up.fiscal_year_start_date")}
          placeholder={t("sign_up.enter_fiscal_year_start_date")}
          className="sign-up-input-field"
          error={getErrorMessage("fiscalYearStart", errors)}
          variant="single"
        />

        <Controller
          as={Input}
          control={control}
          name="email"
          type="email"
          required
          icon={<MailIcon />}
          label={t("sign_up.email_address")}
          placeholder={t("sign_up.enter_your_email_address")}
          className="sign-up-input-field"
          error={getErrorMessage("email", errors)}
          iconPosition="right"
        />

        <Controller
          as={Input}
          control={control}
          name="password"
          type="password"
          required
          icon={<LockIcon />}
          label={t("sign_up.password")}
          placeholder={t("sign_up.enter_your_password")}
          className="sign-up-input-field"
          error={getErrorMessage("password", errors)}
          iconPosition="right"
        />

        <Controller
          as={Input}
          control={control}
          name="passwordConfirmation"
          type="password"
          required
          icon={<LockIcon />}
          label={t("sign_up.password_confirmation")}
          placeholder={t("sign_up.confirm_your_password")}
          className="sign-up-input-field"
          error={getErrorMessage("passwordConfirmation", errors)}
          iconPosition="right"
        />

        <div className="sign-up-actions-container">
          <Button
            label={t("sign_up.create_account")}
            variant="brand"
            type="submit"
            onClick={handleSubmit(onSubmit)}
            isLoading={loading}
          />
        </div>
      </CustomCard>
    </form>
  );
};

export default SignUp;

