import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Decimal` scalar type represents signed double-precision fractional
   * values parsed by the `Decimal` library.  The Decimal appears in a JSON
   * response as a string to preserve precision.
   */
  Decimal: any;
  /**
   * The `Date` scalar type represents a date. The Date appears in a JSON
   * response as an ISO8601 formatted string.
   */
  Date: any;
};

/** A grant */
export type Grant = {
  __typename?: 'Grant';
  applications: Maybe<Array<Maybe<Application>>>;
  frequency: Maybe<GrantFrequencyEnum>;
  funder: Maybe<Funder>;
  id: Maybe<Scalars['Int']>;
  program: Maybe<Program>;
  responseAverage: Maybe<Scalars['Int']>;
};

/** Available parameters to update an award */
export type UpdateAwardParams = {
  amountAwarded?: Maybe<Scalars['Decimal']>;
  id?: Maybe<Scalars['Int']>;
  receivementDate?: Maybe<Scalars['Date']>;
};

/** Available parameters to create an application */
export type CreateApplicationParams = {
  award?: Maybe<CreateAwardParams>;
  deadline: Scalars['Date'];
  deadlineType: DeadlineTypeEnum;
  declined?: Maybe<Scalars['Boolean']>;
  reports?: Maybe<Array<CreateReportParams>>;
  submission?: Maybe<CreateSubmissionParams>;
};


export type RootMutationType = {
  __typename?: 'RootMutationType';
  /** Create a funder */
  createFunder: Maybe<Funder>;
  /** Create a grant */
  createGrant: Maybe<Grant>;
  /** Create a previous award */
  createPreviousAward: Maybe<Award>;
  /** Create a program */
  createProgram: Maybe<Program>;
  /** Creates an unverified user with unconfirmed email */
  createUnverifiedUser: Maybe<Scalars['String']>;
  /** Delete a funder */
  deleteFunder: Maybe<Funder>;
  /** Delete a program */
  deleteProgram: Maybe<Program>;
  /** Logins an user */
  loginUser: Maybe<Scalars['String']>;
  /** Sends an email to reset password */
  requestResetPassword: Maybe<Scalars['String']>;
  /** Resets the user's password */
  resetPassword: Maybe<Scalars['String']>;
  /** Update an application */
  updateApplication: Maybe<Application>;
  /** Update a funder */
  updateFunder: Maybe<Funder>;
  /** Update an organization */
  updateOrganization: Maybe<Organization>;
  /** Update a program */
  updateProgram: Maybe<Program>;
  /** Update a report */
  updateReport: Maybe<Report>;
};


export type RootMutationTypeCreateFunderArgs = {
  params: CreateFunderParams;
};


export type RootMutationTypeCreateGrantArgs = {
  params: CreateGrantParams;
};


export type RootMutationTypeCreatePreviousAwardArgs = {
  amountAwarded: Scalars['Decimal'];
  awardedDate?: Maybe<Scalars['Date']>;
  awardedYear?: Maybe<Scalars['Int']>;
  funderId: Scalars['Int'];
  programId: Scalars['Int'];
  submissionDate?: Maybe<Scalars['Date']>;
};


export type RootMutationTypeCreateProgramArgs = {
  params: CreateProgramParams;
};


export type RootMutationTypeCreateUnverifiedUserArgs = {
  email: Scalars['String'];
  organization: CreateOrganizationParams;
  password: Scalars['String'];
};


export type RootMutationTypeDeleteFunderArgs = {
  id: Scalars['Int'];
};


export type RootMutationTypeDeleteProgramArgs = {
  id: Scalars['Int'];
};


export type RootMutationTypeLoginUserArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type RootMutationTypeRequestResetPasswordArgs = {
  email: Scalars['String'];
};


export type RootMutationTypeResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type RootMutationTypeUpdateApplicationArgs = {
  id: Scalars['Int'];
  params: UpdateApplicationParams;
};


export type RootMutationTypeUpdateFunderArgs = {
  id: Scalars['Int'];
  params: UpdateFunderParams;
};


export type RootMutationTypeUpdateOrganizationArgs = {
  id: Scalars['Int'];
  params: UpdateOrganizationParams;
};


export type RootMutationTypeUpdateProgramArgs = {
  id: Scalars['Int'];
  params: UpdateProgramParams;
};


export type RootMutationTypeUpdateReportArgs = {
  id: Scalars['Int'];
  params: UpdateReportParams;
};

/** Available fields to update a finder */
export type UpdateFunderParams = {
  email?: Maybe<Scalars['String']>;
  howToApply?: Maybe<Scalars['String']>;
  isPaused?: Maybe<Scalars['Boolean']>;
  keyContacts?: Maybe<Scalars['String']>;
  location?: Maybe<UpdateLocationParams>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  type?: Maybe<FunderTypeEnum>;
  website?: Maybe<Scalars['String']>;
};

/** Award by year */
export type AwardByYear = {
  __typename?: 'AwardByYear';
  total: Maybe<Scalars['Decimal']>;
  year: Maybe<Scalars['Int']>;
};

/** The largest funders object */
export type LargestFunders = {
  __typename?: 'LargestFunders';
  overall: Maybe<Array<Maybe<Funder>>>;
  programFunders: Maybe<Array<Maybe<ProgramFunder>>>;
};


/** Available parameters to create an award */
export type CreateAwardParams = {
  amountAwarded: Scalars['Decimal'];
  receivementDate: Scalars['Date'];
};

/** The funder type enum */
export enum FunderTypeEnum {
  /** Corporate */
  Corporate = 'CORPORATE',
  /** Foundation */
  Foundation = 'FOUNDATION',
  /** Government */
  Government = 'GOVERNMENT',
  /** Other */
  Other = 'OTHER'
}

/** The current year goal */
export type CurrentYearGoal = {
  __typename?: 'CurrentYearGoal';
  overall: Maybe<OverallYearGoal>;
  programs: Maybe<Array<Maybe<Program>>>;
};

/** Available parameters to update a report */
export type UpdateReportParams = {
  deadline?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Int']>;
  submissionDate?: Maybe<Scalars['Date']>;
  urgency?: Maybe<ReportUrgencyEnum>;
};

/** Available fields to create a program */
export type CreateProgramParams = {
  name: Scalars['String'];
  organizationId: Scalars['Int'];
  yearlyGoal?: Maybe<Scalars['Decimal']>;
};

/** The funder types count */
export type FunderTypesCount = {
  __typename?: 'FunderTypesCount';
  corporate: Maybe<Scalars['Int']>;
  foundation: Maybe<Scalars['Int']>;
  government: Maybe<Scalars['Int']>;
  other: Maybe<Scalars['Int']>;
};

/** An user */
export type User = {
  __typename?: 'User';
  authorized: Maybe<Scalars['Boolean']>;
  email: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  organization: Maybe<Organization>;
  role: Maybe<UserRoleEnum>;
  verified: Maybe<Scalars['Boolean']>;
};

/** Sorting order */
export enum SortingOrder {
  /** Ascending order */
  Asc = 'ASC',
  /** Descending order */
  Desc = 'DESC'
}

/** Available parameters to create a report */
export type CreateReportParams = {
  deadline: Scalars['Date'];
  submissionDate?: Maybe<Scalars['Date']>;
  urgency: ReportUrgencyEnum;
};

/** Available fields to create a funder */
export type CreateFunderParams = {
  email?: Maybe<Scalars['String']>;
  howToApply?: Maybe<Scalars['String']>;
  isPaused?: Maybe<Scalars['Boolean']>;
  keyContacts?: Maybe<Scalars['String']>;
  location?: Maybe<CreateLocationParams>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  organizationId: Scalars['Int'];
  phone?: Maybe<Scalars['String']>;
  type: FunderTypeEnum;
  website?: Maybe<Scalars['String']>;
};

/** A Program Funder */
export type ProgramFunder = {
  __typename?: 'ProgramFunder';
  funders: Maybe<Array<Maybe<Funder>>>;
  program: Maybe<Program>;
};

/** Available parameters to filter applications */
export type FilterApplicationParams = {
  /** Should not be sent with the 'year' field defined */
  endAt?: Maybe<Scalars['Date']>;
  organizationId: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  /** Should not be sent with the 'year' field defined */
  startAt?: Maybe<Scalars['Date']>;
  status?: Maybe<FilterApplicationStatusEnum>;
  /** It should not be sent with the 'startAt' or 'endAt' fields defined */
  year?: Maybe<Scalars['Int']>;
};

/** A Grants Summary */
export type GrantsSummary = {
  __typename?: 'GrantsSummary';
  totalApplicationAmount: Maybe<Scalars['Decimal']>;
  totalApplicationCount: Maybe<Scalars['Int']>;
  totalAwardedAmount: Maybe<Scalars['Decimal']>;
  totalAwardedGrants: Maybe<Scalars['Int']>;
  totalPendingAmount: Maybe<Scalars['Decimal']>;
  totalPendingGrants: Maybe<Scalars['Int']>;
};

/** Available parameters to update a submission */
export type UpdateSubmissionParams = {
  amountRequested?: Maybe<Scalars['Decimal']>;
  id?: Maybe<Scalars['Int']>;
  submissionDate?: Maybe<Scalars['Date']>;
};

/** A report */
export type Report = {
  __typename?: 'Report';
  deadline: Maybe<Scalars['Date']>;
  grantApplication: Maybe<Application>;
  id: Maybe<Scalars['Int']>;
  submissionDate: Maybe<Scalars['Date']>;
  submitted: Maybe<Scalars['Boolean']>;
  urgency: Maybe<ReportUrgencyEnum>;
};

export type PaginationParams = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<SortingOrder>;
};

/** Possible values for the report urgency */
export enum ReportUrgencyEnum {
  /** Courtesy */
  Courtesy = 'COURTESY',
  /** Required */
  Required = 'REQUIRED'
}

/** An organization */
export type Organization = {
  __typename?: 'Organization';
  awardsByYear: Maybe<Array<Maybe<AwardByYear>>>;
  currentFiscalYear: Maybe<Scalars['Int']>;
  currentYearGoal: Maybe<CurrentYearGoal>;
  fiscalYearStart: Maybe<Scalars['Date']>;
  funderTypesCount: Maybe<FunderTypesCount>;
  funders: Maybe<Array<Maybe<Funder>>>;
  grantsSummary: Maybe<GrantsSummary>;
  id: Maybe<Scalars['Int']>;
  largestFunders: Maybe<LargestFunders>;
  name: Maybe<Scalars['String']>;
  oldestApplication: Maybe<Application>;
  oldestAward: Maybe<Award>;
  programs: Maybe<Array<Maybe<Program>>>;
  user: Maybe<User>;
};


/** An organization */
export type OrganizationAwardsByYearArgs = {
  startAt?: Maybe<Scalars['Date']>;
};


/** An organization */
export type OrganizationFunderTypesCountArgs = {
  endAt?: Maybe<Scalars['Date']>;
  startAt?: Maybe<Scalars['Date']>;
};


/** An organization */
export type OrganizationLargestFundersArgs = {
  endAt?: Maybe<Scalars['Date']>;
  startAt?: Maybe<Scalars['Date']>;
  type?: Maybe<FunderTypeEnum>;
};

/** Available parameters to create a submission */
export type CreateSubmissionParams = {
  amountRequested: Scalars['Decimal'];
  submissionDate: Scalars['Date'];
};

/** Available parameters to create a grant */
export type CreateGrantParams = {
  application: CreateApplicationParams;
  frequency: GrantFrequencyEnum;
  funderId: Scalars['Int'];
  programId: Scalars['Int'];
};

/** The overall year goal */
export type OverallYearGoal = {
  __typename?: 'OverallYearGoal';
  achieved: Maybe<Scalars['Decimal']>;
  achievedRate: Maybe<Scalars['Decimal']>;
  goal: Maybe<Scalars['Decimal']>;
  missing: Maybe<Scalars['Decimal']>;
};

/** A program */
export type Program = {
  __typename?: 'Program';
  achievedAmount: Maybe<Scalars['Decimal']>;
  funders: Maybe<Array<Maybe<Funder>>>;
  grants: Maybe<Array<Maybe<Grant>>>;
  id: Maybe<Scalars['Int']>;
  missingAmount: Maybe<Scalars['Decimal']>;
  name: Maybe<Scalars['String']>;
  organization: Maybe<Organization>;
  yearlyGoal: Maybe<Scalars['Decimal']>;
};

/** Possible values for the grant frequency */
export enum GrantFrequencyEnum {
  /** One Time */
  OneTime = 'ONE_TIME',
  /** Recurring */
  Recurring = 'RECURRING'
}

/** An award */
export type Award = {
  __typename?: 'Award';
  amountAwarded: Maybe<Scalars['Decimal']>;
  grantApplication: Maybe<Application>;
  id: Maybe<Scalars['Int']>;
  isPrecisionByYear: Maybe<Scalars['Boolean']>;
  program: Maybe<Program>;
  receivementDate: Maybe<Scalars['Date']>;
  responseDays: Maybe<Scalars['Int']>;
};

/** Available fields to update a program */
export type UpdateProgramParams = {
  name?: Maybe<Scalars['String']>;
  yearlyGoal?: Maybe<Scalars['Decimal']>;
};

/** An application */
export type Application = {
  __typename?: 'Application';
  award: Maybe<Award>;
  deadline: Maybe<Scalars['Date']>;
  deadlineType: Maybe<DeadlineTypeEnum>;
  declined: Maybe<Scalars['Boolean']>;
  grant: Maybe<Grant>;
  id: Maybe<Scalars['Int']>;
  reports: Maybe<Array<Maybe<Report>>>;
  status: Maybe<ApplicationStatusEnum>;
  submission: Maybe<Submission>;
};

/** The enum filter for the application status */
export enum FilterApplicationStatusEnum {
  /** Awarded */
  Awarded = 'AWARDED',
  /** Declined */
  Declined = 'DECLINED',
  /** Did not apply */
  NotApply = 'NOT_APPLY',
  /** Pending */
  Pending = 'PENDING',
  /** Submitted */
  Submitted = 'SUBMITTED'
}

/** A location */
export type Location = {
  __typename?: 'Location';
  city: Maybe<Scalars['String']>;
  complement: Maybe<Scalars['String']>;
  funder: Maybe<Funder>;
  id: Maybe<Scalars['Int']>;
  state: Maybe<Scalars['String']>;
  streetAddress: Maybe<Scalars['String']>;
  zipcode: Maybe<Scalars['String']>;
};

/** Available fields to update an organization */
export type UpdateOrganizationParams = {
  fiscalYearStart?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
};

/** A submission */
export type Submission = {
  __typename?: 'Submission';
  amountRequested: Maybe<Scalars['Decimal']>;
  grantApplication: Maybe<Application>;
  id: Maybe<Scalars['Int']>;
  submissionDate: Maybe<Scalars['Date']>;
};

/** Available parameters to update an application */
export type UpdateApplicationParams = {
  award?: Maybe<UpdateAwardParams>;
  deadline?: Maybe<Scalars['Date']>;
  deadlineType?: Maybe<DeadlineTypeEnum>;
  declined?: Maybe<Scalars['Boolean']>;
  reports?: Maybe<Array<UpdateReportParams>>;
  submission?: Maybe<UpdateSubmissionParams>;
};

/** The application status enum */
export enum ApplicationStatusEnum {
  /** Awarded */
  Awarded = 'AWARDED',
  /** Declined */
  Declined = 'DECLINED',
  /** Did not apply */
  NotApply = 'NOT_APPLY',
  /** Pending */
  Pending = 'PENDING'
}

/** User roles */
export enum UserRoleEnum {
  /** Admin */
  Admin = 'ADMIN',
  /** User */
  User = 'USER'
}

/** Available parameters to update a location */
export type UpdateLocationParams = {
  city?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

/** Available fields to create an organization */
export type CreateOrganizationParams = {
  fiscalYearStart: Scalars['Date'];
  name: Scalars['String'];
};

/** Available parameters to filter a report */
export type FilterReportParams = {
  endAt?: Maybe<Scalars['Date']>;
  organizationId: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['Date']>;
  submitted?: Maybe<Scalars['Boolean']>;
};

/** Available parameters to create a location */
export type CreateLocationParams = {
  city?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

/** The application deadline type enum */
export enum DeadlineTypeEnum {
  /** Required */
  Required = 'REQUIRED',
  /** Rolling */
  Rolling = 'ROLLING'
}

export type RootQueryType = {
  __typename?: 'RootQueryType';
  /** Gets the user associated with the given token */
  currentUser: Maybe<User>;
  /** Gets an application by ID */
  getApplication: Maybe<Application>;
  /** Gets a funder by ID */
  getFunder: Maybe<Funder>;
  /** Gets an organization by ID */
  getOrganization: Maybe<Organization>;
  /** Gets a program by ID */
  getProgram: Maybe<Program>;
  /** Gets a report by ID */
  getReport: Maybe<Report>;
  /** Gets a user by id */
  getUser: Maybe<User>;
  /** Gets a list of applications */
  listApplications: Maybe<Array<Maybe<Application>>>;
  /** Gets a list of all funders */
  listFunders: Maybe<Array<Maybe<Funder>>>;
  /** Gets a list of all organizations */
  listOrganizations: Maybe<Array<Maybe<Organization>>>;
  /** Gets a list of all programs */
  listPrograms: Maybe<Array<Maybe<Program>>>;
  /** List all reports with filters */
  listReports: Maybe<Array<Maybe<Report>>>;
  /** Gets a list of all users */
  listUsers: Maybe<Array<Maybe<User>>>;
  /** Gets the application version */
  version: Maybe<Scalars['String']>;
};


export type RootQueryTypeGetApplicationArgs = {
  id: Scalars['Int'];
};


export type RootQueryTypeGetFunderArgs = {
  id: Scalars['Int'];
};


export type RootQueryTypeGetOrganizationArgs = {
  id: Scalars['Int'];
};


export type RootQueryTypeGetProgramArgs = {
  id: Scalars['Int'];
};


export type RootQueryTypeGetReportArgs = {
  id: Scalars['Int'];
};


export type RootQueryTypeGetUserArgs = {
  id: Scalars['Int'];
};


export type RootQueryTypeListApplicationsArgs = {
  filters: FilterApplicationParams;
  pagination?: Maybe<PaginationParams>;
};


export type RootQueryTypeListReportsArgs = {
  filters: FilterReportParams;
  pagination?: Maybe<PaginationParams>;
};

/** A funder */
export type Funder = {
  __typename?: 'Funder';
  awards: Maybe<Array<Maybe<Award>>>;
  email: Maybe<Scalars['String']>;
  grants: Maybe<Array<Maybe<Grant>>>;
  howToApply: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  isPaused: Maybe<Scalars['Boolean']>;
  keyContacts: Maybe<Scalars['String']>;
  lastAward: Maybe<Award>;
  location: Maybe<Location>;
  name: Maybe<Scalars['String']>;
  notes: Maybe<Scalars['String']>;
  organization: Maybe<Organization>;
  phone: Maybe<Scalars['String']>;
  totalAwardAmount: Maybe<Scalars['Decimal']>;
  type: Maybe<FunderTypeEnum>;
  website: Maybe<Scalars['String']>;
};


/** A funder */
export type FunderTotalAwardAmountArgs = {
  endAt?: Maybe<Scalars['Date']>;
  startAt?: Maybe<Scalars['Date']>;
};

export type GetCurrentUserRoleQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserRoleQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'role'>
  )> }
);

export type ListAdminImpersonatorOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAdminImpersonatorOrganizationsQuery = (
  { __typename?: 'RootQueryType' }
  & { listOrganizations: Maybe<Array<Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  )>>> }
);

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { organization: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'fiscalYearStart' | 'id'>
      & { funders: Maybe<Array<Maybe<(
        { __typename?: 'Funder' }
        & Pick<Funder, 'id' | 'name'>
      )>>>, programs: Maybe<Array<Maybe<(
        { __typename?: 'Program' }
        & Pick<Program, 'id' | 'name' | 'achievedAmount' | 'missingAmount' | 'yearlyGoal'>
      )>>>, currentYearGoal: Maybe<(
        { __typename?: 'CurrentYearGoal' }
        & { overall: Maybe<(
          { __typename?: 'OverallYearGoal' }
          & Pick<OverallYearGoal, 'achieved' | 'achievedRate' | 'goal' | 'missing'>
        )> }
      )> }
    )> }
  )> }
);

export type CreateFunderMutationVariables = Exact<{
  params: CreateFunderParams;
}>;


export type CreateFunderMutation = (
  { __typename?: 'RootMutationType' }
  & { createFunder: Maybe<(
    { __typename?: 'Funder' }
    & Pick<Funder, 'id' | 'name'>
  )> }
);

export type CreateGrantMutationVariables = Exact<{
  params: CreateGrantParams;
}>;


export type CreateGrantMutation = (
  { __typename?: 'RootMutationType' }
  & { createGrant: Maybe<(
    { __typename?: 'Grant' }
    & Pick<Grant, 'id'>
  )> }
);

export type CreatePreviousAwardMutationVariables = Exact<{
  amountAwarded: Scalars['Decimal'];
  awardedDate?: Maybe<Scalars['Date']>;
  awardedYear?: Maybe<Scalars['Int']>;
  funderId: Scalars['Int'];
  programId: Scalars['Int'];
  submissionDate?: Maybe<Scalars['Date']>;
}>;


export type CreatePreviousAwardMutation = (
  { __typename?: 'RootMutationType' }
  & { createPreviousAward: Maybe<(
    { __typename?: 'Award' }
    & Pick<Award, 'id'>
  )> }
);

export type CreateProgramMutationVariables = Exact<{
  params: CreateProgramParams;
}>;


export type CreateProgramMutation = (
  { __typename?: 'RootMutationType' }
  & { createProgram: Maybe<(
    { __typename?: 'Program' }
    & Pick<Program, 'id'>
  )> }
);

export type CreateUnverifiedUserMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  organization: CreateOrganizationParams;
}>;


export type CreateUnverifiedUserMutation = (
  { __typename?: 'RootMutationType' }
  & Pick<RootMutationType, 'createUnverifiedUser'>
);

export type DeleteFunderMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteFunderMutation = (
  { __typename?: 'RootMutationType' }
  & { deleteFunder: Maybe<(
    { __typename?: 'Funder' }
    & Pick<Funder, 'id'>
  )> }
);

export type DeleteProgramMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteProgramMutation = (
  { __typename?: 'RootMutationType' }
  & { deleteProgram: Maybe<(
    { __typename?: 'Program' }
    & Pick<Program, 'id'>
  )> }
);

export type LoginUserMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginUserMutation = (
  { __typename?: 'RootMutationType' }
  & Pick<RootMutationType, 'loginUser'>
);

export type RequestResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RequestResetPasswordMutation = (
  { __typename?: 'RootMutationType' }
  & Pick<RootMutationType, 'requestResetPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'RootMutationType' }
  & Pick<RootMutationType, 'resetPassword'>
);

export type UpdateApplicationMutationVariables = Exact<{
  id: Scalars['Int'];
  params: UpdateApplicationParams;
}>;


export type UpdateApplicationMutation = (
  { __typename?: 'RootMutationType' }
  & { updateApplication: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id'>
  )> }
);

export type UpdateFunderMutationVariables = Exact<{
  id: Scalars['Int'];
  params: UpdateFunderParams;
}>;


export type UpdateFunderMutation = (
  { __typename?: 'RootMutationType' }
  & { updateFunder: Maybe<(
    { __typename?: 'Funder' }
    & Pick<Funder, 'id' | 'isPaused'>
  )> }
);

export type UpdateOrganizationMutationVariables = Exact<{
  id: Scalars['Int'];
  params: UpdateOrganizationParams;
}>;


export type UpdateOrganizationMutation = (
  { __typename?: 'RootMutationType' }
  & { updateOrganization: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id'>
  )> }
);

export type UpdateReportMutationVariables = Exact<{
  id: Scalars['Int'];
  params: UpdateReportParams;
}>;


export type UpdateReportMutation = (
  { __typename?: 'RootMutationType' }
  & { updateReport: Maybe<(
    { __typename?: 'Report' }
    & Pick<Report, 'id'>
  )> }
);

export type UpdateYearlyGoalMutationVariables = Exact<{
  id: Scalars['Int'];
  params: UpdateProgramParams;
}>;


export type UpdateYearlyGoalMutation = (
  { __typename?: 'RootMutationType' }
  & { updateProgram: Maybe<(
    { __typename?: 'Program' }
    & Pick<Program, 'id'>
  )> }
);

export type GetApplicationQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetApplicationQuery = (
  { __typename?: 'RootQueryType' }
  & { getApplication: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id' | 'status' | 'deadline' | 'declined'>
    & { award: Maybe<(
      { __typename?: 'Award' }
      & Pick<Award, 'id' | 'amountAwarded' | 'receivementDate'>
    )>, submission: Maybe<(
      { __typename?: 'Submission' }
      & Pick<Submission, 'id' | 'submissionDate' | 'amountRequested'>
    )>, reports: Maybe<Array<Maybe<(
      { __typename?: 'Report' }
      & Pick<Report, 'id' | 'urgency' | 'deadline'>
    )>>> }
  )> }
);

export type GetAwardsByYearQueryVariables = Exact<{
  startAt?: Maybe<Scalars['Date']>;
}>;


export type GetAwardsByYearQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { organization: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { awardsByYear: Maybe<Array<Maybe<(
        { __typename?: 'AwardByYear' }
        & Pick<AwardByYear, 'total' | 'year'>
      )>>> }
    )> }
  )> }
);

export type GetCurrentYearGoalQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentYearGoalQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { organization: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { currentYearGoal: Maybe<(
        { __typename?: 'CurrentYearGoal' }
        & { overall: Maybe<(
          { __typename?: 'OverallYearGoal' }
          & Pick<OverallYearGoal, 'achieved' | 'achievedRate' | 'goal' | 'missing'>
        )>, programs: Maybe<Array<Maybe<(
          { __typename?: 'Program' }
          & Pick<Program, 'id' | 'name' | 'achievedAmount' | 'missingAmount' | 'yearlyGoal'>
        )>>> }
      )> }
    )> }
  )> }
);

export type GetFunderQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetFunderQuery = (
  { __typename?: 'RootQueryType' }
  & { getFunder: Maybe<(
    { __typename?: 'Funder' }
    & Pick<Funder, 'id' | 'name' | 'keyContacts' | 'email' | 'phone' | 'type' | 'website' | 'howToApply' | 'notes' | 'isPaused'>
    & { location: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'city' | 'complement' | 'state' | 'streetAddress' | 'zipcode'>
    )>, awards: Maybe<Array<Maybe<(
      { __typename?: 'Award' }
      & Pick<Award, 'id' | 'isPrecisionByYear' | 'receivementDate' | 'amountAwarded' | 'responseDays'>
      & { program: Maybe<(
        { __typename?: 'Program' }
        & Pick<Program, 'id' | 'name'>
      )>, grantApplication: Maybe<(
        { __typename?: 'Application' }
        & Pick<Application, 'id'>
        & { submission: Maybe<(
          { __typename?: 'Submission' }
          & Pick<Submission, 'id' | 'submissionDate'>
        )> }
      )> }
    )>>> }
  )> }
);

export type GetFundersByTypeQueryVariables = Exact<{
  startAt?: Maybe<Scalars['Date']>;
  endAt?: Maybe<Scalars['Date']>;
}>;


export type GetFundersByTypeQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { organization: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { funderTypesCount: Maybe<(
        { __typename?: 'FunderTypesCount' }
        & Pick<FunderTypesCount, 'corporate' | 'foundation' | 'government' | 'other'>
      )> }
    )> }
  )> }
);

export type GetGrantsSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGrantsSummaryQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { organization: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { grantsSummary: Maybe<(
        { __typename?: 'GrantsSummary' }
        & Pick<GrantsSummary, 'totalApplicationAmount' | 'totalApplicationCount' | 'totalAwardedAmount' | 'totalAwardedGrants' | 'totalPendingAmount' | 'totalPendingGrants'>
      )> }
    )> }
  )> }
);

export type GetImpersonatedOrganizationAwardsByYearQueryVariables = Exact<{
  startAt?: Maybe<Scalars['Date']>;
  organizationId: Scalars['Int'];
}>;


export type GetImpersonatedOrganizationAwardsByYearQuery = (
  { __typename?: 'RootQueryType' }
  & { getOrganization: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { awardsByYear: Maybe<Array<Maybe<(
      { __typename?: 'AwardByYear' }
      & Pick<AwardByYear, 'total' | 'year'>
    )>>> }
  )> }
);

export type GetImpersonatedOrganizationCurrentYearGoalQueryVariables = Exact<{
  organizationId: Scalars['Int'];
}>;


export type GetImpersonatedOrganizationCurrentYearGoalQuery = (
  { __typename?: 'RootQueryType' }
  & { getOrganization: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { currentYearGoal: Maybe<(
      { __typename?: 'CurrentYearGoal' }
      & { overall: Maybe<(
        { __typename?: 'OverallYearGoal' }
        & Pick<OverallYearGoal, 'achieved' | 'achievedRate' | 'goal' | 'missing'>
      )>, programs: Maybe<Array<Maybe<(
        { __typename?: 'Program' }
        & Pick<Program, 'id' | 'name' | 'achievedAmount' | 'missingAmount' | 'yearlyGoal'>
      )>>> }
    )> }
  )> }
);

export type GetImpersonatedOrganizationFiscalYearQueryVariables = Exact<{
  organizationId: Scalars['Int'];
}>;


export type GetImpersonatedOrganizationFiscalYearQuery = (
  { __typename?: 'RootQueryType' }
  & { getOrganization: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'currentFiscalYear' | 'fiscalYearStart'>
  )> }
);

export type GetImpersonatedOrganizationFundersByTypeQueryVariables = Exact<{
  startAt?: Maybe<Scalars['Date']>;
  endAt?: Maybe<Scalars['Date']>;
  organizationId: Scalars['Int'];
}>;


export type GetImpersonatedOrganizationFundersByTypeQuery = (
  { __typename?: 'RootQueryType' }
  & { getOrganization: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { funderTypesCount: Maybe<(
      { __typename?: 'FunderTypesCount' }
      & Pick<FunderTypesCount, 'corporate' | 'foundation' | 'government' | 'other'>
    )> }
  )> }
);

export type GetImpersonatedOrganizationGrantsSummaryQueryVariables = Exact<{
  organizationId: Scalars['Int'];
}>;


export type GetImpersonatedOrganizationGrantsSummaryQuery = (
  { __typename?: 'RootQueryType' }
  & { getOrganization: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { grantsSummary: Maybe<(
      { __typename?: 'GrantsSummary' }
      & Pick<GrantsSummary, 'totalApplicationAmount' | 'totalApplicationCount' | 'totalAwardedAmount' | 'totalAwardedGrants' | 'totalPendingAmount' | 'totalPendingGrants'>
    )> }
  )> }
);

export type GetImpersonatedOrganizationLargestFundersQueryVariables = Exact<{
  type?: Maybe<FunderTypeEnum>;
  startAt?: Maybe<Scalars['Date']>;
  endAt?: Maybe<Scalars['Date']>;
  organizationId: Scalars['Int'];
}>;


export type GetImpersonatedOrganizationLargestFundersQuery = (
  { __typename?: 'RootQueryType' }
  & { getOrganization: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { programs: Maybe<Array<Maybe<(
      { __typename?: 'Program' }
      & Pick<Program, 'id' | 'name'>
    )>>>, largestFunders: Maybe<(
      { __typename?: 'LargestFunders' }
      & { overall: Maybe<Array<Maybe<(
        { __typename?: 'Funder' }
        & Pick<Funder, 'id' | 'name' | 'totalAwardAmount'>
      )>>>, programFunders: Maybe<Array<Maybe<(
        { __typename?: 'ProgramFunder' }
        & { funders: Maybe<Array<Maybe<(
          { __typename?: 'Funder' }
          & Pick<Funder, 'id' | 'name' | 'totalAwardAmount'>
        )>>>, program: Maybe<(
          { __typename?: 'Program' }
          & Pick<Program, 'id' | 'name'>
        )> }
      )>>> }
    )> }
  )> }
);

export type GetImpersonatedOrganizationOldestAwardDateQueryVariables = Exact<{
  organizationId: Scalars['Int'];
}>;


export type GetImpersonatedOrganizationOldestAwardDateQuery = (
  { __typename?: 'RootQueryType' }
  & { getOrganization: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { oldestAward: Maybe<(
      { __typename?: 'Award' }
      & Pick<Award, 'id' | 'receivementDate'>
    )> }
  )> }
);

export type GetImpersonatedOrganizationProgramsQueryVariables = Exact<{
  organizationId: Scalars['Int'];
}>;


export type GetImpersonatedOrganizationProgramsQuery = (
  { __typename?: 'RootQueryType' }
  & { getOrganization: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { funders: Maybe<Array<Maybe<(
      { __typename?: 'Funder' }
      & Pick<Funder, 'id' | 'name'>
    )>>>, programs: Maybe<Array<Maybe<(
      { __typename?: 'Program' }
      & Pick<Program, 'id' | 'name'>
    )>>> }
  )> }
);

export type GetImpersonatedOrganizationQueryVariables = Exact<{
  organizationId: Scalars['Int'];
}>;


export type GetImpersonatedOrganizationQuery = (
  { __typename?: 'RootQueryType' }
  & { getOrganization: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  )> }
);

export type GetLargestFundersQueryVariables = Exact<{
  type?: Maybe<FunderTypeEnum>;
  startAt?: Maybe<Scalars['Date']>;
  endAt?: Maybe<Scalars['Date']>;
}>;


export type GetLargestFundersQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { organization: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { programs: Maybe<Array<Maybe<(
        { __typename?: 'Program' }
        & Pick<Program, 'id' | 'name'>
      )>>>, largestFunders: Maybe<(
        { __typename?: 'LargestFunders' }
        & { overall: Maybe<Array<Maybe<(
          { __typename?: 'Funder' }
          & Pick<Funder, 'id' | 'name' | 'totalAwardAmount'>
        )>>>, programFunders: Maybe<Array<Maybe<(
          { __typename?: 'ProgramFunder' }
          & { funders: Maybe<Array<Maybe<(
            { __typename?: 'Funder' }
            & Pick<Funder, 'id' | 'name' | 'totalAwardAmount'>
          )>>>, program: Maybe<(
            { __typename?: 'Program' }
            & Pick<Program, 'id' | 'name'>
          )> }
        )>>> }
      )> }
    )> }
  )> }
);

export type GetOldestAwardDateQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOldestAwardDateQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { organization: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { oldestAward: Maybe<(
        { __typename?: 'Award' }
        & Pick<Award, 'id' | 'receivementDate'>
      )> }
    )> }
  )> }
);

export type GetOrganizationFiscalYearQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationFiscalYearQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { organization: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'currentFiscalYear' | 'fiscalYearStart'>
    )> }
  )> }
);

export type GetOrganizationProgramsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationProgramsQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { organization: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { funders: Maybe<Array<Maybe<(
        { __typename?: 'Funder' }
        & Pick<Funder, 'id' | 'name'>
      )>>>, programs: Maybe<Array<Maybe<(
        { __typename?: 'Program' }
        & Pick<Program, 'id' | 'name'>
      )>>> }
    )> }
  )> }
);

export type GetOrganizationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { organization: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
    )> }
  )> }
);

export type GetReportQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetReportQuery = (
  { __typename?: 'RootQueryType' }
  & { getReport: Maybe<(
    { __typename?: 'Report' }
    & Pick<Report, 'id' | 'deadline' | 'submitted' | 'submissionDate' | 'urgency'>
    & { grantApplication: Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { grant: Maybe<(
        { __typename?: 'Grant' }
        & Pick<Grant, 'id'>
        & { funder: Maybe<(
          { __typename?: 'Funder' }
          & Pick<Funder, 'id' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type GetUserAuthorizationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserAuthorizationQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'authorized' | 'role'>
  )> }
);

export type ListApplicationsQueryVariables = Exact<{
  filters: FilterApplicationParams;
  pagination?: Maybe<PaginationParams>;
}>;


export type ListApplicationsQuery = (
  { __typename?: 'RootQueryType' }
  & { listApplications: Maybe<Array<Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id' | 'status' | 'deadline' | 'declined'>
    & { award: Maybe<(
      { __typename?: 'Award' }
      & Pick<Award, 'id' | 'amountAwarded' | 'receivementDate'>
    )>, grant: Maybe<(
      { __typename?: 'Grant' }
      & Pick<Grant, 'id' | 'responseAverage'>
      & { program: Maybe<(
        { __typename?: 'Program' }
        & Pick<Program, 'id' | 'name'>
      )>, funder: Maybe<(
        { __typename?: 'Funder' }
        & Pick<Funder, 'id' | 'name'>
        & { lastAward: Maybe<(
          { __typename?: 'Award' }
          & Pick<Award, 'id' | 'receivementDate'>
        )> }
      )> }
    )>, submission: Maybe<(
      { __typename?: 'Submission' }
      & Pick<Submission, 'id' | 'submissionDate' | 'amountRequested'>
    )> }
  )>>> }
);

export type ListFundersQueryVariables = Exact<{ [key: string]: never; }>;


export type ListFundersQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { organization: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { funders: Maybe<Array<Maybe<(
        { __typename?: 'Funder' }
        & Pick<Funder, 'id' | 'name' | 'email' | 'type' | 'website' | 'totalAwardAmount'>
      )>>> }
    )> }
  )> }
);

export type ListImpersonatedOrganizationFundersQueryVariables = Exact<{
  organizationId: Scalars['Int'];
}>;


export type ListImpersonatedOrganizationFundersQuery = (
  { __typename?: 'RootQueryType' }
  & { getOrganization: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { funders: Maybe<Array<Maybe<(
      { __typename?: 'Funder' }
      & Pick<Funder, 'id' | 'name' | 'email' | 'type' | 'website' | 'totalAwardAmount'>
    )>>> }
  )> }
);

export type ListReportsQueryVariables = Exact<{
  filters: FilterReportParams;
  pagination?: Maybe<PaginationParams>;
}>;


export type ListReportsQuery = (
  { __typename?: 'RootQueryType' }
  & { listReports: Maybe<Array<Maybe<(
    { __typename?: 'Report' }
    & Pick<Report, 'id' | 'deadline' | 'submissionDate' | 'submitted' | 'urgency'>
    & { grantApplication: Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { award: Maybe<(
        { __typename?: 'Award' }
        & Pick<Award, 'id' | 'amountAwarded'>
      )>, grant: Maybe<(
        { __typename?: 'Grant' }
        & Pick<Grant, 'id'>
        & { funder: Maybe<(
          { __typename?: 'Funder' }
          & Pick<Funder, 'id' | 'name'>
        )>, program: Maybe<(
          { __typename?: 'Program' }
          & Pick<Program, 'id' | 'name'>
        )> }
      )> }
    )> }
  )>>> }
);

export type GetBaseCurrentOrganizationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBaseCurrentOrganizationQuery = (
  { __typename?: 'RootQueryType' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { organization: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
    )> }
  )> }
);

export type GetBaseImpersonatedOrganizationQueryVariables = Exact<{
  organizationId: Scalars['Int'];
}>;


export type GetBaseImpersonatedOrganizationQuery = (
  { __typename?: 'RootQueryType' }
  & { getOrganization: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  )> }
);


export const GetCurrentUserRoleDocument = gql`
    query GetCurrentUserRole {
  currentUser {
    id
    role
  }
}
    `;

/**
 * __useGetCurrentUserRoleQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserRoleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserRoleQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserRoleQuery, GetCurrentUserRoleQueryVariables>) {
        return Apollo.useQuery<GetCurrentUserRoleQuery, GetCurrentUserRoleQueryVariables>(GetCurrentUserRoleDocument, baseOptions);
      }
export function useGetCurrentUserRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserRoleQuery, GetCurrentUserRoleQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentUserRoleQuery, GetCurrentUserRoleQueryVariables>(GetCurrentUserRoleDocument, baseOptions);
        }
export type GetCurrentUserRoleQueryHookResult = ReturnType<typeof useGetCurrentUserRoleQuery>;
export type GetCurrentUserRoleLazyQueryHookResult = ReturnType<typeof useGetCurrentUserRoleLazyQuery>;
export type GetCurrentUserRoleQueryResult = Apollo.QueryResult<GetCurrentUserRoleQuery, GetCurrentUserRoleQueryVariables>;
export const ListAdminImpersonatorOrganizationsDocument = gql`
    query ListAdminImpersonatorOrganizations {
  listOrganizations {
    id
    name
  }
}
    `;

/**
 * __useListAdminImpersonatorOrganizationsQuery__
 *
 * To run a query within a React component, call `useListAdminImpersonatorOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminImpersonatorOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminImpersonatorOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAdminImpersonatorOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<ListAdminImpersonatorOrganizationsQuery, ListAdminImpersonatorOrganizationsQueryVariables>) {
        return Apollo.useQuery<ListAdminImpersonatorOrganizationsQuery, ListAdminImpersonatorOrganizationsQueryVariables>(ListAdminImpersonatorOrganizationsDocument, baseOptions);
      }
export function useListAdminImpersonatorOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAdminImpersonatorOrganizationsQuery, ListAdminImpersonatorOrganizationsQueryVariables>) {
          return Apollo.useLazyQuery<ListAdminImpersonatorOrganizationsQuery, ListAdminImpersonatorOrganizationsQueryVariables>(ListAdminImpersonatorOrganizationsDocument, baseOptions);
        }
export type ListAdminImpersonatorOrganizationsQueryHookResult = ReturnType<typeof useListAdminImpersonatorOrganizationsQuery>;
export type ListAdminImpersonatorOrganizationsLazyQueryHookResult = ReturnType<typeof useListAdminImpersonatorOrganizationsLazyQuery>;
export type ListAdminImpersonatorOrganizationsQueryResult = Apollo.QueryResult<ListAdminImpersonatorOrganizationsQuery, ListAdminImpersonatorOrganizationsQueryVariables>;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  currentUser {
    id
    organization {
      fiscalYearStart
      id
      funders {
        id
        name
      }
      programs {
        id
        name
        achievedAmount
        missingAmount
        yearlyGoal
      }
      currentYearGoal {
        overall {
          achieved
          achievedRate
          goal
          missing
        }
      }
    }
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const CreateFunderDocument = gql`
    mutation CreateFunder($params: CreateFunderParams!) {
  createFunder(params: $params) {
    id
    name
  }
}
    `;
export type CreateFunderMutationFn = Apollo.MutationFunction<CreateFunderMutation, CreateFunderMutationVariables>;

/**
 * __useCreateFunderMutation__
 *
 * To run a mutation, you first call `useCreateFunderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFunderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFunderMutation, { data, loading, error }] = useCreateFunderMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateFunderMutation(baseOptions?: Apollo.MutationHookOptions<CreateFunderMutation, CreateFunderMutationVariables>) {
        return Apollo.useMutation<CreateFunderMutation, CreateFunderMutationVariables>(CreateFunderDocument, baseOptions);
      }
export type CreateFunderMutationHookResult = ReturnType<typeof useCreateFunderMutation>;
export type CreateFunderMutationResult = Apollo.MutationResult<CreateFunderMutation>;
export type CreateFunderMutationOptions = Apollo.BaseMutationOptions<CreateFunderMutation, CreateFunderMutationVariables>;
export const CreateGrantDocument = gql`
    mutation CreateGrant($params: CreateGrantParams!) {
  createGrant(params: $params) {
    id
  }
}
    `;
export type CreateGrantMutationFn = Apollo.MutationFunction<CreateGrantMutation, CreateGrantMutationVariables>;

/**
 * __useCreateGrantMutation__
 *
 * To run a mutation, you first call `useCreateGrantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGrantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGrantMutation, { data, loading, error }] = useCreateGrantMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateGrantMutation(baseOptions?: Apollo.MutationHookOptions<CreateGrantMutation, CreateGrantMutationVariables>) {
        return Apollo.useMutation<CreateGrantMutation, CreateGrantMutationVariables>(CreateGrantDocument, baseOptions);
      }
export type CreateGrantMutationHookResult = ReturnType<typeof useCreateGrantMutation>;
export type CreateGrantMutationResult = Apollo.MutationResult<CreateGrantMutation>;
export type CreateGrantMutationOptions = Apollo.BaseMutationOptions<CreateGrantMutation, CreateGrantMutationVariables>;
export const CreatePreviousAwardDocument = gql`
    mutation CreatePreviousAward($amountAwarded: Decimal!, $awardedDate: Date, $awardedYear: Int, $funderId: Int!, $programId: Int!, $submissionDate: Date) {
  createPreviousAward(amountAwarded: $amountAwarded, awardedDate: $awardedDate, awardedYear: $awardedYear, funderId: $funderId, programId: $programId, submissionDate: $submissionDate) {
    id
  }
}
    `;
export type CreatePreviousAwardMutationFn = Apollo.MutationFunction<CreatePreviousAwardMutation, CreatePreviousAwardMutationVariables>;

/**
 * __useCreatePreviousAwardMutation__
 *
 * To run a mutation, you first call `useCreatePreviousAwardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePreviousAwardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPreviousAwardMutation, { data, loading, error }] = useCreatePreviousAwardMutation({
 *   variables: {
 *      amountAwarded: // value for 'amountAwarded'
 *      awardedDate: // value for 'awardedDate'
 *      awardedYear: // value for 'awardedYear'
 *      funderId: // value for 'funderId'
 *      programId: // value for 'programId'
 *      submissionDate: // value for 'submissionDate'
 *   },
 * });
 */
export function useCreatePreviousAwardMutation(baseOptions?: Apollo.MutationHookOptions<CreatePreviousAwardMutation, CreatePreviousAwardMutationVariables>) {
        return Apollo.useMutation<CreatePreviousAwardMutation, CreatePreviousAwardMutationVariables>(CreatePreviousAwardDocument, baseOptions);
      }
export type CreatePreviousAwardMutationHookResult = ReturnType<typeof useCreatePreviousAwardMutation>;
export type CreatePreviousAwardMutationResult = Apollo.MutationResult<CreatePreviousAwardMutation>;
export type CreatePreviousAwardMutationOptions = Apollo.BaseMutationOptions<CreatePreviousAwardMutation, CreatePreviousAwardMutationVariables>;
export const CreateProgramDocument = gql`
    mutation CreateProgram($params: CreateProgramParams!) {
  createProgram(params: $params) {
    id
  }
}
    `;
export type CreateProgramMutationFn = Apollo.MutationFunction<CreateProgramMutation, CreateProgramMutationVariables>;

/**
 * __useCreateProgramMutation__
 *
 * To run a mutation, you first call `useCreateProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramMutation, { data, loading, error }] = useCreateProgramMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateProgramMutation(baseOptions?: Apollo.MutationHookOptions<CreateProgramMutation, CreateProgramMutationVariables>) {
        return Apollo.useMutation<CreateProgramMutation, CreateProgramMutationVariables>(CreateProgramDocument, baseOptions);
      }
export type CreateProgramMutationHookResult = ReturnType<typeof useCreateProgramMutation>;
export type CreateProgramMutationResult = Apollo.MutationResult<CreateProgramMutation>;
export type CreateProgramMutationOptions = Apollo.BaseMutationOptions<CreateProgramMutation, CreateProgramMutationVariables>;
export const CreateUnverifiedUserDocument = gql`
    mutation CreateUnverifiedUser($email: String!, $password: String!, $organization: CreateOrganizationParams!) {
  createUnverifiedUser(email: $email, password: $password, organization: $organization)
}
    `;
export type CreateUnverifiedUserMutationFn = Apollo.MutationFunction<CreateUnverifiedUserMutation, CreateUnverifiedUserMutationVariables>;

/**
 * __useCreateUnverifiedUserMutation__
 *
 * To run a mutation, you first call `useCreateUnverifiedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUnverifiedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUnverifiedUserMutation, { data, loading, error }] = useCreateUnverifiedUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      organization: // value for 'organization'
 *   },
 * });
 */
export function useCreateUnverifiedUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUnverifiedUserMutation, CreateUnverifiedUserMutationVariables>) {
        return Apollo.useMutation<CreateUnverifiedUserMutation, CreateUnverifiedUserMutationVariables>(CreateUnverifiedUserDocument, baseOptions);
      }
export type CreateUnverifiedUserMutationHookResult = ReturnType<typeof useCreateUnverifiedUserMutation>;
export type CreateUnverifiedUserMutationResult = Apollo.MutationResult<CreateUnverifiedUserMutation>;
export type CreateUnverifiedUserMutationOptions = Apollo.BaseMutationOptions<CreateUnverifiedUserMutation, CreateUnverifiedUserMutationVariables>;
export const DeleteFunderDocument = gql`
    mutation DeleteFunder($id: Int!) {
  deleteFunder(id: $id) {
    id
  }
}
    `;
export type DeleteFunderMutationFn = Apollo.MutationFunction<DeleteFunderMutation, DeleteFunderMutationVariables>;

/**
 * __useDeleteFunderMutation__
 *
 * To run a mutation, you first call `useDeleteFunderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFunderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFunderMutation, { data, loading, error }] = useDeleteFunderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFunderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFunderMutation, DeleteFunderMutationVariables>) {
        return Apollo.useMutation<DeleteFunderMutation, DeleteFunderMutationVariables>(DeleteFunderDocument, baseOptions);
      }
export type DeleteFunderMutationHookResult = ReturnType<typeof useDeleteFunderMutation>;
export type DeleteFunderMutationResult = Apollo.MutationResult<DeleteFunderMutation>;
export type DeleteFunderMutationOptions = Apollo.BaseMutationOptions<DeleteFunderMutation, DeleteFunderMutationVariables>;
export const DeleteProgramDocument = gql`
    mutation DeleteProgram($id: Int!) {
  deleteProgram(id: $id) {
    id
  }
}
    `;
export type DeleteProgramMutationFn = Apollo.MutationFunction<DeleteProgramMutation, DeleteProgramMutationVariables>;

/**
 * __useDeleteProgramMutation__
 *
 * To run a mutation, you first call `useDeleteProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProgramMutation, { data, loading, error }] = useDeleteProgramMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProgramMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProgramMutation, DeleteProgramMutationVariables>) {
        return Apollo.useMutation<DeleteProgramMutation, DeleteProgramMutationVariables>(DeleteProgramDocument, baseOptions);
      }
export type DeleteProgramMutationHookResult = ReturnType<typeof useDeleteProgramMutation>;
export type DeleteProgramMutationResult = Apollo.MutationResult<DeleteProgramMutation>;
export type DeleteProgramMutationOptions = Apollo.BaseMutationOptions<DeleteProgramMutation, DeleteProgramMutationVariables>;
export const LoginUserDocument = gql`
    mutation LoginUser($email: String!, $password: String!) {
  loginUser(email: $email, password: $password)
}
    `;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
        return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, baseOptions);
      }
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const RequestResetPasswordDocument = gql`
    mutation RequestResetPassword($email: String!) {
  requestResetPassword(email: $email)
}
    `;
export type RequestResetPasswordMutationFn = Apollo.MutationFunction<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;

/**
 * __useRequestResetPasswordMutation__
 *
 * To run a mutation, you first call `useRequestResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResetPasswordMutation, { data, loading, error }] = useRequestResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>) {
        return Apollo.useMutation<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>(RequestResetPasswordDocument, baseOptions);
      }
export type RequestResetPasswordMutationHookResult = ReturnType<typeof useRequestResetPasswordMutation>;
export type RequestResetPasswordMutationResult = Apollo.MutationResult<RequestResetPasswordMutation>;
export type RequestResetPasswordMutationOptions = Apollo.BaseMutationOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($password: String!, $token: String!) {
  resetPassword(password: $password, token: $token)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const UpdateApplicationDocument = gql`
    mutation UpdateApplication($id: Int!, $params: UpdateApplicationParams!) {
  updateApplication(id: $id, params: $params) {
    id
  }
}
    `;
export type UpdateApplicationMutationFn = Apollo.MutationFunction<UpdateApplicationMutation, UpdateApplicationMutationVariables>;

/**
 * __useUpdateApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationMutation, { data, loading, error }] = useUpdateApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateApplicationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApplicationMutation, UpdateApplicationMutationVariables>) {
        return Apollo.useMutation<UpdateApplicationMutation, UpdateApplicationMutationVariables>(UpdateApplicationDocument, baseOptions);
      }
export type UpdateApplicationMutationHookResult = ReturnType<typeof useUpdateApplicationMutation>;
export type UpdateApplicationMutationResult = Apollo.MutationResult<UpdateApplicationMutation>;
export type UpdateApplicationMutationOptions = Apollo.BaseMutationOptions<UpdateApplicationMutation, UpdateApplicationMutationVariables>;
export const UpdateFunderDocument = gql`
    mutation UpdateFunder($id: Int!, $params: UpdateFunderParams!) {
  updateFunder(id: $id, params: $params) {
    id
    isPaused
  }
}
    `;
export type UpdateFunderMutationFn = Apollo.MutationFunction<UpdateFunderMutation, UpdateFunderMutationVariables>;

/**
 * __useUpdateFunderMutation__
 *
 * To run a mutation, you first call `useUpdateFunderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFunderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFunderMutation, { data, loading, error }] = useUpdateFunderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateFunderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFunderMutation, UpdateFunderMutationVariables>) {
        return Apollo.useMutation<UpdateFunderMutation, UpdateFunderMutationVariables>(UpdateFunderDocument, baseOptions);
      }
export type UpdateFunderMutationHookResult = ReturnType<typeof useUpdateFunderMutation>;
export type UpdateFunderMutationResult = Apollo.MutationResult<UpdateFunderMutation>;
export type UpdateFunderMutationOptions = Apollo.BaseMutationOptions<UpdateFunderMutation, UpdateFunderMutationVariables>;
export const UpdateOrganizationDocument = gql`
    mutation UpdateOrganization($id: Int!, $params: UpdateOrganizationParams!) {
  updateOrganization(id: $id, params: $params) {
    id
  }
}
    `;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>) {
        return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, baseOptions);
      }
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const UpdateReportDocument = gql`
    mutation UpdateReport($id: Int!, $params: UpdateReportParams!) {
  updateReport(id: $id, params: $params) {
    id
  }
}
    `;
export type UpdateReportMutationFn = Apollo.MutationFunction<UpdateReportMutation, UpdateReportMutationVariables>;

/**
 * __useUpdateReportMutation__
 *
 * To run a mutation, you first call `useUpdateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportMutation, { data, loading, error }] = useUpdateReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateReportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReportMutation, UpdateReportMutationVariables>) {
        return Apollo.useMutation<UpdateReportMutation, UpdateReportMutationVariables>(UpdateReportDocument, baseOptions);
      }
export type UpdateReportMutationHookResult = ReturnType<typeof useUpdateReportMutation>;
export type UpdateReportMutationResult = Apollo.MutationResult<UpdateReportMutation>;
export type UpdateReportMutationOptions = Apollo.BaseMutationOptions<UpdateReportMutation, UpdateReportMutationVariables>;
export const UpdateYearlyGoalDocument = gql`
    mutation UpdateYearlyGoal($id: Int!, $params: UpdateProgramParams!) {
  updateProgram(id: $id, params: $params) {
    id
  }
}
    `;
export type UpdateYearlyGoalMutationFn = Apollo.MutationFunction<UpdateYearlyGoalMutation, UpdateYearlyGoalMutationVariables>;

/**
 * __useUpdateYearlyGoalMutation__
 *
 * To run a mutation, you first call `useUpdateYearlyGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateYearlyGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateYearlyGoalMutation, { data, loading, error }] = useUpdateYearlyGoalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateYearlyGoalMutation(baseOptions?: Apollo.MutationHookOptions<UpdateYearlyGoalMutation, UpdateYearlyGoalMutationVariables>) {
        return Apollo.useMutation<UpdateYearlyGoalMutation, UpdateYearlyGoalMutationVariables>(UpdateYearlyGoalDocument, baseOptions);
      }
export type UpdateYearlyGoalMutationHookResult = ReturnType<typeof useUpdateYearlyGoalMutation>;
export type UpdateYearlyGoalMutationResult = Apollo.MutationResult<UpdateYearlyGoalMutation>;
export type UpdateYearlyGoalMutationOptions = Apollo.BaseMutationOptions<UpdateYearlyGoalMutation, UpdateYearlyGoalMutationVariables>;
export const GetApplicationDocument = gql`
    query GetApplication($id: Int!) {
  getApplication(id: $id) {
    id
    award {
      id
      amountAwarded
      receivementDate
    }
    status
    deadline
    declined
    submission {
      id
      submissionDate
      amountRequested
    }
    reports {
      id
      urgency
      deadline
    }
  }
}
    `;

/**
 * __useGetApplicationQuery__
 *
 * To run a query within a React component, call `useGetApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApplicationQuery(baseOptions?: Apollo.QueryHookOptions<GetApplicationQuery, GetApplicationQueryVariables>) {
        return Apollo.useQuery<GetApplicationQuery, GetApplicationQueryVariables>(GetApplicationDocument, baseOptions);
      }
export function useGetApplicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationQuery, GetApplicationQueryVariables>) {
          return Apollo.useLazyQuery<GetApplicationQuery, GetApplicationQueryVariables>(GetApplicationDocument, baseOptions);
        }
export type GetApplicationQueryHookResult = ReturnType<typeof useGetApplicationQuery>;
export type GetApplicationLazyQueryHookResult = ReturnType<typeof useGetApplicationLazyQuery>;
export type GetApplicationQueryResult = Apollo.QueryResult<GetApplicationQuery, GetApplicationQueryVariables>;
export const GetAwardsByYearDocument = gql`
    query GetAwardsByYear($startAt: Date) {
  currentUser {
    id
    organization {
      id
      awardsByYear(startAt: $startAt) {
        total
        year
      }
    }
  }
}
    `;

/**
 * __useGetAwardsByYearQuery__
 *
 * To run a query within a React component, call `useGetAwardsByYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAwardsByYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAwardsByYearQuery({
 *   variables: {
 *      startAt: // value for 'startAt'
 *   },
 * });
 */
export function useGetAwardsByYearQuery(baseOptions?: Apollo.QueryHookOptions<GetAwardsByYearQuery, GetAwardsByYearQueryVariables>) {
        return Apollo.useQuery<GetAwardsByYearQuery, GetAwardsByYearQueryVariables>(GetAwardsByYearDocument, baseOptions);
      }
export function useGetAwardsByYearLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAwardsByYearQuery, GetAwardsByYearQueryVariables>) {
          return Apollo.useLazyQuery<GetAwardsByYearQuery, GetAwardsByYearQueryVariables>(GetAwardsByYearDocument, baseOptions);
        }
export type GetAwardsByYearQueryHookResult = ReturnType<typeof useGetAwardsByYearQuery>;
export type GetAwardsByYearLazyQueryHookResult = ReturnType<typeof useGetAwardsByYearLazyQuery>;
export type GetAwardsByYearQueryResult = Apollo.QueryResult<GetAwardsByYearQuery, GetAwardsByYearQueryVariables>;
export const GetCurrentYearGoalDocument = gql`
    query GetCurrentYearGoal {
  currentUser {
    id
    organization {
      id
      currentYearGoal {
        overall {
          achieved
          achievedRate
          goal
          missing
        }
        programs {
          id
          name
          achievedAmount
          missingAmount
          yearlyGoal
        }
      }
    }
  }
}
    `;

/**
 * __useGetCurrentYearGoalQuery__
 *
 * To run a query within a React component, call `useGetCurrentYearGoalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentYearGoalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentYearGoalQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentYearGoalQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentYearGoalQuery, GetCurrentYearGoalQueryVariables>) {
        return Apollo.useQuery<GetCurrentYearGoalQuery, GetCurrentYearGoalQueryVariables>(GetCurrentYearGoalDocument, baseOptions);
      }
export function useGetCurrentYearGoalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentYearGoalQuery, GetCurrentYearGoalQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentYearGoalQuery, GetCurrentYearGoalQueryVariables>(GetCurrentYearGoalDocument, baseOptions);
        }
export type GetCurrentYearGoalQueryHookResult = ReturnType<typeof useGetCurrentYearGoalQuery>;
export type GetCurrentYearGoalLazyQueryHookResult = ReturnType<typeof useGetCurrentYearGoalLazyQuery>;
export type GetCurrentYearGoalQueryResult = Apollo.QueryResult<GetCurrentYearGoalQuery, GetCurrentYearGoalQueryVariables>;
export const GetFunderDocument = gql`
    query GetFunder($id: Int!) {
  getFunder(id: $id) {
    id
    name
    keyContacts
    email
    phone
    type
    website
    howToApply
    notes
    isPaused
    location {
      id
      city
      complement
      state
      streetAddress
      zipcode
    }
    awards {
      id
      isPrecisionByYear
      receivementDate
      amountAwarded
      program {
        id
        name
      }
      grantApplication {
        id
        submission {
          id
          submissionDate
        }
      }
      responseDays
    }
  }
}
    `;

/**
 * __useGetFunderQuery__
 *
 * To run a query within a React component, call `useGetFunderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFunderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFunderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFunderQuery(baseOptions?: Apollo.QueryHookOptions<GetFunderQuery, GetFunderQueryVariables>) {
        return Apollo.useQuery<GetFunderQuery, GetFunderQueryVariables>(GetFunderDocument, baseOptions);
      }
export function useGetFunderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFunderQuery, GetFunderQueryVariables>) {
          return Apollo.useLazyQuery<GetFunderQuery, GetFunderQueryVariables>(GetFunderDocument, baseOptions);
        }
export type GetFunderQueryHookResult = ReturnType<typeof useGetFunderQuery>;
export type GetFunderLazyQueryHookResult = ReturnType<typeof useGetFunderLazyQuery>;
export type GetFunderQueryResult = Apollo.QueryResult<GetFunderQuery, GetFunderQueryVariables>;
export const GetFundersByTypeDocument = gql`
    query GetFundersByType($startAt: Date, $endAt: Date) {
  currentUser {
    id
    organization {
      id
      funderTypesCount(startAt: $startAt, endAt: $endAt) {
        corporate
        foundation
        government
        other
      }
    }
  }
}
    `;

/**
 * __useGetFundersByTypeQuery__
 *
 * To run a query within a React component, call `useGetFundersByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFundersByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFundersByTypeQuery({
 *   variables: {
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *   },
 * });
 */
export function useGetFundersByTypeQuery(baseOptions?: Apollo.QueryHookOptions<GetFundersByTypeQuery, GetFundersByTypeQueryVariables>) {
        return Apollo.useQuery<GetFundersByTypeQuery, GetFundersByTypeQueryVariables>(GetFundersByTypeDocument, baseOptions);
      }
export function useGetFundersByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFundersByTypeQuery, GetFundersByTypeQueryVariables>) {
          return Apollo.useLazyQuery<GetFundersByTypeQuery, GetFundersByTypeQueryVariables>(GetFundersByTypeDocument, baseOptions);
        }
export type GetFundersByTypeQueryHookResult = ReturnType<typeof useGetFundersByTypeQuery>;
export type GetFundersByTypeLazyQueryHookResult = ReturnType<typeof useGetFundersByTypeLazyQuery>;
export type GetFundersByTypeQueryResult = Apollo.QueryResult<GetFundersByTypeQuery, GetFundersByTypeQueryVariables>;
export const GetGrantsSummaryDocument = gql`
    query GetGrantsSummary {
  currentUser {
    id
    organization {
      id
      grantsSummary {
        totalApplicationAmount
        totalApplicationCount
        totalAwardedAmount
        totalAwardedGrants
        totalPendingAmount
        totalPendingGrants
      }
    }
  }
}
    `;

/**
 * __useGetGrantsSummaryQuery__
 *
 * To run a query within a React component, call `useGetGrantsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGrantsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGrantsSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGrantsSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GetGrantsSummaryQuery, GetGrantsSummaryQueryVariables>) {
        return Apollo.useQuery<GetGrantsSummaryQuery, GetGrantsSummaryQueryVariables>(GetGrantsSummaryDocument, baseOptions);
      }
export function useGetGrantsSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGrantsSummaryQuery, GetGrantsSummaryQueryVariables>) {
          return Apollo.useLazyQuery<GetGrantsSummaryQuery, GetGrantsSummaryQueryVariables>(GetGrantsSummaryDocument, baseOptions);
        }
export type GetGrantsSummaryQueryHookResult = ReturnType<typeof useGetGrantsSummaryQuery>;
export type GetGrantsSummaryLazyQueryHookResult = ReturnType<typeof useGetGrantsSummaryLazyQuery>;
export type GetGrantsSummaryQueryResult = Apollo.QueryResult<GetGrantsSummaryQuery, GetGrantsSummaryQueryVariables>;
export const GetImpersonatedOrganizationAwardsByYearDocument = gql`
    query GetImpersonatedOrganizationAwardsByYear($startAt: Date, $organizationId: Int!) {
  getOrganization(id: $organizationId) {
    id
    name
    awardsByYear(startAt: $startAt) {
      total
      year
    }
  }
}
    `;

/**
 * __useGetImpersonatedOrganizationAwardsByYearQuery__
 *
 * To run a query within a React component, call `useGetImpersonatedOrganizationAwardsByYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpersonatedOrganizationAwardsByYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpersonatedOrganizationAwardsByYearQuery({
 *   variables: {
 *      startAt: // value for 'startAt'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetImpersonatedOrganizationAwardsByYearQuery(baseOptions?: Apollo.QueryHookOptions<GetImpersonatedOrganizationAwardsByYearQuery, GetImpersonatedOrganizationAwardsByYearQueryVariables>) {
        return Apollo.useQuery<GetImpersonatedOrganizationAwardsByYearQuery, GetImpersonatedOrganizationAwardsByYearQueryVariables>(GetImpersonatedOrganizationAwardsByYearDocument, baseOptions);
      }
export function useGetImpersonatedOrganizationAwardsByYearLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetImpersonatedOrganizationAwardsByYearQuery, GetImpersonatedOrganizationAwardsByYearQueryVariables>) {
          return Apollo.useLazyQuery<GetImpersonatedOrganizationAwardsByYearQuery, GetImpersonatedOrganizationAwardsByYearQueryVariables>(GetImpersonatedOrganizationAwardsByYearDocument, baseOptions);
        }
export type GetImpersonatedOrganizationAwardsByYearQueryHookResult = ReturnType<typeof useGetImpersonatedOrganizationAwardsByYearQuery>;
export type GetImpersonatedOrganizationAwardsByYearLazyQueryHookResult = ReturnType<typeof useGetImpersonatedOrganizationAwardsByYearLazyQuery>;
export type GetImpersonatedOrganizationAwardsByYearQueryResult = Apollo.QueryResult<GetImpersonatedOrganizationAwardsByYearQuery, GetImpersonatedOrganizationAwardsByYearQueryVariables>;
export const GetImpersonatedOrganizationCurrentYearGoalDocument = gql`
    query GetImpersonatedOrganizationCurrentYearGoal($organizationId: Int!) {
  getOrganization(id: $organizationId) {
    id
    name
    currentYearGoal {
      overall {
        achieved
        achievedRate
        goal
        missing
      }
      programs {
        id
        name
        achievedAmount
        missingAmount
        yearlyGoal
      }
    }
  }
}
    `;

/**
 * __useGetImpersonatedOrganizationCurrentYearGoalQuery__
 *
 * To run a query within a React component, call `useGetImpersonatedOrganizationCurrentYearGoalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpersonatedOrganizationCurrentYearGoalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpersonatedOrganizationCurrentYearGoalQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetImpersonatedOrganizationCurrentYearGoalQuery(baseOptions?: Apollo.QueryHookOptions<GetImpersonatedOrganizationCurrentYearGoalQuery, GetImpersonatedOrganizationCurrentYearGoalQueryVariables>) {
        return Apollo.useQuery<GetImpersonatedOrganizationCurrentYearGoalQuery, GetImpersonatedOrganizationCurrentYearGoalQueryVariables>(GetImpersonatedOrganizationCurrentYearGoalDocument, baseOptions);
      }
export function useGetImpersonatedOrganizationCurrentYearGoalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetImpersonatedOrganizationCurrentYearGoalQuery, GetImpersonatedOrganizationCurrentYearGoalQueryVariables>) {
          return Apollo.useLazyQuery<GetImpersonatedOrganizationCurrentYearGoalQuery, GetImpersonatedOrganizationCurrentYearGoalQueryVariables>(GetImpersonatedOrganizationCurrentYearGoalDocument, baseOptions);
        }
export type GetImpersonatedOrganizationCurrentYearGoalQueryHookResult = ReturnType<typeof useGetImpersonatedOrganizationCurrentYearGoalQuery>;
export type GetImpersonatedOrganizationCurrentYearGoalLazyQueryHookResult = ReturnType<typeof useGetImpersonatedOrganizationCurrentYearGoalLazyQuery>;
export type GetImpersonatedOrganizationCurrentYearGoalQueryResult = Apollo.QueryResult<GetImpersonatedOrganizationCurrentYearGoalQuery, GetImpersonatedOrganizationCurrentYearGoalQueryVariables>;
export const GetImpersonatedOrganizationFiscalYearDocument = gql`
    query GetImpersonatedOrganizationFiscalYear($organizationId: Int!) {
  getOrganization(id: $organizationId) {
    id
    name
    currentFiscalYear
    fiscalYearStart
  }
}
    `;

/**
 * __useGetImpersonatedOrganizationFiscalYearQuery__
 *
 * To run a query within a React component, call `useGetImpersonatedOrganizationFiscalYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpersonatedOrganizationFiscalYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpersonatedOrganizationFiscalYearQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetImpersonatedOrganizationFiscalYearQuery(baseOptions?: Apollo.QueryHookOptions<GetImpersonatedOrganizationFiscalYearQuery, GetImpersonatedOrganizationFiscalYearQueryVariables>) {
        return Apollo.useQuery<GetImpersonatedOrganizationFiscalYearQuery, GetImpersonatedOrganizationFiscalYearQueryVariables>(GetImpersonatedOrganizationFiscalYearDocument, baseOptions);
      }
export function useGetImpersonatedOrganizationFiscalYearLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetImpersonatedOrganizationFiscalYearQuery, GetImpersonatedOrganizationFiscalYearQueryVariables>) {
          return Apollo.useLazyQuery<GetImpersonatedOrganizationFiscalYearQuery, GetImpersonatedOrganizationFiscalYearQueryVariables>(GetImpersonatedOrganizationFiscalYearDocument, baseOptions);
        }
export type GetImpersonatedOrganizationFiscalYearQueryHookResult = ReturnType<typeof useGetImpersonatedOrganizationFiscalYearQuery>;
export type GetImpersonatedOrganizationFiscalYearLazyQueryHookResult = ReturnType<typeof useGetImpersonatedOrganizationFiscalYearLazyQuery>;
export type GetImpersonatedOrganizationFiscalYearQueryResult = Apollo.QueryResult<GetImpersonatedOrganizationFiscalYearQuery, GetImpersonatedOrganizationFiscalYearQueryVariables>;
export const GetImpersonatedOrganizationFundersByTypeDocument = gql`
    query GetImpersonatedOrganizationFundersByType($startAt: Date, $endAt: Date, $organizationId: Int!) {
  getOrganization(id: $organizationId) {
    id
    name
    funderTypesCount(startAt: $startAt, endAt: $endAt) {
      corporate
      foundation
      government
      other
    }
  }
}
    `;

/**
 * __useGetImpersonatedOrganizationFundersByTypeQuery__
 *
 * To run a query within a React component, call `useGetImpersonatedOrganizationFundersByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpersonatedOrganizationFundersByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpersonatedOrganizationFundersByTypeQuery({
 *   variables: {
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetImpersonatedOrganizationFundersByTypeQuery(baseOptions?: Apollo.QueryHookOptions<GetImpersonatedOrganizationFundersByTypeQuery, GetImpersonatedOrganizationFundersByTypeQueryVariables>) {
        return Apollo.useQuery<GetImpersonatedOrganizationFundersByTypeQuery, GetImpersonatedOrganizationFundersByTypeQueryVariables>(GetImpersonatedOrganizationFundersByTypeDocument, baseOptions);
      }
export function useGetImpersonatedOrganizationFundersByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetImpersonatedOrganizationFundersByTypeQuery, GetImpersonatedOrganizationFundersByTypeQueryVariables>) {
          return Apollo.useLazyQuery<GetImpersonatedOrganizationFundersByTypeQuery, GetImpersonatedOrganizationFundersByTypeQueryVariables>(GetImpersonatedOrganizationFundersByTypeDocument, baseOptions);
        }
export type GetImpersonatedOrganizationFundersByTypeQueryHookResult = ReturnType<typeof useGetImpersonatedOrganizationFundersByTypeQuery>;
export type GetImpersonatedOrganizationFundersByTypeLazyQueryHookResult = ReturnType<typeof useGetImpersonatedOrganizationFundersByTypeLazyQuery>;
export type GetImpersonatedOrganizationFundersByTypeQueryResult = Apollo.QueryResult<GetImpersonatedOrganizationFundersByTypeQuery, GetImpersonatedOrganizationFundersByTypeQueryVariables>;
export const GetImpersonatedOrganizationGrantsSummaryDocument = gql`
    query GetImpersonatedOrganizationGrantsSummary($organizationId: Int!) {
  getOrganization(id: $organizationId) {
    id
    name
    grantsSummary {
      totalApplicationAmount
      totalApplicationCount
      totalAwardedAmount
      totalAwardedGrants
      totalPendingAmount
      totalPendingGrants
    }
  }
}
    `;

/**
 * __useGetImpersonatedOrganizationGrantsSummaryQuery__
 *
 * To run a query within a React component, call `useGetImpersonatedOrganizationGrantsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpersonatedOrganizationGrantsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpersonatedOrganizationGrantsSummaryQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetImpersonatedOrganizationGrantsSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GetImpersonatedOrganizationGrantsSummaryQuery, GetImpersonatedOrganizationGrantsSummaryQueryVariables>) {
        return Apollo.useQuery<GetImpersonatedOrganizationGrantsSummaryQuery, GetImpersonatedOrganizationGrantsSummaryQueryVariables>(GetImpersonatedOrganizationGrantsSummaryDocument, baseOptions);
      }
export function useGetImpersonatedOrganizationGrantsSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetImpersonatedOrganizationGrantsSummaryQuery, GetImpersonatedOrganizationGrantsSummaryQueryVariables>) {
          return Apollo.useLazyQuery<GetImpersonatedOrganizationGrantsSummaryQuery, GetImpersonatedOrganizationGrantsSummaryQueryVariables>(GetImpersonatedOrganizationGrantsSummaryDocument, baseOptions);
        }
export type GetImpersonatedOrganizationGrantsSummaryQueryHookResult = ReturnType<typeof useGetImpersonatedOrganizationGrantsSummaryQuery>;
export type GetImpersonatedOrganizationGrantsSummaryLazyQueryHookResult = ReturnType<typeof useGetImpersonatedOrganizationGrantsSummaryLazyQuery>;
export type GetImpersonatedOrganizationGrantsSummaryQueryResult = Apollo.QueryResult<GetImpersonatedOrganizationGrantsSummaryQuery, GetImpersonatedOrganizationGrantsSummaryQueryVariables>;
export const GetImpersonatedOrganizationLargestFundersDocument = gql`
    query GetImpersonatedOrganizationLargestFunders($type: FunderTypeEnum, $startAt: Date, $endAt: Date, $organizationId: Int!) {
  getOrganization(id: $organizationId) {
    id
    name
    programs {
      id
      name
    }
    largestFunders(startAt: $startAt, endAt: $endAt, type: $type) {
      overall {
        id
        name
        totalAwardAmount(startAt: $startAt, endAt: $endAt)
      }
      programFunders {
        funders {
          id
          name
          totalAwardAmount(startAt: $startAt, endAt: $endAt)
        }
        program {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetImpersonatedOrganizationLargestFundersQuery__
 *
 * To run a query within a React component, call `useGetImpersonatedOrganizationLargestFundersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpersonatedOrganizationLargestFundersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpersonatedOrganizationLargestFundersQuery({
 *   variables: {
 *      type: // value for 'type'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetImpersonatedOrganizationLargestFundersQuery(baseOptions?: Apollo.QueryHookOptions<GetImpersonatedOrganizationLargestFundersQuery, GetImpersonatedOrganizationLargestFundersQueryVariables>) {
        return Apollo.useQuery<GetImpersonatedOrganizationLargestFundersQuery, GetImpersonatedOrganizationLargestFundersQueryVariables>(GetImpersonatedOrganizationLargestFundersDocument, baseOptions);
      }
export function useGetImpersonatedOrganizationLargestFundersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetImpersonatedOrganizationLargestFundersQuery, GetImpersonatedOrganizationLargestFundersQueryVariables>) {
          return Apollo.useLazyQuery<GetImpersonatedOrganizationLargestFundersQuery, GetImpersonatedOrganizationLargestFundersQueryVariables>(GetImpersonatedOrganizationLargestFundersDocument, baseOptions);
        }
export type GetImpersonatedOrganizationLargestFundersQueryHookResult = ReturnType<typeof useGetImpersonatedOrganizationLargestFundersQuery>;
export type GetImpersonatedOrganizationLargestFundersLazyQueryHookResult = ReturnType<typeof useGetImpersonatedOrganizationLargestFundersLazyQuery>;
export type GetImpersonatedOrganizationLargestFundersQueryResult = Apollo.QueryResult<GetImpersonatedOrganizationLargestFundersQuery, GetImpersonatedOrganizationLargestFundersQueryVariables>;
export const GetImpersonatedOrganizationOldestAwardDateDocument = gql`
    query GetImpersonatedOrganizationOldestAwardDate($organizationId: Int!) {
  getOrganization(id: $organizationId) {
    id
    name
    oldestAward {
      id
      receivementDate
    }
  }
}
    `;

/**
 * __useGetImpersonatedOrganizationOldestAwardDateQuery__
 *
 * To run a query within a React component, call `useGetImpersonatedOrganizationOldestAwardDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpersonatedOrganizationOldestAwardDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpersonatedOrganizationOldestAwardDateQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetImpersonatedOrganizationOldestAwardDateQuery(baseOptions?: Apollo.QueryHookOptions<GetImpersonatedOrganizationOldestAwardDateQuery, GetImpersonatedOrganizationOldestAwardDateQueryVariables>) {
        return Apollo.useQuery<GetImpersonatedOrganizationOldestAwardDateQuery, GetImpersonatedOrganizationOldestAwardDateQueryVariables>(GetImpersonatedOrganizationOldestAwardDateDocument, baseOptions);
      }
export function useGetImpersonatedOrganizationOldestAwardDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetImpersonatedOrganizationOldestAwardDateQuery, GetImpersonatedOrganizationOldestAwardDateQueryVariables>) {
          return Apollo.useLazyQuery<GetImpersonatedOrganizationOldestAwardDateQuery, GetImpersonatedOrganizationOldestAwardDateQueryVariables>(GetImpersonatedOrganizationOldestAwardDateDocument, baseOptions);
        }
export type GetImpersonatedOrganizationOldestAwardDateQueryHookResult = ReturnType<typeof useGetImpersonatedOrganizationOldestAwardDateQuery>;
export type GetImpersonatedOrganizationOldestAwardDateLazyQueryHookResult = ReturnType<typeof useGetImpersonatedOrganizationOldestAwardDateLazyQuery>;
export type GetImpersonatedOrganizationOldestAwardDateQueryResult = Apollo.QueryResult<GetImpersonatedOrganizationOldestAwardDateQuery, GetImpersonatedOrganizationOldestAwardDateQueryVariables>;
export const GetImpersonatedOrganizationProgramsDocument = gql`
    query GetImpersonatedOrganizationPrograms($organizationId: Int!) {
  getOrganization(id: $organizationId) {
    id
    name
    funders {
      id
      name
    }
    programs {
      id
      name
    }
  }
}
    `;

/**
 * __useGetImpersonatedOrganizationProgramsQuery__
 *
 * To run a query within a React component, call `useGetImpersonatedOrganizationProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpersonatedOrganizationProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpersonatedOrganizationProgramsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetImpersonatedOrganizationProgramsQuery(baseOptions?: Apollo.QueryHookOptions<GetImpersonatedOrganizationProgramsQuery, GetImpersonatedOrganizationProgramsQueryVariables>) {
        return Apollo.useQuery<GetImpersonatedOrganizationProgramsQuery, GetImpersonatedOrganizationProgramsQueryVariables>(GetImpersonatedOrganizationProgramsDocument, baseOptions);
      }
export function useGetImpersonatedOrganizationProgramsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetImpersonatedOrganizationProgramsQuery, GetImpersonatedOrganizationProgramsQueryVariables>) {
          return Apollo.useLazyQuery<GetImpersonatedOrganizationProgramsQuery, GetImpersonatedOrganizationProgramsQueryVariables>(GetImpersonatedOrganizationProgramsDocument, baseOptions);
        }
export type GetImpersonatedOrganizationProgramsQueryHookResult = ReturnType<typeof useGetImpersonatedOrganizationProgramsQuery>;
export type GetImpersonatedOrganizationProgramsLazyQueryHookResult = ReturnType<typeof useGetImpersonatedOrganizationProgramsLazyQuery>;
export type GetImpersonatedOrganizationProgramsQueryResult = Apollo.QueryResult<GetImpersonatedOrganizationProgramsQuery, GetImpersonatedOrganizationProgramsQueryVariables>;
export const GetImpersonatedOrganizationDocument = gql`
    query GetImpersonatedOrganization($organizationId: Int!) {
  getOrganization(id: $organizationId) {
    id
    name
  }
}
    `;

/**
 * __useGetImpersonatedOrganizationQuery__
 *
 * To run a query within a React component, call `useGetImpersonatedOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpersonatedOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpersonatedOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetImpersonatedOrganizationQuery(baseOptions?: Apollo.QueryHookOptions<GetImpersonatedOrganizationQuery, GetImpersonatedOrganizationQueryVariables>) {
        return Apollo.useQuery<GetImpersonatedOrganizationQuery, GetImpersonatedOrganizationQueryVariables>(GetImpersonatedOrganizationDocument, baseOptions);
      }
export function useGetImpersonatedOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetImpersonatedOrganizationQuery, GetImpersonatedOrganizationQueryVariables>) {
          return Apollo.useLazyQuery<GetImpersonatedOrganizationQuery, GetImpersonatedOrganizationQueryVariables>(GetImpersonatedOrganizationDocument, baseOptions);
        }
export type GetImpersonatedOrganizationQueryHookResult = ReturnType<typeof useGetImpersonatedOrganizationQuery>;
export type GetImpersonatedOrganizationLazyQueryHookResult = ReturnType<typeof useGetImpersonatedOrganizationLazyQuery>;
export type GetImpersonatedOrganizationQueryResult = Apollo.QueryResult<GetImpersonatedOrganizationQuery, GetImpersonatedOrganizationQueryVariables>;
export const GetLargestFundersDocument = gql`
    query GetLargestFunders($type: FunderTypeEnum, $startAt: Date, $endAt: Date) {
  currentUser {
    id
    organization {
      id
      programs {
        id
        name
      }
      largestFunders(startAt: $startAt, endAt: $endAt, type: $type) {
        overall {
          id
          name
          totalAwardAmount(startAt: $startAt, endAt: $endAt)
        }
        programFunders {
          funders {
            id
            name
            totalAwardAmount(startAt: $startAt, endAt: $endAt)
          }
          program {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetLargestFundersQuery__
 *
 * To run a query within a React component, call `useGetLargestFundersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLargestFundersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLargestFundersQuery({
 *   variables: {
 *      type: // value for 'type'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *   },
 * });
 */
export function useGetLargestFundersQuery(baseOptions?: Apollo.QueryHookOptions<GetLargestFundersQuery, GetLargestFundersQueryVariables>) {
        return Apollo.useQuery<GetLargestFundersQuery, GetLargestFundersQueryVariables>(GetLargestFundersDocument, baseOptions);
      }
export function useGetLargestFundersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLargestFundersQuery, GetLargestFundersQueryVariables>) {
          return Apollo.useLazyQuery<GetLargestFundersQuery, GetLargestFundersQueryVariables>(GetLargestFundersDocument, baseOptions);
        }
export type GetLargestFundersQueryHookResult = ReturnType<typeof useGetLargestFundersQuery>;
export type GetLargestFundersLazyQueryHookResult = ReturnType<typeof useGetLargestFundersLazyQuery>;
export type GetLargestFundersQueryResult = Apollo.QueryResult<GetLargestFundersQuery, GetLargestFundersQueryVariables>;
export const GetOldestAwardDateDocument = gql`
    query GetOldestAwardDate {
  currentUser {
    id
    organization {
      id
      oldestAward {
        id
        receivementDate
      }
    }
  }
}
    `;

/**
 * __useGetOldestAwardDateQuery__
 *
 * To run a query within a React component, call `useGetOldestAwardDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOldestAwardDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOldestAwardDateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOldestAwardDateQuery(baseOptions?: Apollo.QueryHookOptions<GetOldestAwardDateQuery, GetOldestAwardDateQueryVariables>) {
        return Apollo.useQuery<GetOldestAwardDateQuery, GetOldestAwardDateQueryVariables>(GetOldestAwardDateDocument, baseOptions);
      }
export function useGetOldestAwardDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOldestAwardDateQuery, GetOldestAwardDateQueryVariables>) {
          return Apollo.useLazyQuery<GetOldestAwardDateQuery, GetOldestAwardDateQueryVariables>(GetOldestAwardDateDocument, baseOptions);
        }
export type GetOldestAwardDateQueryHookResult = ReturnType<typeof useGetOldestAwardDateQuery>;
export type GetOldestAwardDateLazyQueryHookResult = ReturnType<typeof useGetOldestAwardDateLazyQuery>;
export type GetOldestAwardDateQueryResult = Apollo.QueryResult<GetOldestAwardDateQuery, GetOldestAwardDateQueryVariables>;
export const GetOrganizationFiscalYearDocument = gql`
    query GetOrganizationFiscalYear {
  currentUser {
    id
    organization {
      id
      currentFiscalYear
      fiscalYearStart
    }
  }
}
    `;

/**
 * __useGetOrganizationFiscalYearQuery__
 *
 * To run a query within a React component, call `useGetOrganizationFiscalYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationFiscalYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationFiscalYearQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationFiscalYearQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationFiscalYearQuery, GetOrganizationFiscalYearQueryVariables>) {
        return Apollo.useQuery<GetOrganizationFiscalYearQuery, GetOrganizationFiscalYearQueryVariables>(GetOrganizationFiscalYearDocument, baseOptions);
      }
export function useGetOrganizationFiscalYearLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationFiscalYearQuery, GetOrganizationFiscalYearQueryVariables>) {
          return Apollo.useLazyQuery<GetOrganizationFiscalYearQuery, GetOrganizationFiscalYearQueryVariables>(GetOrganizationFiscalYearDocument, baseOptions);
        }
export type GetOrganizationFiscalYearQueryHookResult = ReturnType<typeof useGetOrganizationFiscalYearQuery>;
export type GetOrganizationFiscalYearLazyQueryHookResult = ReturnType<typeof useGetOrganizationFiscalYearLazyQuery>;
export type GetOrganizationFiscalYearQueryResult = Apollo.QueryResult<GetOrganizationFiscalYearQuery, GetOrganizationFiscalYearQueryVariables>;
export const GetOrganizationProgramsDocument = gql`
    query GetOrganizationPrograms {
  currentUser {
    id
    organization {
      id
      funders {
        id
        name
      }
      programs {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetOrganizationProgramsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationProgramsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationProgramsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationProgramsQuery, GetOrganizationProgramsQueryVariables>) {
        return Apollo.useQuery<GetOrganizationProgramsQuery, GetOrganizationProgramsQueryVariables>(GetOrganizationProgramsDocument, baseOptions);
      }
export function useGetOrganizationProgramsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationProgramsQuery, GetOrganizationProgramsQueryVariables>) {
          return Apollo.useLazyQuery<GetOrganizationProgramsQuery, GetOrganizationProgramsQueryVariables>(GetOrganizationProgramsDocument, baseOptions);
        }
export type GetOrganizationProgramsQueryHookResult = ReturnType<typeof useGetOrganizationProgramsQuery>;
export type GetOrganizationProgramsLazyQueryHookResult = ReturnType<typeof useGetOrganizationProgramsLazyQuery>;
export type GetOrganizationProgramsQueryResult = Apollo.QueryResult<GetOrganizationProgramsQuery, GetOrganizationProgramsQueryVariables>;
export const GetOrganizationDocument = gql`
    query GetOrganization {
  currentUser {
    id
    organization {
      id
    }
  }
}
    `;

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
        return Apollo.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, baseOptions);
      }
export function useGetOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
          return Apollo.useLazyQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, baseOptions);
        }
export type GetOrganizationQueryHookResult = ReturnType<typeof useGetOrganizationQuery>;
export type GetOrganizationLazyQueryHookResult = ReturnType<typeof useGetOrganizationLazyQuery>;
export type GetOrganizationQueryResult = Apollo.QueryResult<GetOrganizationQuery, GetOrganizationQueryVariables>;
export const GetReportDocument = gql`
    query GetReport($id: Int!) {
  getReport(id: $id) {
    id
    deadline
    submitted
    submissionDate
    urgency
    grantApplication {
      id
      grant {
        id
        funder {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetReportQuery__
 *
 * To run a query within a React component, call `useGetReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReportQuery(baseOptions?: Apollo.QueryHookOptions<GetReportQuery, GetReportQueryVariables>) {
        return Apollo.useQuery<GetReportQuery, GetReportQueryVariables>(GetReportDocument, baseOptions);
      }
export function useGetReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportQuery, GetReportQueryVariables>) {
          return Apollo.useLazyQuery<GetReportQuery, GetReportQueryVariables>(GetReportDocument, baseOptions);
        }
export type GetReportQueryHookResult = ReturnType<typeof useGetReportQuery>;
export type GetReportLazyQueryHookResult = ReturnType<typeof useGetReportLazyQuery>;
export type GetReportQueryResult = Apollo.QueryResult<GetReportQuery, GetReportQueryVariables>;
export const GetUserAuthorizationDocument = gql`
    query GetUserAuthorization {
  currentUser {
    id
    authorized
    role
  }
}
    `;

/**
 * __useGetUserAuthorizationQuery__
 *
 * To run a query within a React component, call `useGetUserAuthorizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAuthorizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAuthorizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserAuthorizationQuery(baseOptions?: Apollo.QueryHookOptions<GetUserAuthorizationQuery, GetUserAuthorizationQueryVariables>) {
        return Apollo.useQuery<GetUserAuthorizationQuery, GetUserAuthorizationQueryVariables>(GetUserAuthorizationDocument, baseOptions);
      }
export function useGetUserAuthorizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAuthorizationQuery, GetUserAuthorizationQueryVariables>) {
          return Apollo.useLazyQuery<GetUserAuthorizationQuery, GetUserAuthorizationQueryVariables>(GetUserAuthorizationDocument, baseOptions);
        }
export type GetUserAuthorizationQueryHookResult = ReturnType<typeof useGetUserAuthorizationQuery>;
export type GetUserAuthorizationLazyQueryHookResult = ReturnType<typeof useGetUserAuthorizationLazyQuery>;
export type GetUserAuthorizationQueryResult = Apollo.QueryResult<GetUserAuthorizationQuery, GetUserAuthorizationQueryVariables>;
export const ListApplicationsDocument = gql`
    query ListApplications($filters: FilterApplicationParams!, $pagination: PaginationParams) {
  listApplications(filters: $filters, pagination: $pagination) {
    id
    award {
      id
      amountAwarded
      receivementDate
    }
    grant {
      id
      program {
        id
        name
      }
      funder {
        id
        name
        lastAward {
          id
          receivementDate
        }
      }
      responseAverage
    }
    status
    deadline
    declined
    submission {
      id
      submissionDate
      amountRequested
    }
  }
}
    `;

/**
 * __useListApplicationsQuery__
 *
 * To run a query within a React component, call `useListApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListApplicationsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useListApplicationsQuery(baseOptions?: Apollo.QueryHookOptions<ListApplicationsQuery, ListApplicationsQueryVariables>) {
        return Apollo.useQuery<ListApplicationsQuery, ListApplicationsQueryVariables>(ListApplicationsDocument, baseOptions);
      }
export function useListApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListApplicationsQuery, ListApplicationsQueryVariables>) {
          return Apollo.useLazyQuery<ListApplicationsQuery, ListApplicationsQueryVariables>(ListApplicationsDocument, baseOptions);
        }
export type ListApplicationsQueryHookResult = ReturnType<typeof useListApplicationsQuery>;
export type ListApplicationsLazyQueryHookResult = ReturnType<typeof useListApplicationsLazyQuery>;
export type ListApplicationsQueryResult = Apollo.QueryResult<ListApplicationsQuery, ListApplicationsQueryVariables>;
export const ListFundersDocument = gql`
    query ListFunders {
  currentUser {
    id
    organization {
      id
      funders {
        id
        name
        email
        type
        website
        totalAwardAmount
      }
    }
  }
}
    `;

/**
 * __useListFundersQuery__
 *
 * To run a query within a React component, call `useListFundersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFundersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFundersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListFundersQuery(baseOptions?: Apollo.QueryHookOptions<ListFundersQuery, ListFundersQueryVariables>) {
        return Apollo.useQuery<ListFundersQuery, ListFundersQueryVariables>(ListFundersDocument, baseOptions);
      }
export function useListFundersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListFundersQuery, ListFundersQueryVariables>) {
          return Apollo.useLazyQuery<ListFundersQuery, ListFundersQueryVariables>(ListFundersDocument, baseOptions);
        }
export type ListFundersQueryHookResult = ReturnType<typeof useListFundersQuery>;
export type ListFundersLazyQueryHookResult = ReturnType<typeof useListFundersLazyQuery>;
export type ListFundersQueryResult = Apollo.QueryResult<ListFundersQuery, ListFundersQueryVariables>;
export const ListImpersonatedOrganizationFundersDocument = gql`
    query ListImpersonatedOrganizationFunders($organizationId: Int!) {
  getOrganization(id: $organizationId) {
    id
    name
    funders {
      id
      name
      email
      type
      website
      totalAwardAmount
    }
  }
}
    `;

/**
 * __useListImpersonatedOrganizationFundersQuery__
 *
 * To run a query within a React component, call `useListImpersonatedOrganizationFundersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListImpersonatedOrganizationFundersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListImpersonatedOrganizationFundersQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useListImpersonatedOrganizationFundersQuery(baseOptions?: Apollo.QueryHookOptions<ListImpersonatedOrganizationFundersQuery, ListImpersonatedOrganizationFundersQueryVariables>) {
        return Apollo.useQuery<ListImpersonatedOrganizationFundersQuery, ListImpersonatedOrganizationFundersQueryVariables>(ListImpersonatedOrganizationFundersDocument, baseOptions);
      }
export function useListImpersonatedOrganizationFundersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListImpersonatedOrganizationFundersQuery, ListImpersonatedOrganizationFundersQueryVariables>) {
          return Apollo.useLazyQuery<ListImpersonatedOrganizationFundersQuery, ListImpersonatedOrganizationFundersQueryVariables>(ListImpersonatedOrganizationFundersDocument, baseOptions);
        }
export type ListImpersonatedOrganizationFundersQueryHookResult = ReturnType<typeof useListImpersonatedOrganizationFundersQuery>;
export type ListImpersonatedOrganizationFundersLazyQueryHookResult = ReturnType<typeof useListImpersonatedOrganizationFundersLazyQuery>;
export type ListImpersonatedOrganizationFundersQueryResult = Apollo.QueryResult<ListImpersonatedOrganizationFundersQuery, ListImpersonatedOrganizationFundersQueryVariables>;
export const ListReportsDocument = gql`
    query ListReports($filters: FilterReportParams!, $pagination: PaginationParams) {
  listReports(filters: $filters, pagination: $pagination) {
    id
    deadline
    submissionDate
    submitted
    urgency
    grantApplication {
      id
      award {
        id
        amountAwarded
      }
      grant {
        id
        funder {
          id
          name
        }
        program {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useListReportsQuery__
 *
 * To run a query within a React component, call `useListReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListReportsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useListReportsQuery(baseOptions?: Apollo.QueryHookOptions<ListReportsQuery, ListReportsQueryVariables>) {
        return Apollo.useQuery<ListReportsQuery, ListReportsQueryVariables>(ListReportsDocument, baseOptions);
      }
export function useListReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListReportsQuery, ListReportsQueryVariables>) {
          return Apollo.useLazyQuery<ListReportsQuery, ListReportsQueryVariables>(ListReportsDocument, baseOptions);
        }
export type ListReportsQueryHookResult = ReturnType<typeof useListReportsQuery>;
export type ListReportsLazyQueryHookResult = ReturnType<typeof useListReportsLazyQuery>;
export type ListReportsQueryResult = Apollo.QueryResult<ListReportsQuery, ListReportsQueryVariables>;
export const GetBaseCurrentOrganizationDocument = gql`
    query GetBaseCurrentOrganization {
  currentUser {
    id
    organization {
      id
    }
  }
}
    `;

/**
 * __useGetBaseCurrentOrganizationQuery__
 *
 * To run a query within a React component, call `useGetBaseCurrentOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseCurrentOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseCurrentOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBaseCurrentOrganizationQuery(baseOptions?: Apollo.QueryHookOptions<GetBaseCurrentOrganizationQuery, GetBaseCurrentOrganizationQueryVariables>) {
        return Apollo.useQuery<GetBaseCurrentOrganizationQuery, GetBaseCurrentOrganizationQueryVariables>(GetBaseCurrentOrganizationDocument, baseOptions);
      }
export function useGetBaseCurrentOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBaseCurrentOrganizationQuery, GetBaseCurrentOrganizationQueryVariables>) {
          return Apollo.useLazyQuery<GetBaseCurrentOrganizationQuery, GetBaseCurrentOrganizationQueryVariables>(GetBaseCurrentOrganizationDocument, baseOptions);
        }
export type GetBaseCurrentOrganizationQueryHookResult = ReturnType<typeof useGetBaseCurrentOrganizationQuery>;
export type GetBaseCurrentOrganizationLazyQueryHookResult = ReturnType<typeof useGetBaseCurrentOrganizationLazyQuery>;
export type GetBaseCurrentOrganizationQueryResult = Apollo.QueryResult<GetBaseCurrentOrganizationQuery, GetBaseCurrentOrganizationQueryVariables>;
export const GetBaseImpersonatedOrganizationDocument = gql`
    query GetBaseImpersonatedOrganization($organizationId: Int!) {
  getOrganization(id: $organizationId) {
    id
    name
  }
}
    `;

/**
 * __useGetBaseImpersonatedOrganizationQuery__
 *
 * To run a query within a React component, call `useGetBaseImpersonatedOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseImpersonatedOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseImpersonatedOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetBaseImpersonatedOrganizationQuery(baseOptions?: Apollo.QueryHookOptions<GetBaseImpersonatedOrganizationQuery, GetBaseImpersonatedOrganizationQueryVariables>) {
        return Apollo.useQuery<GetBaseImpersonatedOrganizationQuery, GetBaseImpersonatedOrganizationQueryVariables>(GetBaseImpersonatedOrganizationDocument, baseOptions);
      }
export function useGetBaseImpersonatedOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBaseImpersonatedOrganizationQuery, GetBaseImpersonatedOrganizationQueryVariables>) {
          return Apollo.useLazyQuery<GetBaseImpersonatedOrganizationQuery, GetBaseImpersonatedOrganizationQueryVariables>(GetBaseImpersonatedOrganizationDocument, baseOptions);
        }
export type GetBaseImpersonatedOrganizationQueryHookResult = ReturnType<typeof useGetBaseImpersonatedOrganizationQuery>;
export type GetBaseImpersonatedOrganizationLazyQueryHookResult = ReturnType<typeof useGetBaseImpersonatedOrganizationLazyQuery>;
export type GetBaseImpersonatedOrganizationQueryResult = Apollo.QueryResult<GetBaseImpersonatedOrganizationQuery, GetBaseImpersonatedOrganizationQueryVariables>;