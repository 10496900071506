import numeral from "numeral";

const CURRENCY_FORMAT = "$0,0[.]00";
const ABBREVIATION_FORMAT = "$0.[0]a";
/**
 * Receives a number and returns it formatted as currency
 * @param amount USD amount
 * @param useAbbreviation boolean to that adds thousands formatting
 * e.g.: $1K, $2.5K
 */
const formatCurrency = (
  amount: number,
  useAbbreviation?: boolean,
): string | undefined => (
  numeral(amount)
    .format(useAbbreviation ? ABBREVIATION_FORMAT : CURRENCY_FORMAT)
    .toUpperCase()
);

export default formatCurrency;
