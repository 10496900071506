import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useUpdateFunderMutation } from "generated/graphql";
import { useToast } from "contexts/toast";
import updateHowToApplySchema, { UpdateHowToApplySchema } from "settings/yup/schemas/updateHowToApplySchema";
import capitalizeStringFirstLetter from "utils/capitalizeStringFirstLetter";

import { HowToApplyCardProps } from "./types";
import CardTextArea from "../CardTextArea";

const HowToApplyCard: React.FC<HowToApplyCardProps> = ({
  funderId,
  funderHowToApply,
}) => {
  const [t] = useTranslation();
  const { addToast } = useToast();

  const {
    control,
    reset,
    handleSubmit,
    formState,
  } = useForm<UpdateHowToApplySchema>({
    resolver: yupResolver(updateHowToApplySchema),
    mode: "onBlur",
  });

  useEffect(() => {
    if (funderHowToApply) {
      reset({
        howToApply: funderHowToApply ?? "",
      });
    }
  }, [
    reset,
    funderHowToApply,
  ]);

  const [updateHowToApply, {
    loading: isLoading,
  }] = useUpdateFunderMutation();

  const onSubmit = useCallback((values: UpdateHowToApplySchema) => {
    if (!funderId || !formState.isDirty) {
      return;
    }

    updateHowToApply({
      variables: {
        id: funderId,
        params: {
          howToApply: values?.howToApply,
        },
      },
    }).then(() => {
      addToast({
        title: t("how_to_apply_card.funder_how_to_apply_have_been_updated"),
        type: "success",
      });
    })
      .catch((error) => {
        addToast({
          title: capitalizeStringFirstLetter(error.message),
          type: "error",
        });
      });
  }, [
    formState.isDirty,
    updateHowToApply,
    funderId,
    addToast,
    t,
  ]);

  return (
    <CardTextArea
      isLoading={isLoading}
      handleSubmit={handleSubmit(onSubmit)}
      value={funderHowToApply}
      title={`${t("how_to_apply_card.how_to_apply")}:`}
      control={control}
      name="howToApply"
      funderId={funderId}
    />
  );
};

export default HowToApplyCard;
