import { gql } from "@apollo/client";

export const DeleteFunderMutationRefetchQueries = [
  "ListFunders",
];

export default gql`
  mutation DeleteFunder ($id: Int!) {
    deleteFunder(id: $id) {
      id
    }
  }
`;
