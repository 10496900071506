import i18n from "translations/i18n";

export enum MonthsEnum {
  January = "JANUARY",

  February = "FEBRUARY",

  March = "MARCH",

  April = "APRIL",

  May = "MAY",

  June = "JUNE",

  July = "JULY",

  August = "AUGUST",

  September = "SEPTEMBER",

  October = "OCTOBER",

  November = "NOVEMBER",

  December = "DECEMBER",
}

export interface Month {
  label: string;
  value: string;
}

export const months: Record<MonthsEnum, Month> = {
  [MonthsEnum.January]: {
    label: i18n.t("months.january"),
    value: MonthsEnum.January,
  },
  [MonthsEnum.February]: {
    label: i18n.t("months.february"),
    value: MonthsEnum.February,
  },
  [MonthsEnum.March]: {
    label: i18n.t("months.march"),
    value: MonthsEnum.March,
  },
  [MonthsEnum.April]: {
    label: i18n.t("months.april"),
    value: MonthsEnum.April,
  },
  [MonthsEnum.May]: {
    label: i18n.t("months.may"),
    value: MonthsEnum.May,
  },
  [MonthsEnum.June]: {
    label: i18n.t("months.june"),
    value: MonthsEnum.June,
  },
  [MonthsEnum.July]: {
    label: i18n.t("months.july"),
    value: MonthsEnum.July,
  },
  [MonthsEnum.August]: {
    label: i18n.t("months.august"),
    value: MonthsEnum.August,
  },
  [MonthsEnum.September]: {
    label: i18n.t("months.september"),
    value: MonthsEnum.September,
  },
  [MonthsEnum.October]: {
    label: i18n.t("months.october"),
    value: MonthsEnum.October,
  },
  [MonthsEnum.November]: {
    label: i18n.t("months.november"),
    value: MonthsEnum.November,
  },
  [MonthsEnum.December]: {
    label: i18n.t("months.december"),
    value: MonthsEnum.December,
  },
};
