import React from "react";
import { Box, Text } from "@chakra-ui/layout";
import { Button as RainbowButton } from "react-rainbow-components";

import { ButtonProps } from "./types";

const Button: React.FC<ButtonProps> = ({
  isLoading = false,
  children,
  textProps,
  disabled,
  ...rest
}) => (
  <Box
    as={RainbowButton}
    padding="0px 16px"
    outline="none"
    fontSize="xs"
    fontFamily="heading"
    variant="base"
    color="white"
    borderRadius={2}
    disabled={disabled}
    _hover={{ color: "white" }}
    _focus={{ boxShadow: "none", color: "white" }}
    _disabled={{ background: "gray.200", color: "white" }}
    isLoading={isLoading}
    {...rest}
  >
    <>
      {
        typeof children === "string"
          ? (
            <Text
              as="span"
              {...textProps}
            >
              {children}
            </Text>
          )
          : children
      }

    </>
  </Box>
);

export default Button;
