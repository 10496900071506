import { FunderTypeEnum } from "generated/graphql";
import formatStatus from "utils/formatStatus";

const labels = [
  formatStatus(FunderTypeEnum?.Corporate) ?? "-",
  formatStatus(FunderTypeEnum?.Foundation) ?? "-",
  formatStatus(FunderTypeEnum?.Government) ?? "-",
  formatStatus(FunderTypeEnum?.Other) ?? "-",
];

export default labels;
