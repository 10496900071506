import { GetOrganizationProgramsQuery } from "generated/graphql";

import { OptionsProps } from "./types";

/**
 * Receives tableData from the ListPrograms query and
 * returns it formatted
 * @param tableData rawTableData
 */
const formatProgramsList = (
  tableData?: GetOrganizationProgramsQuery["currentUser"]["organization"]["programs"],
): OptionsProps[] => {
  if (!tableData) {
    return [];
  }

  const formattedListData = (tableData).map(program => (
    {
      value: program?.id ?? undefined,

      label: program?.name ?? "-",
    }
  ));

  return formattedListData;
};

export default formatProgramsList;
