import React, { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

import FormWizard from "components/FormWizard";
import createFunderSchema, { CreateFunderSchema } from "settings/yup/schemas/createFunderSchema";
import { useCreateFunderMutation, useGetImpersonatedOrganizationQuery, useGetOrganizationQuery } from "generated/graphql";
import { createFunderRefetchQueries } from "graphql/mutations/createFunderMutation";
import { useToast } from "contexts/toast";
import useWizardStore from "components/Wizard/store";
import capitalizeStringFirstLetter from "utils/capitalizeStringFirstLetter";
import useGetOrganization from "hooks/useGetOrganization";

import createFunderSteps from "./steps";
import { CreateFunderFormProps } from "./types";

const CreateFunderForm: React.FC<CreateFunderFormProps> = ({
  hideModal,
  componentProps: {
    successCallback,
  },
}) => {
  const { addToast } = useToast();

  const [t] = useTranslation();

  const { setStepIndex } = useWizardStore((store) => ({
    setStepIndex: store.setStepIndex,
  }));

  const form = useForm<CreateFunderSchema>({
    resolver: yupResolver(createFunderSchema),
    mode: "onBlur",
    defaultValues: {
      name: undefined,
      type: undefined,
    },
    shouldUnregister: false,
  });

  const [organization] = useGetOrganization(
    useGetOrganizationQuery,
    useGetImpersonatedOrganizationQuery,
  );

  const [
    createFunder,
    {
      loading,
    },
  ] = useCreateFunderMutation({
    refetchQueries: createFunderRefetchQueries,
    awaitRefetchQueries: true,
  });

  const onSubmit = useCallback((values: CreateFunderSchema) => {
    if (!organization?.id) {
      addToast({
        type: "error",
        title: t("errors.the_organization_id"),
      });
      return;
    }

    createFunder({
      variables: {
        params: {
          name: values?.name,
          type: values?.type?.value,
          organizationId: organization.id,
        },
      },
    })
      .then((result) => {
        const funder = result?.data?.createFunder;

        addToast({
          title: t("create_funder.success"),
          type: "success",
        });

        hideModal();

        setStepIndex(0);

        if (successCallback && funder?.id) {
          successCallback(funder);
        }
      })
      .catch((error) => {
        addToast({
          title: capitalizeStringFirstLetter(error.message),
          type: "error",
        });
      });
  }, [
    t,
    addToast,
    hideModal,
    setStepIndex,
    createFunder,
    organization,
    successCallback,
  ]);

  return (
    <FormProvider {...form}>
      <FormWizard
        steps={createFunderSteps}
        onSubmit={form.handleSubmit(onSubmit)}
        isLoading={loading}
      />
    </FormProvider>
  );
};

export default CreateFunderForm;
