import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

import { TOKEN_KEY } from "constants/auth";
import { ROOT_PAGE_PATH } from "routes";

import { UseSessionPayload } from "./types";

/**
 * Hook that exposes session handling functions.
 */
const useSession = (): UseSessionPayload => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const history = useHistory();

  const currentToken = cookies[TOKEN_KEY];

  const createSession = useCallback((
    token: string,
    redirectTo: string = ROOT_PAGE_PATH,
  ) => {
    setCookie(TOKEN_KEY, token, {
      path: ROOT_PAGE_PATH,
    });

    history.push(redirectTo);
  }, [
    setCookie,
    history,
  ]);

  const endSession = useCallback(() => {
    removeCookie(TOKEN_KEY, {
      path: ROOT_PAGE_PATH,
    });

    window.location.href = ROOT_PAGE_PATH;
  }, [
    removeCookie,
  ]);

  const payload = useMemo<UseSessionPayload>(() => [
    createSession,
    endSession,
    currentToken,
  ], [
    currentToken,
    createSession,
    endSession,
  ]);

  return payload;
};

export default useSession;
