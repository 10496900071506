import React from "react";
import { SimpleGrid, Stack } from "@chakra-ui/layout";
import { useTranslation } from "react-i18next";
import { Picklist, DatePicker, Option } from "react-rainbow-components";
import { Controller, useFormContext } from "react-hook-form";

import getErrorMessage from "utils/getErrorMessage";
import { CreateGrantSchema } from "settings/yup/schemas/createGrantSchema";

import { deadlineOptions } from "./options";

const Deadlines: React.FC = () => {
  const [t] = useTranslation();

  const {
    errors,
    control,
  } = useFormContext<CreateGrantSchema>();

  return (
    <Stack
      as="form"
      spacing={6}
      marginBottom={8}
      width="full"
    >
      <SimpleGrid
        columns={2}
        gap={6}
        alignItems="baseline"
      >
        <Controller
          as={Picklist}
          control={control}
          name="applicationType"
          error={getErrorMessage("applicationType", errors)}
          label={t("create_grant.steps.deadlines.application_type")}
          placeholder={t("create_grant.steps.deadlines.select_a_type")}
          labelAlignment="left"
          required
        >
          {
            deadlineOptions.map((type) => (
              <Option
                name={type.value}
                value={type.value}
                label={type.label}
                key={type.label}
              />
            ))
          }
        </Controller>

        <Controller
          as={DatePicker}
          control={control}
          name="applicationDeadline"
          label={t("create_grant.steps.deadlines.application_deadline")}
          error={getErrorMessage("applicationDeadline", errors)}
          required
        />
      </SimpleGrid>
    </Stack>
  );
};

export default Deadlines;
