import { PreviousAwardsTableTitlesEnum } from "constants/previousAwardsTableTitles";
import { GetFunderQuery } from "generated/graphql";

import { displayMonthDayYear, formatDate, yearMask } from "./dateFormats";
import formatCurrency from "./formatCurrency";
import getResponseDays from "./getResponseDays";

export interface PreviousAwardsProps {
  id: string;

  [PreviousAwardsTableTitlesEnum.Year]?: string;

  [PreviousAwardsTableTitlesEnum.Program]?: string;

  [PreviousAwardsTableTitlesEnum.PreviousAwards]?: string;

  [PreviousAwardsTableTitlesEnum.DateAwarded]?: string;

  [PreviousAwardsTableTitlesEnum.DateSubmitted]?: string;

  [PreviousAwardsTableTitlesEnum.ResponseDays]?: string;
}

/**
 * Receives awards data from the getFunder query and
 * returns it formatted
 * @param tableData rawTableData
 */
const formatPreviousAwardsTableData = (
  tableData: GetFunderQuery | undefined,
): PreviousAwardsProps[] => {
  const previousAwardsList = tableData?.getFunder?.awards;

  if (!tableData || !previousAwardsList) {
    return [];
  }

  const sortedPreviousAwardsData = [...previousAwardsList].sort(
    (a, b) => (b.receivementDate > a.receivementDate ? 1 : -1),
  );

  const formattedPreviousAwardsData = sortedPreviousAwardsData.map(previousAward => (
    {
      id: previousAward?.id?.toString() ?? "",

      [PreviousAwardsTableTitlesEnum.Year]: formatDate(previousAward?.receivementDate, yearMask),

      [PreviousAwardsTableTitlesEnum.Program]: previousAward?.program?.name,

      [PreviousAwardsTableTitlesEnum.PreviousAwards]: formatCurrency(previousAward?.amountAwarded),

      [PreviousAwardsTableTitlesEnum.DateSubmitted]: formatDate(
          previousAward?.grantApplication?.submission?.submissionDate,
          displayMonthDayYear,
      ),

      [PreviousAwardsTableTitlesEnum.DateAwarded]: formatDate(
        (
          !previousAward?.isPrecisionByYear
          && previousAward?.receivementDate
        ),
        displayMonthDayYear,
      ),

      [PreviousAwardsTableTitlesEnum.ResponseDays]: (
        previousAward?.isPrecisionByYear
          ? "-"
          : getResponseDays(previousAward?.responseDays)
      ),
    }
  ));

  return formattedPreviousAwardsData;
};

export default formatPreviousAwardsTableData;
