import { Flex } from "@chakra-ui/layout";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { UserRoleEnum } from "generated/graphql";
import {
  DASHBOARD_PAGE_PATH,
  FUNDERS_LIST_PAGE_PATH,
  GRANT_FLOW_PAGE_PATH,
  REPORT_CENTER_PAGE_PATH,
  ADMIN_PAGE_PATH,
  backendUrl,
  LARGEST_FUNDERS_PAGE_PATH,
} from "routes";
import LogoIcon from "settings/theme/ChakraTheme/icons/LogoIcon";
import AdminImpersonation from "components/AdminImpersonation";

import HeaderLink from "./HeaderLink";
import HeaderMenu from "./HeaderMenu";
import HeaderExternalLink from "./HeaderExternalLink";
import { HeaderProps } from "./types";

const Header: React.FC<HeaderProps> = ({
  showNavItems,
  userRole = UserRoleEnum.User,
}) => {
  const [t] = useTranslation();

  const isAdmin = userRole === UserRoleEnum.Admin;

  return (
    <nav className="header header-wrapper hide-on-print">
      <Link to={DASHBOARD_PAGE_PATH}>
        <LogoIcon boxSize="80px" />
      </Link>

      <div className="links-wrapper">
        {
          showNavItems && (
            <>
              <HeaderLink
                to={DASHBOARD_PAGE_PATH}
                title={t("header.dashboard")}
              />

              <HeaderLink
                to={GRANT_FLOW_PAGE_PATH}
                title={t("header.grant_flow")}
              />

              <HeaderLink
                to={REPORT_CENTER_PAGE_PATH}
                title={t("header.report_center")}
              />

              <HeaderLink
                to={FUNDERS_LIST_PAGE_PATH}
                title={t("header.funders")}
              />

              <HeaderLink
                to={LARGEST_FUNDERS_PAGE_PATH}
                title={t("header.largest_funders")}
              />

              {
                isAdmin && (
                  <HeaderExternalLink
                    href={`${backendUrl}${ADMIN_PAGE_PATH}`}
                    label={t("header.admin")}
                  />
                )
              }
            </>
          )
        }
        {/*
    Removed for the first release.
    Will be implemented afterward: https://jungsoft.monday.com/boards/726667435/pulses/774917029
    <HeaderLink
    to={CALENDAR_PAGE_PATH}
    title={t("header.calendar")}
  /> */}
      </div>

      <Flex
        alignItems="center"
      >
        {/*
          Removed for the first release.
          Will be implemented afterward: https://jungsoft.monday.com/boards/726667435/pulses/774916464
          <NotificationBell />
        */}
        <AdminImpersonation />

        <HeaderMenu />
      </Flex>
    </nav>
  );
};

export default Header;
