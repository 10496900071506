/**
 * Receives an string and returns it
 *  formatted with the first letter capitalized.
 * @param message error message
 */
const capitalizeStringFirstLetter = (message: string): string => (
  message.charAt(0).toUpperCase() + message.slice(1)
);

export default capitalizeStringFirstLetter;
