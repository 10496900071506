import React from "react";
import {
  OrderedList,
  ListItem,
  Stack,
  Text,
} from "@chakra-ui/layout";

import { LargestFundersPrintTableProps } from "../types";

/**
 * Component that renders the largest funders table as print-friendly.
 * Only visible when printing, as the table itself will be invisible and this will show up.
 * @param param0 Props.
 */
const LargestFundersPrintTable: React.FC<LargestFundersPrintTableProps> = ({
  table,
}) => (
  <Stack
    className="show-on-print"
    spacing={4}
    mt={4}
  >
    {
      table?.formattedHeaders?.map((header) => {
        const largestFunders = (table?.largestFunders ?? [])
          .map((item) => item[header.id])
          .filter((item) => !!item);

        return (
          <Stack key={header.name} spacing={2}>
            <Text
              fontSize={{ base: "xxs", md: "xs", lg: "xs" }}
              fontFamily="heading"
            >
              {header.name}
            </Text>

            {
              largestFunders.length > 0
                ? (
                  <OrderedList pl={4}>
                    {
                      largestFunders.map((largestFunder) => (
                        <Text key={largestFunder} as={ListItem}>
                          {largestFunder}
                        </Text>
                      ))
                    }
                  </OrderedList>
                )
                : (
                  <Text pl={3}>
                    -
                  </Text>
                )
            }
          </Stack>
        );
      })
    }
  </Stack>
);

export default LargestFundersPrintTable;
