import { WizardStep } from "components/Wizard/types";
import i18n from "translations/i18n";

import Funder from "./Steps/Funder";
import Deadlines from "./Steps/Deadlines";
import Status from "./Steps/Status";

export enum CreateGrantStep {
  FunderStep,
  DeadlinesStep,
  StatusStep,
}

const createGrantSteps: WizardStep[] = [
  {
    id: CreateGrantStep.FunderStep,
    component: Funder,
    title: i18n.t("create_grant.steps.funder.title"),
  },
  {
    id: CreateGrantStep.DeadlinesStep,
    component: Deadlines,
    title: i18n.t("create_grant.steps.deadlines.title"),
  },
  {
    id: CreateGrantStep.StatusStep,
    component: Status,
    title: i18n.t("create_grant.steps.status.title"),
  },
];

export default createGrantSteps;
