import { LabelOptions } from "chartjs-plugin-datalabels/types/options";

import formatCurrency from "utils/formatCurrency";

export const datalabelsConfigs: LabelOptions = {
  color: "black",
  anchor: "end",
  align: "top",
  offset: 0,
  padding: 0,
  formatter(value) {
    return `${formatCurrency(value)}`;
  },
};
