import React from "react";
import { Text } from "@chakra-ui/layout";

import TableCellContainer from "components/Table/TableCell";

import { DefaultCellProps } from "./types";

const DefaultCell: React.FC<DefaultCellProps> = ({
  value,
  children,
  ...props
}) => (
  <TableCellContainer>
    <Text
      textAlign="center"
      wordBreak="break-word"
      whiteSpace="normal"
      lineHeight="none"
      {...props}
    >
      {value || "-"}
    </Text>

    {children}
  </TableCellContainer>
);

export default DefaultCell;
