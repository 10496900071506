import React from "react";

import UpdateButtonCell from "components/Table/TableCell/CellValue/UpdateButtonCell";
import i18n from "translations/i18n";
import { GrantCell, FunderCell } from "components/Table/TableCell/CellValue/GrantCell";

export enum TableTitlesEnum {
  ApplicationDeadline = "applicationDeadline",

  Funder = "funder",

  LastAward = "lastAward",

  DateSubmitted = "dateSubmitted",

  AmountRequested = "amountRequested",

  Program = "program",

  DateReceived = "dateReceived",

  AmountAwarded = "amountAwarded",

  ResponseAverage = "responseAverage",

  Status = "status",
}

export interface ColumnContent {
  label: string;
  field: string;
  component?: React.FC;
  componentProps?: Record<string, string>;
}

export const grantFlowTableColumns: Record<TableTitlesEnum, ColumnContent> = {
  [TableTitlesEnum.ApplicationDeadline]: {
    label: i18n.t("tables.columns_titles.application_deadline"),
    field: TableTitlesEnum.ApplicationDeadline,
    component: GrantCell,
  },
  [TableTitlesEnum.Funder]: {
    label: i18n.t("tables.columns_titles.funder"),
    field: TableTitlesEnum.Funder,
    component: FunderCell,
  },
  [TableTitlesEnum.LastAward]: {
    label: i18n.t("tables.columns_titles.last_award"),
    field: TableTitlesEnum.LastAward,
    component: GrantCell,
  },
  [TableTitlesEnum.DateSubmitted]: {
    label: i18n.t("tables.columns_titles.date_submitted"),
    field: TableTitlesEnum.DateSubmitted,
    component: GrantCell,
  },
  [TableTitlesEnum.AmountRequested]: {
    label: i18n.t("tables.columns_titles.amount_requested"),
    field: TableTitlesEnum.AmountRequested,
    component: GrantCell,
  },
  [TableTitlesEnum.Program]: {
    label: i18n.t("tables.columns_titles.program"),
    field: TableTitlesEnum.Program,
    component: GrantCell,
  },
  [TableTitlesEnum.DateReceived]: {
    label: i18n.t("tables.columns_titles.date_received"),
    field: TableTitlesEnum.DateReceived,
    component: GrantCell,
  },
  [TableTitlesEnum.AmountAwarded]: {
    label: i18n.t("tables.columns_titles.amount_awarded"),
    field: TableTitlesEnum.AmountAwarded,
    component: GrantCell,
  },
  [TableTitlesEnum.ResponseAverage]: {
    label: i18n.t("tables.columns_titles.response_average"),
    field: TableTitlesEnum.ResponseAverage,
    component: GrantCell,
  },
  [TableTitlesEnum.Status]: {
    label: i18n.t("tables.columns_titles.status"),
    field: TableTitlesEnum.Status,
    component: UpdateButtonCell,
  },
};

/**
 * Defines grantFlow table titles.
 */
export const grantFlowFormattedColumns = Object.keys(grantFlowTableColumns).map(
  (key) => ({
    label: grantFlowTableColumns[key].label as string,
    field: grantFlowTableColumns[key].field as string,
    component: grantFlowTableColumns[key].component,
    componentProps: grantFlowTableColumns[key].componentProps,
  }),
);
