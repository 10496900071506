import { endOfDay } from "date-fns";

import yup from "settings/yup";
import i18n from "translations/i18n";

import buildCurrencyTest from "../tests/currencyFieldTest";
import awardedYearTest from "../tests/awardedYearTest";
import minAwardedDateTest from "../tests/awardedDateTest";

export const previousAwardDefaultValues = {
  amountAwarded: "",
  program: undefined,
  submissionDate: undefined,
  isAwardedDateKnown: false,
  awardedDate: undefined,
  awardedYear: "",
};

const createPreviousAwardSchema = yup.object()
  .shape({
    amountAwarded: yup
      .string()
      .test(buildCurrencyTest())
      .default(previousAwardDefaultValues.amountAwarded)
      .required(),
    program: yup
      .object({
        label: yup.string().required(),
        value: yup.number().required(),
        name: yup.string(),
      })
      .default(previousAwardDefaultValues.program)
      .required(),
    submissionDate: yup
      .date()
      .default(previousAwardDefaultValues.submissionDate)
      .max(
        endOfDay(new Date()).toDateString(),
        ({ max }) => i18n.t("yup.string_max_or_equal", { max }),
      ),
    isAwardedDateKnown: yup
      .boolean()
      .default(previousAwardDefaultValues.isAwardedDateKnown),
    awardedDate: yup
      .date()
      .default(previousAwardDefaultValues.awardedDate)
      .when("isAwardedDateKnown", {
        is: true,
        then: yup
          .date()
          .max(
            endOfDay(new Date()).toDateString(),
            ({ max }) => i18n.t("yup.string_max_or_equal", { max }),
          )
          .test(minAwardedDateTest(true))
          .required(),
        otherwise: yup
          .date(),
      }),
    awardedYear: yup
      .mixed()
      .default(previousAwardDefaultValues.awardedYear)
      .when("isAwardedDateKnown", {
        is: false,
        then: yup
          .mixed()
          .test(awardedYearTest(true)),
        otherwise: yup
          .mixed(),
      }),
  });

export interface OptionProps {
    label?: string;
    name?: string;
    value?: number;
  }

export interface CreatePreviousAwardSchema {
  amountAwarded: string;
  program: OptionProps;
  submissionDate: Date;
  isAwardedDateKnown: boolean;
  awardedDate?: Date;
  awardedYear?: Record<string, unknown> | null | string | number;
}

export default createPreviousAwardSchema;
