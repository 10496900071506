import { AssertingTestOptions } from "yup";

import i18n from "translations/i18n";

/**
 * Applies date precedence validation in one schema field.
 */
const minAwardedDateTest = (isRequired = true): AssertingTestOptions<string> => ({
  name: "isAfterSubmissionDate",
  exclusive: false,
  message: i18n.t("errors.awarded_date_must_come_after_submission_date"),
  test (value: string): value is string {
    if ((!value && !isRequired) || !this.parent.submissionDate) {
      return true;
    }

    return (new Date(value) >= this.parent.submissionDate);
  },
});

export default minAwardedDateTest;
