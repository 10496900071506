import { AllApplicationsEnum, AllRecordsEnum, ReportStatusEnum } from "constants/statuses";
import { FilterApplicationStatusEnum } from "generated/graphql";

export const getGrantStatus = (
  status: FilterApplicationStatusEnum | AllApplicationsEnum,
): FilterApplicationStatusEnum | undefined => {
  if (status === AllApplicationsEnum.AllApplications) {
    return undefined;
  }

  return status;
};

export const getReportStatus = (
  status: AllRecordsEnum,
): boolean | undefined => {
  if (status === AllRecordsEnum.AllRecords) {
    return undefined;
  }

  return status === ReportStatusEnum.Submitted;
};
