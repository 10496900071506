import React from "react";
import {
  Box,
  List,
  ListItem,
  Text,
} from "@chakra-ui/layout";

import { ChartLegendProps } from "./types";

/**
 * Renders a chart legend.
 * Labels, colors and values must have the same length.
 */
const ChartLegend: React.FC<ChartLegendProps> = ({
  labels,
  colors,
  values,
  ...rest
}) => {
  const legendItems = labels.map((label, index) => (
    {
      label,
      color: colors[index],
      value: values[index],
    }
  ));

  return (
    <List {...rest}>
      {
        legendItems && legendItems.map(item => (
          item.value !== 0 && (
            <ListItem
              display="flex"
              marginBottom={2}
              key={item?.label}
              alignItems="center"
            >
              <Box
                as="div"
                width={4}
                height={4}
                marginRight={2}
                borderRadius={100}
                backgroundColor={item?.color}
              />

              <Text
                whiteSpace="nowrap"
                fontSize="xxs"
              >
                {`${item?.label} - ${item.value}`}
              </Text>
            </ListItem>
          )))
      }
    </List>
  );
};

export default ChartLegend;
