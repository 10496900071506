import React from "react";
import { Redirect } from "react-router-dom";

import { useCurrentUser } from "contexts/currentUser";
import { DASHBOARD_PAGE_PATH, LOGIN_PAGE_PATH } from "routes";

const Home: React.FC = () => {
  const [currentUser,
    {
      loading: loadingCurrentUser,
    },
  ] = useCurrentUser();

  if (loadingCurrentUser) {
    return null;
  }

  if (currentUser) {
    return <Redirect to={DASHBOARD_PAGE_PATH} />;
  }

  return <Redirect to={LOGIN_PAGE_PATH} />;
};

export default Home;
