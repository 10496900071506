import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import Button from "components/Button";
import { FUNDER_PROFILE_PAGE_LOCATION } from "routes/locations";

import DefaultCell from "./DefaultCell";
import { FunderCellProps } from "./types";

const FunderCell: React.FC<FunderCellProps> = ({
  value,
  row,
}) => {
  const history = useHistory();

  const handleClick = useCallback((funderId) => () => {
    history.push(FUNDER_PROFILE_PAGE_LOCATION.toUrl({
      id: funderId,
    }));
  }, [history]);

  return (
    <DefaultCell
      value={value}
      color="primary.100"
    >
      <Button
        width="full"
        height="90%"
        position="absolute"
        backgroundColor="transparent"
        padding={0}
        onClick={handleClick(row?.funderId)}
      />
    </DefaultCell>

  );
};
export default FunderCell;
