import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/icon";

const UserNameIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} viewBox="0 0 512 512">
    <g>
      <path clipRule="evenodd" d="m10.007 188.082h492.015c5.499 0 9.978 4.507 9.978 10.006v259.994l-256 19.417-256-19.417c0-86.655 0-173.339 0-259.994 0-5.499 4.507-10.006 10.007-10.006z" fill="#e1f1ff" fillRule="evenodd" />
      <path clipRule="evenodd" d="m76.82 313.43h64.035c18.879 0 34.328 15.449 34.328 34.327v64.602c-.851 4.904-4.734 8.76-9.61 9.639h-113.442c-4.904-.879-8.76-4.734-9.609-9.639v-64.602c-.001-18.878 15.419-34.327 34.298-34.327z" fill="#a5cdff" fillRule="evenodd" />
      <path clipRule="evenodd" d="m115.882 313.43h24.974c18.879 0 34.328 15.449 34.328 34.327v64.602c-.851 4.904-4.734 8.76-9.61 9.639h-25.001c4.876-.879 8.759-4.734 9.609-9.639v-64.602c0-18.878-15.449-34.327-34.3-34.327z" fill="#8abfff" fillRule="evenodd" />
      <path d="m140.487 421.997v-61.03c0-3.798 3.09-6.86 6.889-6.86 3.798 0 6.888 3.062 6.888 6.86v61.03zm-77.047-61.03c0-3.798 3.09-6.86 6.889-6.86 3.798 0 6.888 3.062 6.888 6.86v61.03h-13.777z" fill="#71aaf0" />
      <g clipRule="evenodd" fillRule="evenodd">
        <path d="m10.007 496.917h492.015c5.499 0 9.978-4.508 9.978-10.007 0-9.609 0-19.219 0-28.828h-512v28.828c0 5.499 4.507 10.007 10.007 10.007z" fill="#477b9e" />
        <path d="m134.393 15.083h59.244c6.974 0 12.643 3.346 15.959 9.609l86.911 163.39-42.098-8.979-38.887 8.979-86.912-163.39c-2.524-4.733.114-9.609 5.783-9.609z" fill="#eb616f" />
        <path d="m377.636 15.083h-59.244c-7.002 0-12.671 3.346-15.987 9.609l-86.883 163.39 45.231-9.613 35.755 9.613 86.884-163.39c2.522-4.733-.115-9.609-5.756-9.609z" fill="#ff6978" />
        <path d="m324.826 188.082v-43.512c0-5.499-4.507-10.007-9.978-10.007h-117.667c-5.5 0-10.007 4.508-10.007 10.007v43.512z" fill="#ffe5a0" />
        <path d="m241.912 242.819h208.943c8.759 0 15.902 7.143 15.902 15.902 0 8.73-7.144 15.902-15.902 15.902h-208.943c-8.73 0-15.902-7.172-15.902-15.902 0-8.759 7.172-15.902 15.902-15.902z" fill="#477b9e" />
        <path d="m241.912 306.429h208.943c8.759 0 15.902 7.143 15.902 15.902 0 8.73-7.144 15.873-15.902 15.873h-208.943c-8.73 0-15.902-7.143-15.902-15.873 0-8.76 7.172-15.902 15.902-15.902z" fill="#477b9e" />
        <path d="m241.912 370.009h139.21c8.731 0 15.903 7.172 15.903 15.903 0 8.759-7.172 15.902-15.903 15.902h-139.21c-8.73 0-15.902-7.144-15.902-15.902 0-8.731 7.172-15.903 15.902-15.903z" fill="#477b9e" />
        <path d="m145.222 282.306c0 20.013-16.356 36.368-36.37 36.368-20.013 0-36.396-16.355-36.396-36.368v-39.005c0-11.196 9.184-20.353 20.381-20.353h32.031c11.198 0 20.354 9.156 20.354 20.353z" fill="#ffcbbe" />
        <path d="m72.455 243.301v14.173c10.262 3.799 18.822.596 23.754-2.183 3.827-2.183 4.479-2.607 8.277.709 17.461 15.308 40.735 6.804 40.735 6.804v-19.503c0-11.196-9.156-20.353-20.354-20.353h-32.031c-11.197 0-20.381 9.157-20.381 20.353z" fill="#d09080" />
        <path d="m120.219 264.05v18.256c0 15.619-9.979 29.026-23.868 34.157 3.912 1.417 8.107 2.211 12.501 2.211 20.014 0 36.37-16.355 36.37-36.368v-19.502s-11.85 4.336-25.003 1.246z" fill="#f7beaf" />
        <path d="m99.865 222.948c11.197 0 20.354 9.156 20.354 20.353v20.749c13.153 3.09 25.003-1.246 25.003-1.246v-19.503c0-11.196-9.156-20.353-20.354-20.353z" fill="#be694f" />
        <path d="m477.021 433.08h-477.021v25.002h512v-259.994c0-5.499-4.479-10.006-9.978-10.006h-15.024v234.992c0 5.499-4.478 10.006-9.977 10.006z" fill="#cbe2ff" />
        <path d="m299.825 144.57v37.247 6.265h25.001v-43.512c0-2.239-.737-4.309-1.984-5.981-1.843-2.438-4.762-4.025-7.993-4.025h-25.003c5.499 0 9.979 4.507 9.979 10.006z" fill="#f2ce7e" />
        <path d="m358.389 24.692-58.423 109.871h14.883c3.232 0 6.15 1.588 7.993 4.025l60.549-113.896c2.523-4.733-.113-9.609-5.755-9.609h-25.002c5.64 0 8.277 4.876 5.755 9.609z" fill="#eb616f" />
      </g>
    </g>
  </Icon>
);

export default UserNameIcon;
