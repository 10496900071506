import React, { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

import FormWizard from "components/FormWizard";
import {
  useCreateProgramMutation,
  useGetImpersonatedOrganizationQuery,
  useGetOrganizationQuery,
} from "generated/graphql";
import { useToast } from "contexts/toast";
import useWizardStore from "components/Wizard/store";
import createProgramSchema, { CreateProgramSchema } from "settings/yup/schemas/createProgramSchema";
import { getFloat } from "utils/getFloat";
import capitalizeStringFirstLetter from "utils/capitalizeStringFirstLetter";
import useGetOrganization from "hooks/useGetOrganization";
import buildRefetchQueries from "hooks/useGetOrganization/buildRefetchQueries";
import getImpersonatedOrganizationCurrentYearGoalQuery from "graphql/queries/getImpersonatedOrganizationCurrentYearGoalQuery";
import { CreateProgramRefetchQueries } from "graphql/mutations/createProgramMutation";

import { CreateProgramFormProps } from "./types";
import createProgramSteps from "./steps";

const CreateProgramForm: React.FC<CreateProgramFormProps> = ({
  hideModal,
}) => {
  const { addToast } = useToast();

  const [t] = useTranslation();

  const { setStepIndex } = useWizardStore((store) => ({
    setStepIndex: store.setStepIndex,
  }));

  const form = useForm<CreateProgramSchema>({
    resolver: yupResolver(createProgramSchema),
    mode: "onBlur",
    defaultValues: {
      name: undefined,
      yearlyGoal: undefined,
    },
    shouldUnregister: false,
  });

  const [organization] = useGetOrganization(
    useGetOrganizationQuery,
    useGetImpersonatedOrganizationQuery,
  );

  const refetchQueries = buildRefetchQueries({
    refetchCurrentOrganizationQueries: CreateProgramRefetchQueries,
    refetchImpersonatedOrganizationQueries: [{
      query: getImpersonatedOrganizationCurrentYearGoalQuery,
      variables: {
        organizationId: organization?.id,
      },
    }],
  });

  const [
    createProgram,
    {
      loading,
    },
  ] = useCreateProgramMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const onSubmit = useCallback((values: CreateProgramSchema) => {
    if (!organization?.id) {
      addToast({
        type: "error",
        title: t("errors.the_organization_id"),
      });
      return;
    }

    createProgram({
      variables: {
        params: {
          name: values?.name,
          yearlyGoal: getFloat(values?.yearlyGoal),
          organizationId: organization?.id,
        },
      },
    })
      .then(() => {
        addToast({
          title: t("create_program.success"),
          type: "success",
        });

        hideModal();

        setStepIndex(0);
      })
      .catch((error) => {
        addToast({
          title: capitalizeStringFirstLetter(error.message),
          type: "error",
        });
      });
  }, [
    t,
    addToast,
    hideModal,
    organization,
    setStepIndex,
    createProgram,
  ]);

  return (
    <FormProvider {...form}>
      <FormWizard
        isLoading={loading}
        steps={createProgramSteps}
        onSubmit={form.handleSubmit(onSubmit)}
      />
    </FormProvider>
  );
};

export default CreateProgramForm;
