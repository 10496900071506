/**
 * Receives a string and returns it capitalized
 * if it receives anything than a string
 * returns undefined.
 * @param status status string
 */
const formatStatus = (status: string | undefined): string | undefined => {
  if (typeof status !== "string") return undefined;

  return status
    .charAt(0)
    .toUpperCase() + status.toLowerCase().slice(1);
};

export default formatStatus;
