import React, { useCallback, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

import FormWizard from "components/FormWizard";
import createGrantSchema, { CreateGrantSchema } from "settings/yup/schemas/createGrantSchema";
import { ApplicationStatusEnum, CreateGrantParams, useCreateGrantMutation } from "generated/graphql";
import { createFunderRefetchQueries } from "graphql/mutations/createFunderMutation";
import { useToast } from "contexts/toast";
import useWizardStore from "components/Wizard/store";
import { getFloat } from "utils/getFloat";
import { formatDate, yearMonthDay } from "utils/dateFormats";
import { IsSubmittedEnum } from "components/EditReportForm/types";
import useNavigate from "hooks/useNavigate";
import getFunderQuery from "graphql/queries/getFunderQuery";
import capitalizeStringFirstLetter from "utils/capitalizeStringFirstLetter";

import { CreateGrantFormProps } from "./types";
import createGrantSteps from "./steps";

const CreateGrantForm: React.FC<CreateGrantFormProps> = ({
  hideModal,
  componentProps,
}) => {
  const { addToast } = useToast();
  const navigate = useNavigate();

  const [t] = useTranslation();

  const { setStepIndex } = useWizardStore((store) => ({
    setStepIndex: store.setStepIndex,
  }));

  const {
    redirectPath,
    selectedFunder,
  } = componentProps || {};

  const funder = useMemo((): Record<string, unknown> | undefined => (
    selectedFunder
      ? {
        label: selectedFunder?.name,
        value: selectedFunder?.id,
      }
      : undefined
  ),
  [selectedFunder]);

  const form = useForm<CreateGrantSchema>({
    resolver: yupResolver(createGrantSchema),
    mode: "onChange",
    defaultValues: {
      funder,
      program: undefined,
      frequency: undefined,
      applicationType: undefined,
      applicationDeadline: undefined,
      reportType: undefined,
      reportDeadline: undefined,
      isSubmitted: undefined,
      dateSubmitted: undefined,
      amountRequested: "",
      deadline: undefined,
      status: undefined,
      receivementDate: undefined,
      amountAwarded: "",
    },
    shouldUnregister: false,
  });

  const [
    createGrant,
    {
      loading,
    },
  ] = useCreateGrantMutation({
    awaitRefetchQueries: true,
  });

  const onSubmit = useCallback((values: CreateGrantSchema) => {
    const params: CreateGrantParams = {
      funderId: Number(values?.funder?.value),
      programId: values?.program?.value,
      frequency: values?.frequency,
      application: {
        deadline: formatDate(values?.applicationDeadline, yearMonthDay),
        deadlineType: values?.applicationType?.value,
        declined: values?.status === ApplicationStatusEnum.Declined,
        submission: {
          amountRequested: getFloat(values?.amountRequested),
          submissionDate: formatDate(values?.dateSubmitted, yearMonthDay),
        },
        reports: (
          (values?.reportType && values?.reportDeadline)
            ? [{
              urgency: values?.reportType?.value,
              deadline: formatDate(values?.reportDeadline, yearMonthDay),
            }]
            : undefined
        ),
        award: {
          amountAwarded: getFloat(values?.amountAwarded),
          receivementDate: formatDate(
            values?.receivementDate,
            yearMonthDay,
          ) || undefined,
        },
      },
    };

    if (values?.isSubmitted === IsSubmittedEnum.No) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      params.application.submission = undefined as any;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      params.application.declined = undefined as any;
    }

    if (!values?.amountAwarded) {
      params.application.award = undefined;
    }

    createGrant({
      variables: {
        params,
      },
      refetchQueries: [
        {
          query: getFunderQuery,
          variables: { id: params.funderId },
        },
        ...createFunderRefetchQueries,
      ],
    })
      .then(() => {
        if (redirectPath) {
          navigate(redirectPath)();
        }

        addToast({
          title: t("create_grant.success"),
          type: "success",
        });

        hideModal();

        setStepIndex(0);
      })
      .catch((error) => {
        addToast({
          title: capitalizeStringFirstLetter(error.message),
          type: "error",
        });
      });
  }, [
    t,
    addToast,
    navigate,
    hideModal,
    createGrant,
    setStepIndex,
    redirectPath,
  ]);

  return (
    <FormProvider {...form}>
      <FormWizard
        steps={createGrantSteps}
        isLoading={loading}
        onSubmit={form.handleSubmit(onSubmit)}
      />
    </FormProvider>
  );
};

export default CreateGrantForm;
