import { ReactNode } from "react";

import { GetReportQuery } from "generated/graphql";

export interface EditGrantFormProps {
  buttonLabel: string | ReactNode;
  reportData?: GetReportQuery;
  hideModal: () => void;
}

export enum IsSubmittedEnum {
  Yes = "YES",
  No = "NO"
}
