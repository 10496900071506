import i18n from "translations/i18n";

import { ColumnContent } from "./grantFlowTableTitles";

export enum PreviousAwardsTableTitlesEnum {
  Year = "year",

  Program = "program",

  PreviousAwards = "previousAwards",

  DateSubmitted = "dateSubmitted",

  DateAwarded = "dateAwarded",

  ResponseDays = "responseDays",
}

export const previousAwardsColumns: Record<PreviousAwardsTableTitlesEnum, ColumnContent> = {
  [PreviousAwardsTableTitlesEnum.Year]: {
    label: i18n.t("tables.columns_titles.year"),
    field: PreviousAwardsTableTitlesEnum.Year,
  },
  [PreviousAwardsTableTitlesEnum.Program]: {
    label: i18n.t("tables.columns_titles.program"),
    field: PreviousAwardsTableTitlesEnum.Program,
  },
  [PreviousAwardsTableTitlesEnum.PreviousAwards]: {
    label: i18n.t("tables.columns_titles.previous_awards"),
    field: PreviousAwardsTableTitlesEnum.PreviousAwards,
  },
  [PreviousAwardsTableTitlesEnum.DateSubmitted]: {
    label: i18n.t("tables.columns_titles.date_submitted"),
    field: PreviousAwardsTableTitlesEnum.DateSubmitted,
  },
  [PreviousAwardsTableTitlesEnum.DateAwarded]: {
    label: i18n.t("tables.columns_titles.date_awarded"),
    field: PreviousAwardsTableTitlesEnum.DateAwarded,
  },
  [PreviousAwardsTableTitlesEnum.ResponseDays]: {
    label: i18n.t("tables.columns_titles.response_days"),
    field: PreviousAwardsTableTitlesEnum.ResponseDays,
  },
};

/**
 * Defines previousAwards table columns.
 */
export const previousAwardsFormattedColumns = Object.keys(previousAwardsColumns).map(
  (key) => ({
    label: previousAwardsColumns[key].label as string,
    field: previousAwardsColumns[key].field as string,
  }),
);
