import { Flex, Text } from "@chakra-ui/layout";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import Table from "components/Table";
import Button from "components/Button";
import { useModal } from "contexts/modal";
import CreatePreviousAward from "components/CreatePreviousAward";

import { PreviousAwardsCardProps } from "./types";
import CardContainer from "../CardContainer";

const PreviousAwardsCard: React.FC<PreviousAwardsCardProps> = ({
  tableColumns,
  formattedTableData,
  tableFooter,
  isLoading,
}) => {
  const [t] = useTranslation();

  const [showModal] = useModal();

  const handleOpenModal = useCallback(() => {
    showModal({
      title: t("create_previous_award.title"),
      component: CreatePreviousAward,
      closeOnOverlayClick: false,
    });
  }, [
    t,
    showModal,
  ]);

  return (
    <CardContainer>
      <Table
        tableColumns={tableColumns}
        table={formattedTableData}
        margin={0}
        pageSize={4}
        tableFooter={tableFooter}
        isLoading={isLoading}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          marginBottom={4}
        >
          <Text
            fontFamily="heading"
            fontSize="xs"
          >
            {t("previous_awards_card.previous_awards")}
          </Text>

          <Button
            background="status.success"
            onClick={() => handleOpenModal()}
          >
            <Text>
              {t("previous_awards_card.add_previous_awards")}
            </Text>
          </Button>
        </Flex>
      </Table>
    </CardContainer>
  );
};

export default PreviousAwardsCard;
