import { Flex } from "@chakra-ui/layout";
import React, { useMemo, memo, useEffect } from "react";
import { Option, Picklist } from "react-rainbow-components";

import getYearsInInterval from "utils/getYearsInInterval";
import useFilter from "hooks/useFilter";

import { YearFilterProps } from "./types";

const YearFilter: React.FC<YearFilterProps> = ({
  endDate,
  startDate,
  filter,
}) => {
  const [currentYear, setFilter] = useFilter(filter);

  const options = useMemo(
    () => getYearsInInterval({
      start: startDate,
      end: endDate || new Date(),
    }), [endDate, startDate],
  );

  useEffect(() => {
    if (!currentYear) {
      setFilter(options?.[options?.length - 1]);
    }
  }, [currentYear, options, setFilter]);

  return (
    <Flex maxWidth="125px">
      <Picklist
        value={{ value: currentYear, label: `FY ${currentYear}` }}
        onChange={(o) => setFilter(o?.value)}
      >
        {options?.map((year) => (
          <Option value={year} label={`FY ${year}`} name={year} key={year} />
        ))}
      </Picklist>
    </Flex>
  );
};
export default memo(YearFilter);
