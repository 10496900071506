import React, { useCallback, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import ToastsContainer from "components/ToastsContainer";

import { ToastDurationEnum, ToastProps } from "./types";
import { ToastProvider } from ".";

const ToastContainer: React.FC = ({ children }) => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);

  const addToast = useCallback((
    {
      type,
      title,
      description,
      hideCloseButton,
      toastDuration,
    }: Omit<ToastProps, "id">,
  ) => {
    const id = uuidv4();

    const toast = {
      id,
      type,
      title,
      description,
      hideCloseButton: hideCloseButton || false,
      toastDuration: toastDuration || ToastDurationEnum.LONG,
    };

    setToasts((state) => [...state, toast]);
  }, []);

  const removeToast = useCallback((id: string) => {
    setToasts(
      (state) => state.filter(
        (toast) => toast.id !== id,
      ),
    );
  }, []);

  const value = useMemo(() => (
    { addToast, removeToast }
  ), [
    addToast,
    removeToast,
  ]);

  return (
    <ToastProvider value={value}>
      {children}
      <ToastsContainer toasts={toasts} />
    </ToastProvider>
  );
};

export default ToastContainer;
