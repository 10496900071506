import { Flex } from "@chakra-ui/layout";
import React, { useCallback } from "react";

import { formattedMonthOptions } from "utils/formattedMonthOptions";
import useFilter, { filters } from "hooks/useFilter";
import CaretLeftIcon from "settings/theme/ChakraTheme/icons/CaretLeftIcon";
import CaretRightIcon from "settings/theme/ChakraTheme/icons/CaretRightIcon";
import { MonthsEnum } from "constants/months";
import { PeriodsEnum } from "constants/periods";

import { DateFilterProps } from "./types";
import SwapButton from "./SwapButton";
import MonthSelector from "./MonthSelector";

const DateFilter: React.FC<DateFilterProps> = (
  {
    ...props
  },
) => {
  const [submissionMonth, setSubmissionMonth] = useFilter(filters.submissionMonth);
  const [submissionYear, setSubmissionYear] = useFilter(filters.submissionYear);

  const isSwapDisabled = submissionMonth === PeriodsEnum.All
    || submissionMonth === PeriodsEnum.Year;
  const isYearPrecision = submissionMonth === PeriodsEnum.Year;

  const handleDecrease = useCallback(() => {
    const currentSelectedMonth = submissionMonth;

    const selectedYear = submissionYear;

    const monthOptions = formattedMonthOptions(selectedYear as string);

    let monthIndex = monthOptions.findIndex(
      month => month.value === currentSelectedMonth,
    );

    if (monthIndex === 0 && submissionYear === PeriodsEnum.All) {
      return;
    }

    if (isYearPrecision) {
      setSubmissionYear((Number(submissionYear) - 1).toString());
      return;
    }

    if (monthIndex === 0) {
      setSubmissionYear((Number(submissionYear) - 1).toString());

      monthIndex = monthOptions.length;
    }

    const updatedMonth = monthOptions[monthIndex - 1];

    setSubmissionMonth(MonthsEnum[updatedMonth.label]);
  }, [
    submissionYear,
    submissionMonth,
    isYearPrecision,
    setSubmissionYear,
    setSubmissionMonth,
  ]);

  const handleIncrease = useCallback(() => {
    const currentSelectedMonth = submissionMonth;

    const selectedYear = submissionYear;

    const monthOptions = formattedMonthOptions(selectedYear as string);

    const monthOptionsLength = monthOptions.length;

    let monthIndex = monthOptions.findIndex(
      month => month.value === currentSelectedMonth,
    );

    if (monthIndex === monthOptionsLength - 1 && submissionYear === PeriodsEnum.All) {
      return;
    }

    if (isYearPrecision) {
      setSubmissionYear((Number(submissionYear) + 1).toString());
      return;
    }

    if (monthIndex === monthOptionsLength - 1) {
      setSubmissionYear((Number(submissionYear) + 1).toString());

      monthIndex = 0;

      const updatedMonth = monthOptions[monthIndex];

      setSubmissionMonth(MonthsEnum[updatedMonth.label]);

      return;
    }

    const updatedMonth = monthOptions[monthIndex + 1];

    setSubmissionMonth(MonthsEnum[updatedMonth.label]);
  }, [
    submissionYear,
    submissionMonth,
    isYearPrecision,
    setSubmissionYear,
    setSubmissionMonth,
  ]);

  return (
    <Flex
      backgroundColor="primary.100"
      justifyContent="center"
      {...props}
    >
      <SwapButton
        icon={<CaretLeftIcon />}
        disabled={isSwapDisabled}
        onClick={handleDecrease}
      />

      <MonthSelector />

      <SwapButton
        icon={<CaretRightIcon />}
        disabled={isSwapDisabled}
        onClick={handleIncrease}
      />
    </Flex>
  );
};

export default DateFilter;
