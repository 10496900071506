import React from "react";
import { Flex, Text } from "@chakra-ui/layout";

import CardContainer from "components/Cards/CardContainer";

import { CardProps } from "../types";

const Card: React.FC<CardProps> = ({
  value,
  subtitle,
  icon,
}) => (
  <CardContainer>
    <Flex
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex flexDirection="column">
        <Text
          fontFamily="heading"
          color="primary.100"
          fontSize={{ base: "xs", md: "xl", lg: "xl" }}
        >
          { value }
        </Text>

        <Text
          color="gray.400"
          fontFamily="mono"
          fontSize={{ base: "xxs", md: "xs", lg: "xs" }}
        >
          { subtitle }
        </Text>
      </Flex>

      { icon }
    </Flex>
  </CardContainer>
);

export default Card;
