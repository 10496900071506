import React from "react";
import { ButtonIcon } from "react-rainbow-components";

import { SwapButtonProps } from "./types";

const SwapButton: React.FC<SwapButtonProps> = ({
  ...props
}) => (
  <ButtonIcon
    {...props}
    variant="inverse"
    className="swap-button"
  />
);

export default SwapButton;
