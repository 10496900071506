import i18next from "i18next";

import { MIN_PASSWORD_LENGTH } from "constants/forms";
import yup from "settings/yup";

const signUpSchema = yup.object()
  .shape({
    organizationName: yup
      .string()
      .required(),
    fiscalYearStart: yup
      .date()
      .required(),
    email: yup
      .string()
      .email()
      .required(),
    password: yup
      .string()
      .min(MIN_PASSWORD_LENGTH)
      .required(),
    passwordConfirmation: yup
      .string()
      .oneOf(
        [yup.ref("password")],
        i18next.t("schemas.passwords_must_match"),
      ),
  });

export interface SignUpSchema {
  organizationName: string;
  fiscalYearStart: Date;
  email: string;
  password: string;
  passwordConfirmation: string;
}

export default signUpSchema;
