import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import DeleteModal from "components/Modal/DeleteModal";
import { useModal } from "contexts/modal";
import { useToast } from "contexts/toast";
import capitalizeStringFirstLetter from "utils/capitalizeStringFirstLetter";

import { UseDeleteProps, UseDeleteResult } from "./types";

const useDelete = ({
  mutate,
  entryType,
  modalTitle,
  onSuccess,
  onFinish,
}: UseDeleteProps): UseDeleteResult => {
  const [t] = useTranslation();
  const [showModal, hideModal] = useModal();
  const { addToast } = useToast();

  const handleDelete = useCallback((id: number) => () => {
    mutate({
      variables: {
        id,
      },
    })
      .then(() => {
        addToast({
          title: t("actions.this_entry_has_been_deleted", { entryType }),
          type: "success",
        });

        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error: Record<string, string>) => {
        addToast({
          title: capitalizeStringFirstLetter(error.message),
          type: "error",
        });
      })
      .finally(() => {
        hideModal();

        if (onFinish) {
          onFinish();
        }
      });
  },
  [
    t,
    mutate,
    addToast,
    onFinish,
    onSuccess,
    entryType,
    hideModal,
  ]);

  const deleteEntry = useCallback(({
    entryName,
    id,
  }) => {
    showModal({
      title: modalTitle,
      component: DeleteModal,
      componentProps: {
        entryName,
        onDelete: handleDelete(id),
      },
    });
  }, [
    modalTitle,
    handleDelete,
    showModal,
  ]);

  return deleteEntry;
};

export default useDelete;
