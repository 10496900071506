import React from "react";
import { useTranslation } from "react-i18next";

import TableHeader from "components/Table/TableHeader";
import {
  useListFundersQuery,
  useListImpersonatedOrganizationFundersQuery,
} from "generated/graphql";
import { fundersTableFormattedTitles } from "constants/fundersTableTitles";
import Table from "components/Table";
import formatFundersTableData from "utils/formatFundersTableData";
import useGetOrganization from "hooks/useGetOrganization";

const FundersList: React.FC = () => {
  const [t] = useTranslation();

  const [organization, {
    loading,
  }] = useGetOrganization(
    useListFundersQuery,
    useListImpersonatedOrganizationFundersQuery,
    {
      fetchPolicy: "cache-and-network",
    },
  );

  const table = formatFundersTableData(organization);

  const tableColumns = fundersTableFormattedTitles;

  return (
    <Table
      table={table}
      tableColumns={tableColumns}
      isLoading={loading}
    >
      <TableHeader
        title={t("tables.titles.funders")}
        flexDirection={{ base: "column", md: "row" }}
      />
    </Table>
  );
};

export default FundersList;
