/**
 * Defines element ids for dashboard containers.
 */
const elementIds = {
  summary: "dashboard-summary",
  comparisonByYear: "dashboard-comparison-by-year",
  largestFunders: "dashboard-largest-funders",
  currentYearGoal: "dashboard-current-year-goal",
  fundingByType: "dashboard-funding-by-type",
};

export default elementIds;
