import React, { useCallback } from "react";
import { Flex, Stack } from "@chakra-ui/layout";
import { useTranslation } from "react-i18next";
import { Option, Picklist, RadioGroup } from "react-rainbow-components";
import { Controller, useFormContext } from "react-hook-form";

import { CreateGrantSchema } from "settings/yup/schemas/createGrantSchema";
import getErrorMessage from "utils/getErrorMessage";
import Button from "components/Button";
import { useModal } from "contexts/modal";
import CreateFunderForm from "components/CreateFunderForm";
import {
  CreateFunderMutation,
  useGetOrganizationProgramsQuery,
  useGetImpersonatedOrganizationProgramsQuery,
} from "generated/graphql";
import CreateProgramForm from "components/CreateProgramForm";
import CreateGrantForm from "components/CreateGrantForm";
import useGetOrganization from "hooks/useGetOrganization";

import { frequencyOptions } from "./options";
import formatFunderList from "./formatFundersList";
import formatProgramsList from "./formatProgramsList";

const Funder: React.FC = () => {
  const [t] = useTranslation();

  const [showModal] = useModal();

  const [organization] = useGetOrganization(
    useGetOrganizationProgramsQuery,
    useGetImpersonatedOrganizationProgramsQuery,
    {
      fetchPolicy: "network-only",
    },
  );

  const {
    errors,
    control,
  } = useFormContext<CreateGrantSchema>();

  const fundersOptions = formatFunderList(organization?.funders);

  const programsOptions = formatProgramsList(organization?.programs);

  const openCreateNewGrantModal = useCallback((selectedFunder: CreateFunderMutation["createFunder"]) => {
    showModal({
      title: t("create_grant.title"),
      component: CreateGrantForm,
      closeOnOverlayClick: false,
      componentProps: {
        selectedFunder,
      },
    });
  }, [
    t,
    showModal,
  ]);

  const handleCreateNewFunder = useCallback(() => {
    showModal({
      title: t("create_funder.title"),
      component: CreateFunderForm,
      closeOnOverlayClick: false,
      componentProps: {
        successCallback: openCreateNewGrantModal,
      },
    });
  }, [
    t,
    showModal,
    openCreateNewGrantModal,
  ]);

  const handleCreateNewProgram = useCallback(() => {
    showModal({
      title: t("create_program.title"),
      component: CreateProgramForm,
      closeOnOverlayClick: false,
    });
  }, [
    t,
    showModal,
  ]);

  return (
    <Stack
      as="form"
      spacing={6}
      marginBottom={8}
      width="full"
    >
      <Flex flexDirection="column">
        <Controller
          required
          as={Picklist}
          name="funder"
          control={control}
          labelAlignment="left"
          error={getErrorMessage("funder", errors)}
          label={t("create_grant.steps.funder.funder")}
          placeholder={t("create_grant.steps.funder.select_a_funder")}
        >
          {
            fundersOptions.map((type) => (
              <Option
                name={type?.value}
                value={type?.value}
                label={type?.label}
                key={type?.label}
              />
            ))
          }
        </Controller>

        <Button
          color="primary.100"
          _hover={{ color: "primary.100" }}
          _active={{ color: "primary.100" }}
          _focus={{
            color: "primary.100",
            boxShadow: "none",
          }}
          padding={0}
          margin={0}
          alignSelf="flex-end"
          fontSize="xxs"
          onClick={handleCreateNewFunder}
        >
          {t("buttons.add_new_funder")}
        </Button>
      </Flex>

      <Flex flexDirection="column">
        <Controller
          as={Picklist}
          control={control}
          name="program"
          error={getErrorMessage("program", errors)}
          label={t("create_grant.steps.funder.program_being_funded")}
          placeholder={t("create_grant.steps.funder.select_a_program")}
          labelAlignment="left"
          required
        >
          {
            programsOptions.map((type) => (
              <Option
                name={type.value}
                value={type.value}
                label={type.label}
                key={type.label}
              />
            ))
          }
        </Controller>

        <Button
          color="primary.100"
          _hover={{ color: "primary.100" }}
          _active={{ color: "primary.100" }}
          _focus={{
            color: "primary.100",
            boxShadow: "none",
          }}
          padding={0}
          margin={0}
          alignSelf="flex-end"
          fontSize="xxs"
          onClick={handleCreateNewProgram}
        >
          {t("buttons.add_new_program")}
        </Button>
      </Flex>

      <Controller
        as={RadioGroup}
        control={control}
        name="frequency"
        label={t("create_grant.steps.funder.grant_frequency")}
        error={getErrorMessage("frequency", errors)}
        orientation="horizontal"
        required
        options={frequencyOptions}
      />
    </Stack>
  );
};

export default Funder;
