import { Flex } from "@chakra-ui/layout";
import React, { useCallback, useState } from "react";
import { Option, Picklist } from "react-rainbow-components";
import { PicklistOptionProps } from "react-rainbow-components/components/PicklistOption";

import { FunderTypeEnum } from "generated/graphql";
import useFilter from "hooks/useFilter";
import i18n from "translations/i18n";

import { FunderTypeFilterProps } from "./types";
import options from "./options";

const FunderTypeFilter: React.FC<FunderTypeFilterProps> = ({
  filter,
}) => {
  const [typeFilter, setTypeFilter] = useFilter<FunderTypeEnum | undefined>(filter);

  const [selectedType, setSelectedType] = useState<PicklistOptionProps>({
    value: {
      name: typeFilter,
      value: typeFilter,
      icon: undefined,
      label: (
        typeFilter
          ? i18n.t(`funder_types.${typeFilter}`)
          : i18n.t("funder_types.all_types")
      ),
    },
  });

  const handleChange = useCallback((option) => {
    setTypeFilter(option?.value);

    setSelectedType({ value: option });
  }, [
    setSelectedType,
    setTypeFilter,
  ]);

  return (
    <Flex maxWidth="200px">
      <Picklist
        value={selectedType.value}
        onChange={handleChange}
      >
        {
          options.map((option) => (
            <Option
              value={option.value}
              label={option.label}
              name={option.value}
              key={option.value}
            />
          ))
        }
      </Picklist>
    </Flex>
  );
};
export default FunderTypeFilter;
