import React from "react";
import { Column, TableWithBrowserPagination } from "react-rainbow-components";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@chakra-ui/skeleton";
import { parseISO } from "date-fns";
import { Flex, Stack, Text } from "@chakra-ui/layout";

import formatLargestFundersTable from "utils/formatLargestFundersTable";
import {
  FunderTypeEnum,
  useGetLargestFundersQuery,
  useGetImpersonatedOrganizationLargestFundersQuery,
  useGetOldestAwardDateQuery,
  useGetImpersonatedOrganizationOldestAwardDateQuery,
} from "generated/graphql";
import FunderTypeFilter from "components/Filters/FunderTypeFilter";
import YearFilter from "components/Filters/YearFilter";
import useFilter, { filters } from "hooks/useFilter";
import useYearPeriodFilter from "hooks/useFilter/useYearPeriodFilter";
import { DashboardItemProps } from "views/Dashboard/types";
import { tableStyles } from "components/Table/styles";
import DefaultCell from "components/Table/TableCell/CellValue/DefaultCell";
import useGetOrganization from "hooks/useGetOrganization";
import { formatDate, yearMonthDay } from "utils/dateFormats";
import TableContainer from "components/Table/TableContainer";

import LargestFundersFooter from "./LargestFundersFooter";
import LargestFundersPrintTable from "./LargestFundersPrintTable";

const Component: React.FC<DashboardItemProps> = ({
  oldestAwardReceivementDate,
}) => {
  const [t] = useTranslation();

  const [type] = useFilter<FunderTypeEnum | undefined>(filters.largestFunderType);
  const { startAt, endAt } = useYearPeriodFilter(filters.largestFunderYear);

  const [organization, {
    loading,
  }] = useGetOrganization(
    useGetLargestFundersQuery,
    useGetImpersonatedOrganizationLargestFundersQuery,
    {
      variables: {
        type: type || undefined,
        startAt,
        endAt,
      },
    },
  );

  const table = formatLargestFundersTable(organization);

  return (
    <Skeleton
      as={TableContainer}
      isLoaded={!loading}
      height="full"
      display="flex"
      flexDirection="column"
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginBottom={8}
      >
        <Flex
          alignItems="center"
          width="full"
        >
          <Text
            color="primary.200"
            fontFamily="heading"
            fontSize={{ base: "xs", md: "xl", lg: "xl" }}
            textTransform="uppercase"
          >
            {t("largest_funders.title")}
          </Text>
        </Flex>

        <Stack
          justify="flex-end"
          align="center"
          width="full"
          isInline
        >
          <FunderTypeFilter
            filter={filters.largestFunderType}
          />

          <YearFilter
            startDate={oldestAwardReceivementDate}
            filter={filters.largestFunderYear}
          />
        </Stack>
      </Flex>

      <LargestFundersPrintTable
        table={table}
      />

      <TableWithBrowserPagination
        className="table hide-on-print"
        pageSize={5}
        keyField="id"
        variant="default"
        minColumnWidth="120"
        resizeColumnDisabled
        paginationAlignment="right"
        style={tableStyles}
        data={table?.largestFunders}
      >
        {
          table?.formattedHeaders && table.formattedHeaders.map((header) => (
            <Column
              width="auto"
              key={header?.id + header?.name}
              field={header?.id}
              header={header?.name}
              component={DefaultCell}
            />
          ))
        }
      </TableWithBrowserPagination>

      <LargestFundersFooter overall={organization?.largestFunders?.overall} />
    </Skeleton>
  );
};

const LargestFunders: React.FC = () => {
  const [organization, {
    loading: oldestAwardLoading,
  }] = useGetOrganization(
    useGetOldestAwardDateQuery,
    useGetImpersonatedOrganizationOldestAwardDateQuery,
  );

  const oldestAward = organization?.oldestAward;

  const oldestAwardReceivementDate = parseISO(
    oldestAward?.receivementDate
    ?? formatDate(new Date(), yearMonthDay),
  );

  return (
    <Component
      oldestAwardReceivementDate={oldestAwardReceivementDate}
      oldestAwardLoading={oldestAwardLoading}
    />
  );
};

export default LargestFunders;
