import { FunderTypeEnum } from "generated/graphql";
import i18n from "translations/i18n";

import { Option } from "./types";

const options: Option[] = [
  {
    value: "",
    label: i18n.t("funder_types.all_types"),
  },
  {
    value: FunderTypeEnum.Corporate,
    label: i18n.t(`funder_types.${FunderTypeEnum.Corporate}`),
  },
  {
    value: FunderTypeEnum.Foundation,
    label: i18n.t(`funder_types.${FunderTypeEnum.Foundation}`),
  },
  {
    value: FunderTypeEnum.Government,
    label: i18n.t(`funder_types.${FunderTypeEnum.Government}`),
  },
  {
    value: FunderTypeEnum.Other,
    label: i18n.t(`funder_types.${FunderTypeEnum.Other}`),
  },
];

export default options;
