import { PicklistProps } from "react-rainbow-components/components/Picklist";
import { endOfDay } from "date-fns";
import { ObjectSchema } from "yup";
import { ReactNode } from "react";

import { IsSubmittedEnum } from "components/EditGrantForm/types";
import { ApplicationStatusEnum, GrantFrequencyEnum } from "generated/graphql";
import yup from "settings/yup";
import i18n from "translations/i18n";

import buildCurrencyTest from "../tests/currencyFieldTest";

const createGrantSchema = yup.object().shape({
  funder: yup
    .object()
    .required(),
  program: yup
    .object()
    .required(),
  frequency: yup
    .string()
    .required(),
  applicationType: yup
    .object()
    .required(),
  applicationDeadline: yup
    .date()
    .required(),
  reportType: yup
    .object()
    .when("reportDeadline", (reportDeadline: string, schema: ObjectSchema) => (
      reportDeadline
        ? schema.required()
        : schema.notRequired()
    )),
  reportDeadline: yup
    .date()
    .when("reportType", (reportType: string, schema: ObjectSchema) => (
      reportType
        ? schema.required()
        : schema.notRequired()
    )),
  isSubmitted: yup
    .string(),
  dateSubmitted: yup
    .date()
    .when("isSubmitted", {
      is: (value) => value === IsSubmittedEnum.Yes,
      then: yup
        .date()
        .max(
          endOfDay(new Date()).toDateString(),
          i18n.t("yup.string_max"),
        )
        .required(),
      otherwise: yup
        .date(),
    }),
  amountRequested: yup
    .string()
    .when("isSubmitted", {
      is: (value) => value === IsSubmittedEnum.Yes,
      then: yup
        .string()
        .test(buildCurrencyTest())
        .required(),
      otherwise: yup
        .string(),
    }),
  status: yup
    .string(),
  receivementDate: yup
    .date()
    .when("status", {
      is: (value) => value === ApplicationStatusEnum.Awarded,
      then: yup
        .date()
        .max(
          endOfDay(new Date()).toDateString(),
          i18n.t("yup.string_max"),
        )
        .required(),
      otherwise: yup
        .date(),
    }),
  amountAwarded: yup
    .string()
    .when("status", {
      is: (value) => value === ApplicationStatusEnum.Awarded,
      then: yup
        .string()
        .test(buildCurrencyTest())
        .required(),
      otherwise: yup
        .string(),
    }),
}, [["reportType", "reportDeadline"]]);

export interface PicklistFunderProps {
  label?: string;
  name?: string | number;
  icon?: ReactNode;
  value?: unknown;
}
export interface CreateGrantSchema {
  funder: PicklistFunderProps;
  program: PicklistProps["value"];
  frequency: GrantFrequencyEnum;
  applicationType: PicklistProps["value"];
  applicationDeadline: Date;
  reportType: PicklistProps["value"];
  reportDeadline: Date;
  isSubmitted?: IsSubmittedEnum;
  dateSubmitted: Date;
  amountRequested: string;
  deadline: Date;
  status: string;
  receivementDate?: Date;
  amountAwarded: string;
}

export default createGrantSchema;
