import { Flex, Stack } from "@chakra-ui/layout";
import { yupResolver } from "@hookform/resolvers/yup";
import React, {
  useCallback, useEffect, useMemo, useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  CheckboxToggle,
  DatePicker,
  Picklist,
  PicklistOption,
} from "react-rainbow-components";
import { getYear } from "date-fns";

import MaskedInput from "components/FormComponents/MaskedInput";
import createPreviousAwardSchema,
{
  CreatePreviousAwardSchema,
  previousAwardDefaultValues,
} from "settings/yup/schemas/createPreviousAwardSchema";
import MoneyBagIcon from "settings/theme/ChakraTheme/icons/MoneyBagIcon";
import { buildCurrencyMask, YEAR_MASK } from "constants/masks";
import { useCreatePreviousAwardMutation, useGetOrganizationProgramsQuery } from "generated/graphql";
import formatProgramsList from "components/CreateGrantForm/Steps/Funder/formatProgramsList";
import getErrorMessage from "utils/getErrorMessage";
import Button from "components/Button";
import { FUNDER_PROFILE_PAGE_LOCATION } from "routes/locations";
import { getFloat } from "utils/getFloat";
import { formatDate, yearMonthDay } from "utils/dateFormats";
import { useToast } from "contexts/toast";
import { createPreviousAwardRefetchQueries } from "graphql/mutations/createPreviousAward";
import capitalizeStringFirstLetter from "utils/capitalizeStringFirstLetter";

import { CreatePreviousAwardProps } from "./types";

const CreatePreviousAward: React.FC<CreatePreviousAwardProps> = ({
  hideModal,
}) => {
  const [t] = useTranslation();
  const { addToast } = useToast();

  const [isAwardedDateKnown, setIsAwardedDateKnown] = useState(
    previousAwardDefaultValues.isAwardedDateKnown,
  );

  const { data } = useGetOrganizationProgramsQuery({
    fetchPolicy: "network-only",
  });

  const programsOptions = useMemo(() => formatProgramsList(
    data?.currentUser?.organization?.programs,
  ),
  [
    data,
  ]);

  const funderId = FUNDER_PROFILE_PAGE_LOCATION.parseLocationParams(window.location)?.id as number;

  const [
    createPreviousAward, {
      loading,
    },
  ] = useCreatePreviousAwardMutation({
    refetchQueries: createPreviousAwardRefetchQueries,
    awaitRefetchQueries: true,
  });

  const {
    control,
    handleSubmit,
    errors,
    setValue,
    formState,
    clearErrors,
    trigger,
    watch,
    getValues,
  } = useForm<CreatePreviousAwardSchema>({
    resolver: yupResolver(createPreviousAwardSchema),
    mode: "all",
    defaultValues: createPreviousAwardSchema.cast(),
  });

  const handleToggleCheckBox = useCallback(() => {
    setIsAwardedDateKnown(!isAwardedDateKnown);
  }, [
    isAwardedDateKnown,
  ]);

  useEffect(() => {
    setValue("isAwardedDateKnown", isAwardedDateKnown);
    setValue("awardedDate", previousAwardDefaultValues.awardedDate);
    setValue("awardedYear", previousAwardDefaultValues.awardedYear);

    clearErrors([
      "awardedDate",
      "awardedYear",
    ]);
  }, [
    setValue,
    isAwardedDateKnown,
    clearErrors,
  ]);

  const submissionDate = watch("submissionDate");

  useEffect(() => {
    if (getValues("submissionDate")) {
      if (getValues("awardedYear")) {
        trigger("awardedYear");
      }

      if (getValues("awardedDate")) {
        trigger("awardedDate");
      }
    }
  }, [
    trigger,
    getValues,
    submissionDate,
  ]);

  const onSubmit = useCallback((values: CreatePreviousAwardSchema) => {
    const params = {
      amountAwarded: getFloat(values?.amountAwarded),
      funderId,
      programId: values?.program?.value as number,
      submissionDate: values?.submissionDate
        ? formatDate(values?.submissionDate, yearMonthDay)
        : undefined,
      awardedDate: isAwardedDateKnown ? formatDate(values?.awardedDate, yearMonthDay) : undefined,
      awardedYear: isAwardedDateKnown ? undefined : Number(values?.awardedYear),
    };

    createPreviousAward({
      variables: params,
    })
      .then(() => {
        addToast({
          title: t("create_previous_award.success"),
          type: "success",
        });

        hideModal();
      })
      .catch(error => {
        addToast({
          title: capitalizeStringFirstLetter(error.message),
          type: "error",
        });
      });
  }, [
    t,
    addToast,
    funderId,
    hideModal,
    isAwardedDateKnown,
    createPreviousAward,
  ]);

  return (
    <Stack
      as="form"
      spacing={6}
      padding={4}
    >
      <Controller
        as={MaskedInput}
        control={control}
        name="amountAwarded"
        errors={errors}
        isRequired
        mask={buildCurrencyMask()}
        rightElement={<MoneyBagIcon />}
        title={t("create_previous_award.amount_awarded")}
      />

      <Controller
        as={Picklist}
        control={control}
        name="program"
        label={t("create_previous_award.program")}
        required
        labelAlignment="left"
        error={getErrorMessage("program", errors)}
      >
        {
          programsOptions.map(program => (
            <PicklistOption
              value={program?.value}
              key={program?.value}
              name={program?.label}
              label={program?.label}
            />
          ))
        }
      </Controller>

      <Controller
        as={DatePicker}
        control={control}
        name="submissionDate"
        label={t("create_previous_award.submission_date")}
        labelAlignment="left"
        error={getErrorMessage("submissionDate", errors)}
      />

      <Flex justifyContent="center">
        <Controller
          as={CheckboxToggle}
          control={control}
          label={t("create_previous_award.do_you_know_the_awarded_date")}
          name="isAwardedDateKnown"
          onClick={handleToggleCheckBox}
        />
      </Flex>

      <Controller
        as={DatePicker}
        control={control}
        name="awardedDate"
        label={t("create_previous_award.awarded_date")}
        required={isAwardedDateKnown}
        labelAlignment="left"
        error={getErrorMessage("awardedDate", errors)}
        disabled={!isAwardedDateKnown}
      />

      <Controller
        as={MaskedInput}
        control={control}
        name="awardedYear"
        title={t("create_previous_award.awarded_year")}
        isRequired={!isAwardedDateKnown}
        errors={errors}
        mask={YEAR_MASK}
        isDisabled={isAwardedDateKnown}
        placeholder={`e.g.: ${getYear(new Date())}`}
      />

      <Button
        background="primary.100"
        onClick={handleSubmit(onSubmit)}
        disabled={!formState.isValid}
        isLoading={loading}
      >
        {t("buttons.submit")}
      </Button>
    </Stack>
  );
};

export default CreatePreviousAward;
