import React, { useCallback } from "react";
import { Column, TableWithBrowserPagination as RainbowTable } from "react-rainbow-components";
import { Flex } from "@chakra-ui/layout";

import { TableTitlesEnum } from "constants/grantFlowTableTitles";

import { tableStyles } from "./styles";
import TableContainer from "./TableContainer";
import { TableProps } from "./types";
import DefaultCell from "./TableCell/CellValue/DefaultCell";
import { DefaultCellProps, UpdateButtonCellProps } from "./TableCell/CellValue/types";

const Table: React.FC<TableProps> = ({
  tableColumns,
  table,
  isLoading = false,
  children,
  pageSize = 8,
  tableFooter,
  ...props
}) => {
  const statusColumnCustomWidth = useCallback(
    (field: string) => (
      field === TableTitlesEnum.Status
        ? "180"
        : "auto"
    ), [],
  );

  return (
    <TableContainer
      justifyContent="space-between"
      height="full"
      {...props}
    >
      <Flex
        flexDirection="column"
      >
        {children}

        <RainbowTable
          className="table"
          data={table}
          keyField="id"
          variant="default"
          resizeColumnDisabled
          minColumnWidth="120"
          isLoading={isLoading}
          pageSize={pageSize}
          style={tableStyles}
        >
          {
            tableColumns.map(column => {
              const Component = column.component ?? DefaultCell;
              const componentProps = column.componentProps ?? {};

              return (
                <Column
                  key={column.field}
                  header={column.label}
                  field={column.field}
                  width={statusColumnCustomWidth(column.field)}
                  component={({ value, row }: DefaultCellProps | UpdateButtonCellProps) => (
                    <Component
                      value={value}
                      row={row}
                      {...componentProps}
                    />
                  )}
                />
              );
            })
          }
        </RainbowTable>
      </Flex>

      <Flex alignSelf="baseline">
        {tableFooter}
      </Flex>
    </TableContainer>
  );
};

export default Table;
