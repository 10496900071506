import { getYear, eachYearOfInterval } from "date-fns";

/**
 * Receives two dates and returns the years in this range
 * @param start first date of the range
 * @param end last date of the range
 */
const getYearsInInterval = (interval: Interval): string[] | undefined => {
  if (!interval) {
    return undefined;
  }

  const yearsInterval = eachYearOfInterval(interval);

  const result = yearsInterval.map(year => getYear(year).toString());

  result.reverse();

  return result;
};

export default getYearsInInterval;
