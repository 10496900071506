import React, { useCallback } from "react";
import { Stack } from "@chakra-ui/layout";
import { useTranslation } from "react-i18next";

// import BoardReportModal from "components/Modal/BoardReportModal";
import { useModal } from "contexts/modal";
import Button from "components/Button";
import TableHeader from "components/Table/TableHeader";
import CreateGrantForm from "components/CreateGrantForm";
import { GRANT_FLOW_PAGE_PATH } from "routes";

import { hideOnPrintCss } from "../styles";
import YearFilter from "./YearFilter";

interface DashboardHeaderProps {
  oldestAwardLoading: boolean;
  oldestAwardReceivementDate: Date;
  downloadPDF: () => void;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  oldestAwardReceivementDate,
  oldestAwardLoading,
  downloadPDF,
}) => {
  const [showModal] = useModal();
  const [t] = useTranslation();

  // const openBoardReport = useCallback(() => {
  //   showModal({
  //     title: t("board_report.title"),
  //     component: BoardReportModal,
  //   });
  // }, [
  //   showModal,
  //   t,
  // ]);

  const openCreateGrantModal = useCallback(() => {
    showModal({
      title: t("create_grant.title"),
      component: CreateGrantForm,
      componentProps: {
        redirectPath: GRANT_FLOW_PAGE_PATH,
      },
      closeOnOverlayClick: false,
    });
  }, [
    t,
    showModal,
  ]);

  return (
    <TableHeader title={t("dashboard.title")} pt={8} px={8} mb={0}>
      <Stack
        spacing={6}
        direction={{ base: "column", sm: "row" }}
      >
        {!oldestAwardLoading && (
          <YearFilter
            startDate={oldestAwardReceivementDate}
          />
        )}
        <Button
          background="status.success"
          onClick={openCreateGrantModal}
          fontSize={{ base: "xxs", md: "xs", lg: "xs" }}
          css={hideOnPrintCss}
        >
          {t("buttons.add_new_grant")}
        </Button>

        <Button
          background="primary.100"
          fontSize={{ base: "xxs", md: "xs", lg: "xs" }}
          onClick={downloadPDF}
          css={hideOnPrintCss}
        >
          {t("board_report.title")}
        </Button>
      </Stack>
    </TableHeader>
  );
};

export default DashboardHeader;
