import { GetFunderQuery } from "generated/graphql";

import formatCurrency from "./formatCurrency";

const getTotalAward = (funderData: GetFunderQuery | undefined): string | undefined => {
  if (!funderData) {
    return undefined;
  }

  const awardsList = funderData?.getFunder?.awards.map(award => award.amountAwarded);

  const totalAward = awardsList?.reduce(
    (previousValue, currentValue) => Number(previousValue) + Number(currentValue),
    0,
  );

  const formattedValue = formatCurrency(totalAward);

  return formattedValue;
};

export default getTotalAward;
