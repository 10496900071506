import React from "react";
import { Stack, Text } from "@chakra-ui/layout";

import { TableHeaderProps } from "./types";

const TableHeader: React.FC<TableHeaderProps> = ({
  title,
  children,
  ...rest
}) => (
  <Stack
    width="full"
    display="flex"
    flexDirection={{ base: "column", sm: "row" }}
    justifyContent="space-between"
    alignItems="center"
    marginBottom={8}
    {...rest}
  >
    <Text
      color="primary.200"
      fontFamily="heading"
      fontSize={{ base: "xs", md: "xl", lg: "xl" }}
      textTransform="uppercase"
    >
      {title}
    </Text>

    {children}
  </Stack>
);

export default TableHeader;
