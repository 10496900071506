import { WizardStep } from "components/Wizard/types";
import i18n from "translations/i18n";

import Personal from "./Steps/Personal";

export enum CreateFunderStep {
  PersonalStep,
}

const createFunderSteps: WizardStep[] = [
  {
    id: CreateFunderStep.PersonalStep,
    component: Personal,
    title: i18n.t("create_funder.steps.personal.title"),
  },
];

export default createFunderSteps;
