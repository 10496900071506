import React, { useCallback, useEffect, useMemo } from "react";
import {
  Flex, Grid, HStack, Stack, Text,
} from "@chakra-ui/layout";
import { useTranslation } from "react-i18next";
import { usePreviousDistinct } from "react-use";

import BackButton from "components/BackButton";
import { FUNDER_PROFILE_PAGE_LOCATION } from "routes/locations";
import {
  useGetFunderQuery,
  useUpdateFunderMutation,
  useDeleteFunderMutation,
} from "generated/graphql";
import ContactInformationCard from "components/Cards/ContactInformationCard";
import NotesCard from "components/Cards/NotesCard";
import { previousAwardsFormattedColumns } from "constants/previousAwardsTableTitles";
import formatPreviousAwardsTableData from "utils/formatPreviousAwardsTableData";
import getTotalAward from "utils/getTotalAward";
import TableFooter from "components/Table/TableFooter";
import PreviousAwardsCard from "components/Cards/PreviousAwardsCard";
import { useModal } from "contexts/modal";
import PauseNegotiationModal from "components/Modal/PauseNegotiationModal";
import Button from "components/Button";
import { useToast } from "contexts/toast";
import HowToApplyCard from "components/Cards/HowToApplyCard";
import { FUNDERS_LIST_PAGE_PATH } from "routes";
import capitalizeStringFirstLetter from "utils/capitalizeStringFirstLetter";
import { DeleteFunderMutationRefetchQueries } from "graphql/mutations/deleteFunderMutation";
import useDelete from "hooks/useDelete";
import useNavigate from "hooks/useNavigate";
import useAdminImpersonationStore from "components/AdminImpersonation/store";

const FunderProfile: React.FC = () => {
  const [t] = useTranslation();

  const [showModal, hideModal] = useModal();

  const navigate = useNavigate();

  const [updateFunder, { loading: funderLoading }] = useUpdateFunderMutation();
  const [deleteFunder, { loading: deleteFunderLoading }] = useDeleteFunderMutation({
    awaitRefetchQueries: true,
    refetchQueries: DeleteFunderMutationRefetchQueries,
  });

  const deleteEntry = useDelete({
    entryType: t("entry_type.funder"),
    modalTitle: t("modals.delete.title.delete_funder"),
    mutate: deleteFunder,
    onSuccess: navigate(FUNDERS_LIST_PAGE_PATH),
  });

  const { addToast } = useToast();

  const funderId = FUNDER_PROFILE_PAGE_LOCATION.parseLocationParams(window.location)?.id as number;

  const {
    data: funderData,
    loading,
  } = useGetFunderQuery({
    variables: {
      id: funderId,
    },
    fetchPolicy: "cache-and-network",
  });

  const tableColumns = previousAwardsFormattedColumns;

  const formattedTableData = useMemo(() => formatPreviousAwardsTableData(funderData), [funderData]);

  const totalAward = useMemo(() => (
    getTotalAward(funderData) ?? t("actions.nothing_here_yet")
  ),
  [
    t,
    funderData,
  ]);

  const handlePauseNegotiation = useCallback(() => {
    if (!funderData) {
      return;
    }

    showModal({
      title: t("actions.pause_negotiation"),
      component: PauseNegotiationModal,
      componentProps: {
        funderData,
        onPause: () => {
          updateFunder({
            variables: {
              id: funderId,
              params: {
                isPaused: true,
              },
            },
          })
            .then(() => {
              addToast({
                title: t("actions.this_negotiation_has_been_paused"),
                type: "success",
              });

              hideModal();
            })
            .catch((error) => {
              addToast({
                type: "error",
                title: capitalizeStringFirstLetter(error.message),
              });
            });
        },
      },
    });
  }, [
    t,
    funderId,
    addToast,
    hideModal,
    showModal,
    funderData,
    updateFunder,
  ]);

  const handleResumeNegotiation = useCallback(() => {
    if (!funderData) {
      return;
    }

    updateFunder({
      variables: {
        id: funderId,
        params: {
          isPaused: false,
        },
      },
    })
      .then(() => {
        addToast({
          title: t("actions.this_negotiation_has_been_resumed"),
          type: "success",
        });
      })
      .catch((error) => {
        addToast({
          type: "error",
          title: capitalizeStringFirstLetter(error.message),
        });
      });
  }, [
    t,
    addToast,
    funderId,
    funderData,
    updateFunder,
  ]);

  const handleDelete = useCallback(() => {
    if (!funderData) {
      return;
    }

    deleteEntry({
      entryName: funderData.getFunder?.name,
      id: Number(funderData?.getFunder?.id),
    });
  }, [
    deleteEntry,
    funderData,
  ]);

  const impersonatedOrganizationId = useAdminImpersonationStore(
    store => store.organizationId,
  );

  const prevOrganizationId = usePreviousDistinct(impersonatedOrganizationId);

  useEffect(() => {
    if (prevOrganizationId) {
      navigate(FUNDERS_LIST_PAGE_PATH)();
    }
  }, [
    prevOrganizationId,
    navigate,
  ]);

  return (
    <Stack
      spacing={8}
      margin={8}
    >
      <Flex
        width="full"
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex alignItems="center">
          <BackButton />

          <Text
            as="h1"
            marginLeft={4}
            fontFamily="heading"
            fontSize="lg"
            color="primary.200"
            textTransform="capitalize"
          >
            {funderData?.getFunder?.name ?? t("contact_information_card.not_informed")}
          </Text>
        </Flex>

        <HStack spacing={6}>
          {
            funderData?.getFunder?.isPaused && (
              <Button
                backgroundColor="secondary.200"
                isLoading={funderLoading}
                onClick={handleResumeNegotiation}
                textProps={
                  {
                    fontSize: "xs",
                    color: "white",
                    fontFamily: "heading",
                  }
                }
              >
                {t("actions.resume")}
              </Button>
            )
          }

          {
            !funderData?.getFunder?.isPaused && (

              <Button
                isLoading={funderLoading}
                backgroundColor="status.warning"
                onClick={handlePauseNegotiation}
                textProps={
                  {
                    fontSize: "xs",
                    color: "white",
                    fontFamily: "heading",
                  }
                }
              >
                {t("actions.pause_negotiation")}
              </Button>
            )
          }

          <Button
            isLoading={funderLoading || deleteFunderLoading}
            disabled={false}
            backgroundColor="status.error"
            onClick={handleDelete}
            textProps={
              {
                fontSize: "xs",
                color: "white",
                fontFamily: "heading",
              }
            }
          >
            {t("actions.delete_funder")}
          </Button>
        </HStack>
      </Flex>

      <Grid
        gap={6}
        width="full"
        templateColumns="2fr 5fr"
      >
        <ContactInformationCard data={funderData} />

        <PreviousAwardsCard
          tableColumns={tableColumns}
          formattedTableData={formattedTableData}
          isLoading={loading}
          tableFooter={(
            <TableFooter
              title={t("previous_awards_card.total_award")}
              value={totalAward}
            />
          )}
        />
      </Grid>

      <Stack
        spacing={6}
        direction={{ base: "column", lg: "row" }}
      >
        <NotesCard
          funderId={funderData?.getFunder?.id}
          funderNotes={funderData?.getFunder?.notes}
        />

        <HowToApplyCard
          funderId={funderData?.getFunder?.id}
          funderHowToApply={funderData?.getFunder?.howToApply}
        />
      </Stack>
    </Stack>
  );
};

export default FunderProfile;
