import { gql } from "@apollo/client";

export const updateReportRefetchQueries = [
  "ListReports",
];

export default gql`
  mutation UpdateReport (
    $id: Int!, 
    $params: UpdateReportParams!, 
  ) {
    updateReport(
      id: $id, 
      params: $params,
    ) {
      id
    }
  }
`;
