import { ReactNode } from "react";
import { UseFormMethods } from "react-hook-form";
import { StackProps } from "@chakra-ui/layout";

import { GetApplicationQuery } from "generated/graphql";

export interface EditGrantFormProps {
  buttonLabel: string | ReactNode;
  grantData?: GetApplicationQuery;
  hideModal: () => void;
}

export enum IsSubmittedEnum {
  Yes = "YES",
  No = "NO"
}

export interface EditGrantFormFieldsProps extends StackProps {
  control: UseFormMethods["control"];
  errors: UseFormMethods["errors"];
  register: UseFormMethods["register"];
  isSubmitted: boolean;
  isAwarded: boolean;
  shouldShowDeadlineField?: boolean;
}
