/**
 * Character to be used as the placeholder for input mask.
 */
export const maskPlaceholderChar = "\u2000";

/**
 * Clears the input value from mask placeholder characters.
 * @param value The input value.
 */
export const clearInputPlaceholder = (value: string): string => (
  String(value ?? "")
    .split(maskPlaceholderChar)
    .join("")
);
