import { FunderNameAward } from "views/LargestFunders/types";

import formatCurrency from "./formatCurrency";

/**
 * Receives a Funder and returns its formatted name with total award amount
 * @param funder a Funder
 */
const formatFunderName = (funder: FunderNameAward): string | undefined => {
  if (!funder) {
    return undefined;
  }

  const formattedCurrency = formatCurrency(funder.totalAwardAmount, true);

  return (
    formattedCurrency
      ? `${funder.name} (${formattedCurrency})`
      : funder.name
  );
};

export default formatFunderName;
