import { getYear } from "date-fns";
import { AssertingTestOptions } from "yup";

import i18n from "translations/i18n";
import { INITIAL_DATE } from "constants/dates";

/**
 * Applies date range validation in one schema field.
 */
const awardedYearTest = (isRequired = true): AssertingTestOptions<string> => ({
  name: "isValidAwardedYear",
  exclusive: false,
  test (value: string): value is string {
    if (!value && !isRequired) {
      return true;
    }

    const { submissionDate } = this.parent;

    if (submissionDate) {
      const isBetweenCurrentDateAndSubmissionDate = (
        Number(value) >= getYear(submissionDate)
        && Number(value) <= getYear(new Date())
      );

      if (!isBetweenCurrentDateAndSubmissionDate) {
        return this.resolve(this.createError({
          message: i18n.t("errors.awarded_year_should_be_between_submission_date"),
        }));
      }
    }

    const isBetweenCurrentYearAndSubmissionYear = (
      Number(value) >= getYear(new Date(INITIAL_DATE))
      && Number(value) <= getYear(new Date())
    );

    if (!isBetweenCurrentYearAndSubmissionYear) {
      return this.resolve(this.createError({
        message: i18n.t("yup.string_range", {
          min: getYear(new Date(INITIAL_DATE)),
          max: getYear(new Date()),
        }),
      }));
    }

    return true;
  },
});

export default awardedYearTest;
