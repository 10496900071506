import { clearInputPlaceholder } from "components/FormComponents/MaskedInput/maskPlaceholderChar";

/**
 * Validates currency field.
 * @param {*} value The value.
 */
export const isValidCurrency = (value: string): boolean => {
  const parsedValue = clearInputPlaceholder(value ?? "");

  return (
    !!parsedValue.length
  );
};
