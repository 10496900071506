import React from "react";
import { Textarea } from "react-rainbow-components";
import { TextareaProps } from "react-rainbow-components/components/Textarea";

import FieldTitle from "components/FormComponents/MaskedInput/FieldTitle";
import TextareaDisplay from "components/TextareaDisplay";

const TextareaInput: React.FC<TextareaProps> = ({
  readOnly,
  value,
  name,
  label,
  ...props
}) => (
  <>
    {
      readOnly
        ? (
          <>
            <FieldTitle htmlFor={3}>
              {label}
            </FieldTitle>

            <TextareaDisplay text={value} />
          </>
        )
        : (
          <Textarea
            readOnly={readOnly}
            value={value}
            name={name}
            label={label}
            {...props}
          />
        )
    }
  </>
);

export default TextareaInput;
