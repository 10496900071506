import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { HeaderLinkProps } from "./types";

const HeaderLink: React.FC<HeaderLinkProps> = ({
  to,
  title,
  children,
}) => {
  const isSelected = window.location.pathname === to;

  const isSelectedClassName = classNames(
    {
      selected: isSelected,
    },
  );

  return (
    <Link
      to={to}
      className={isSelectedClassName}
    >
      <span>
        {title}
      </span>

      {children}
    </Link>
  );
};

export default HeaderLink;
