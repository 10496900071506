import * as R from "remeda";

import { GetAwardsByYearQuery } from "generated/graphql";

import { getFloat } from "./getFloat";

export interface GetAwardsByYearType {
  labels: string[];
  values: number[];
}

const getAwardsByYear = (value?: GetAwardsByYearQuery["currentUser"]["organization"]["awardsByYear"]): GetAwardsByYearType => {
  if (!value) {
    return {
      labels: [],
      values: [],
    };
  }

  const awardsByYear = value;

  const parsedYears = R.pipe(
    awardsByYear ?? [],
    R.map(award => `FY ${award.year}`),
  );

  const parsedTotals = R.pipe(
    awardsByYear ?? [],
    R.map(award => award.total),
    R.map(total => getFloat(total) as number ?? []),
  );

  return {
    labels: [...parsedYears],
    values: [...parsedTotals],
  };
};

export default getAwardsByYear;
