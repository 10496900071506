import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/icon";

const CaretRightIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} viewBox="0 0 192 512">
    <path fill="currentColor" d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" />
  </Icon>
);

export default CaretRightIcon;
