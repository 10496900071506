import React, { useCallback, useState } from "react";
import { Flex } from "@chakra-ui/layout";
import { useTranslation } from "react-i18next";
import useDebouncedEffect from "use-debounced-effect-hook";
import { Input } from "react-rainbow-components";

import useFilter, { filters } from "hooks/useFilter";
import LoupeIcon from "settings/theme/ChakraTheme/icons/LoupeIcon";

import { SearchFilterParamProps, SearchFilterProps } from "./types";

const SearchFilter: React.FC<SearchFilterProps> = (
  {
    isDisabled = false,
    ...props
  },
) => {
  const [t] = useTranslation();

  const [, setSearch]: SearchFilterParamProps<string> = useFilter(filters.search);

  const [searchInput, setSearchInput] = useState<string | undefined>();

  const handleSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.currentTarget.value;

    setSearchInput(searchText || undefined);
  }, [setSearchInput]);

  useDebouncedEffect(() => {
    setSearch(searchInput);
  }, [
    searchInput,
  ], 500);

  return (
    <Flex {...props}>
      <Input
        disabled={isDisabled}
        iconPosition="right"
        onChange={handleSearch}
        value={searchInput ?? ""}
        placeholder={t("filters.search_filter.placeholder")}
        icon={<LoupeIcon color="gray" />}
        type="text"
        className="search-filter-container"
      />
    </Flex>
  );
};

export default SearchFilter;
