export const isPresent = (url?: string | null): boolean => (
  !!url && String(url).trim().length > 0
);

const hasProtocol = (url: string): boolean => (
  url.includes("http://")
  || url.includes("https://")
);

/**
 * Formats a given url, to ensure it have the protocol defined.
 *
 * Examples:
 *  google.com -> http://google.com
 *  https://www.google.com -> https://www.google.com
 *
 * @param url A url to be formatted
 */
const formatUrl = (url?: string): string | undefined => {
  if (isPresent(url) && !hasProtocol(url as string)) {
    return "http://".concat(url as string);
  }

  return url;
};

export default formatUrl;
