import {
  startOfMonth,
  endOfMonth,
  parseISO,
  isValid,
  format,
} from "date-fns";

import { PeriodsEnum } from "constants/periods";
import getDateFnsLocale from "translations/dateFns";
import { MonthsEnum } from "constants/months";

import captureSentryException from "./captureSentryException";

/**
 * Time mask.
 * E.g. 12:00:00
 */
export const timeMask = "HH:mm:ss";

/**
 * Hours and minutes.
 * E.g. 12:00
 */
export const hoursAndMinutes = "HH:mm";

/**
 * Date mask.
 * E.g. 01 April 2020
 */
export const dateMask = "d MMMM yyyy";

/**
 * Year mask.
 * E.g. 2020
 */
export const yearMask = "yyyy";

/**
 * Full date mask.
 * E.g. 1 April 2020 - 12:00:00
 */
export const fullDateMask = `${dateMask} - ${timeMask}`;

/**
 * Mask with year, month, day, for backend usage.
 * E.g. 2020-12-25
 */
export const yearMonthDay = "yyyy-MM-dd";

/**
 * Mask with year, month, day, for frontend usage.
 * E.g. 2020/12/25
 */
export const displayYearMonthDay = "yyyy/MM/dd";

/**
 * Mask with month, day, year, for frontend usage.
 * E.g. 12/25/2020
 */
export const displayMonthDayYear = "MM/dd/yyyy";

/**
 * Mask for date pickers.
 * E.g. 25/12/2020
 */
export const datePickerDateMask = "dd/MM/yyyy";

/**
 * Mask for short month name, day and hour.
 * E.g. May 3th - 08:00 PM
 */
export const monthNameDayHour = "MMM do - p";

/**
 * Mask with year and month.
 * E.g. 21-03
 */
export const yearMonthMask = "yy-MM";

/**
 * Mask for detailed time.
 * E.g. 08:00 PM
 */
export const detailedTime = "p";

/**
 * Tries to parse a date with `parseISO` when possible. Will fallback to the default `Date`
 * constructor in case the value is not valid for parsing or the parsed date is invalid.
 * @param date the date.
 */
const parseDate = (
  date: Date | string,
): Date => {
  if (date && typeof date === "string") {
    const parsedDate = parseISO(date);

    if (isValid(parsedDate)) {
      return parsedDate;
    }
  }

  return new Date(date);
};

/**
 * Safely formats a date with a mask.
 * @param date the date.
 * @param mask the mask.
 * @param fallback fallback return value if date is invalid.
 */
export const formatDate = (
  date?: Date | string,
  mask = yearMonthDay,
  fallback = "-",
): string => {
  if (!date) {
    return fallback;
  }

  let result: string;

  const parsedDate = parseDate(date);

  try {
    result = format(parsedDate, mask, {
      locale: getDateFnsLocale(),
    });
  } catch (error) {
    captureSentryException(new Error("Failed to format date."), {
      error,
      input: {
        parsedDate,
        fallback,
        date,
        mask,
      },
    });

    result = fallback;
  }

  return result;
};

/**
 * Safely formats a date to fullDate mask.
 * @param date the date.
 */
export const formatFullDate = (
  date: string,
): string => formatDate(date, fullDateMask);

/**
 * Safely formats a date to a time mask.
 * @param date the date.
 */
export const formatTime = (
  date: string,
): string => formatDate(date, timeMask);

/**
 * Returns first day of a given month or period.
 * Period can be defined as 'All' (all data) or 'Year' (Selected year).
 * In case you need the date according to the fiscal year, inform the
 * fiscal year start date as the third param.
 * @param month 'NOVEMBER'
 * @param year '2020'
 */
export const getStartAt = (
  month?: string,
  year?: string,
  fiscalYearStart?: string,
): string | undefined => {
  if (month === PeriodsEnum.All) {
    return undefined;
  }

  if (month === PeriodsEnum.Year) {
    return fiscalYearStart || formatDate(
      startOfMonth(new Date(`${year}-${MonthsEnum.January}`)),
      yearMonthDay,
    );
  }

  return formatDate(
    startOfMonth(new Date(`${year}-${month}`)),
    yearMonthDay,
  );
};

/**
 * Returns last day of a given month or period.
 * Period can be defined as 'All' (all data) or 'Year' (Selected year).
 * In case you need the date according to the fiscal year, inform the
 * fiscal year start date as the third param.
 * @param month "NOVEMBER"
 * @param year "2020"
 */
export const getEndAt = (
  month: string,
  year: string,
  fiscalYearStart?: string,
): string | undefined => {
  if (month === PeriodsEnum.All) {
    return undefined;
  }

  if (month === PeriodsEnum.Year) {
    return fiscalYearStart || formatDate(
      endOfMonth(new Date(`${year}-${MonthsEnum.December}`)),
      yearMonthDay,
    );
  }

  return formatDate(
    endOfMonth(new Date(`${year}-${month}`)),
    yearMonthDay,
  );
};
