import { Flex } from "@chakra-ui/layout";
import React from "react";

import { LabelProps } from "./types";

const Label: React.FC<LabelProps> = ({
  labelText,
  ...props
}) => (
  <Flex
    width={150}
    justifyContent="center"
    color="white"
    fontFamily="heading"
    {...props}
  >
    { labelText }
  </Flex>
);

export default Label;
