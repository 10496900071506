import React, { useEffect, useMemo } from "react";
import { Flex } from "@chakra-ui/layout";
import { useTranslation } from "react-i18next";

import {
  FilterApplicationStatusEnum,
  ListApplicationsQuery,
  useListApplicationsLazyQuery,
} from "generated/graphql";
import { grantFlowFormattedColumns } from "constants/grantFlowTableTitles";
import Table from "components/Table";
import SearchFilter from "components/Filters/SearchFilter";
import StatusFilter from "components/Filters/StatusFilter";
import useFilter, { filters } from "hooks/useFilter";
import { getStartAt, getEndAt } from "utils/dateFormats";
import formatGrantFlowTableData from "utils/formatGrantFlowTableData";
import { getGrantStatus } from "utils/getStatus";
import { AllApplicationsEnum, grantFlowStatuses, grantFlowStatusSelectOptions } from "constants/statuses";
import TableHeader from "components/Table/TableHeader";
import DateFilter from "components/Filters/DateFilter";
import { useFiscalYear } from "hooks/useFiscalYear";

import GrantFlowActions from "./GrantFlowActions";

const TITLES: Record<string, string> = {
  [AllApplicationsEnum.AllApplications]: "tables.titles.grant_flow",
  [FilterApplicationStatusEnum.Submitted]: "tables.titles.applications_sent",
  [FilterApplicationStatusEnum.Awarded]: "tables.titles.grants_awarded",
  [FilterApplicationStatusEnum.Pending]: "tables.titles.grants_pending",
};

const GrantFlow: React.FC = () => {
  const tableColumns = grantFlowFormattedColumns;

  const [t] = useTranslation();

  const [search] = useFilter(filters.search);
  const [grantStatus, setGrantStatus] = useFilter(filters.grantStatus);
  const [submissionMonth] = useFilter(filters.submissionMonth);
  const [submissionYear] = useFilter(filters.submissionYear);

  const {
    currentFiscalYearStart,
    currentFiscalYearEnd,
    organizationId,
  } = useFiscalYear();

  const [listApplications,
    {
      data: tableData,
      loading: tableLoading,
    },
  ] = useListApplicationsLazyQuery();

  const startAt = useMemo(() => getStartAt(
    submissionMonth as string,
    submissionYear as string,
    currentFiscalYearStart,
  ), [
    currentFiscalYearStart,
    submissionMonth,
    submissionYear,
  ]);

  const endAt = useMemo(() => getEndAt(
    submissionMonth as string,
    submissionYear as string,
    currentFiscalYearEnd,
  ), [
    currentFiscalYearEnd,
    submissionMonth,
    submissionYear,
  ]);

  useEffect(() => {
    if (!organizationId) {
      return;
    }

    listApplications({
      variables: {
        filters: {
          organizationId,
          search: search as string,
          status: getGrantStatus(grantStatus as FilterApplicationStatusEnum),
          startAt,
          endAt,
        },
      },
    });
  },
  [
    listApplications,
    organizationId,
    grantStatus,
    startAt,
    search,
    endAt,
  ]);

  const rawTableData = (tableData ?? {}) as ListApplicationsQuery;

  const formattedTableData = formatGrantFlowTableData(rawTableData);

  return (
    <Table
      table={formattedTableData}
      tableColumns={tableColumns}
      isLoading={tableLoading}
    >
      <TableHeader
        title={t(TITLES[grantStatus as string])}
        flexDirection={{ base: "column", md: "row" }}
      >
        <GrantFlowActions />
      </TableHeader>

      <Flex
        flexDir="row"
        marginBottom={8}
      >
        <SearchFilter
          width="full"
        />

        <StatusFilter
          marginLeft={4}
          currentStatus={grantStatus as string}
          setStatus={setGrantStatus}
          tableStatuses={grantFlowStatuses}
          statusOptions={grantFlowStatusSelectOptions}
        />
      </Flex>

      <DateFilter />
    </Table>
  );
};

export default GrantFlow;
