import React, { useCallback, useEffect, useState } from "react";
import { Stack } from "@chakra-ui/layout";
import { Controller } from "react-hook-form";
import { Skeleton } from "@chakra-ui/skeleton";

import TextareaInput from "components/CustomInputs/TextareaInput";

import { CardTextAreaProps } from "./types";
import CardContainer from "../CardContainer";
import CardHeaderWithEdit from "../CardHeaderWithEdit";

const CardTextArea: React.FC<CardTextAreaProps> = ({
  value,
  handleSubmit,
  isLoading,
  title,
  control,
  name,
  funderId,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(value);

  useEffect(() => {
    if (!value) {
      return;
    }

    setText(value);
  }, [value]);

  const handleInput = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.currentTarget.value);
  }, []);

  return (
    <CardContainer height="full">
      <Stack spacing={4}>
        <CardHeaderWithEdit
          title={title}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
        />

        <Skeleton isLoaded={!!funderId}>
          <Controller
            as={TextareaInput}
            control={control}
            name={name}
            rows={6}
            value={text ?? ""}
            className="text-area"
            onChange={handleInput}
            readOnly={!isEditing}
          />
        </Skeleton>
      </Stack>
    </CardContainer>
  );
};

export default CardTextArea;
