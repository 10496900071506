import Location from "react-app-location";
import * as Yup from "yup";
import { getYear } from "date-fns";

import { FUNDER_PROFILE_PAGE_PATH, GRANT_FLOW_PAGE_PATH } from "routes";
import yup from "settings/yup";
import { validPeriods } from "constants/periods";
import { validStatuses } from "constants/statuses";

const idSchema = {
  id: Yup
    .number()
    .integer()
    .positive()
    .required(),
};

const filtersSchema = {
  submissionMonth: yup
    .string()
    .oneOf(validPeriods),
  submissionYear: yup
    .string()
    .max(
      getYear(new Date()),
    ),
  grantStatus: Yup
    .string()
    .oneOf(validStatuses),
};

export const FUNDER_PROFILE_PAGE_LOCATION = new Location(FUNDER_PROFILE_PAGE_PATH, idSchema);

export const GRANT_FLOW_PAGE_LOCATION = new Location(GRANT_FLOW_PAGE_PATH, null, filtersSchema);
