import yup from "settings/yup";

const loginSchema = yup.object()
  .shape({
    email: yup
      .string()
      .email()
      .required(),
    password: yup
      .string()
      .required(),
  });

export interface LoginSchema {
  email: string;
  password: string;
}

export default loginSchema;
