import React, { useEffect } from "react";
import { animated } from "react-spring";
import { Notification } from "react-rainbow-components";
import { Text } from "@chakra-ui/layout";

import { useToast } from "contexts/toast";

import { ToastComponentProps } from "./types";

const Toast: React.FC<ToastComponentProps> = ({
  toast,
  style,
  ...props
}) => {
  const { removeToast } = useToast();

  useEffect(() => {
    if (!toast) {
      return;
    }

    setTimeout(() => (
      removeToast(toast.id)
    ), toast.toastDuration);
  }, [
    toast,
    removeToast,
  ]);

  return (
    <animated.div style={style}>
      <Notification
        key={toast.id}
        icon={toast.type}
        title={(
          <Text
            width="95%"
            fontSize="xs"
            color="black"
            alignSelf="start"
            wordBreak="break-word"
          >
            {toast.title}
          </Text>
        )}
        description={(
          <Text
            width="95%"
            color="black"
            fontSize="xxs"
            alignSelf="start"
            wordBreak="break-word"
          >
            {toast.description}
          </Text>
        )}
        hideCloseButton={toast.hideCloseButton}
        onRequestClose={() => removeToast(toast.id)}
        {...props}
      />
    </animated.div>
  );
};

export default Toast;
